import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User, UserService, AlertService, CommonService, ProductCartService, ProductService, ChatService, DeliverySettings, DeliverySettingsService, EordersService, AddressService, Address } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
@UntilDestroy()
@Component({
  selector: 'product-cart-details',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [OrderByPipe]
})
export class CartComponent implements OnInit {
  cartData: any;
  cartProdData: any;
  productLists: any = null;
  cartView: boolean = true;
  isAddProduct: boolean = false;
  deliveryChargesPopup: boolean = false;
  deliveryCharges: any;
  deliveryChargesId: any;
  totalPrice: any;
  ticket: any;
  deliverySettings: DeliverySettings[] = [];
  deliverySettingsParams: any;
  PaymentMethods: any;
  isOrderLoaded: boolean = false;
  addressParams: any;
  addressList: Address[] = [];
  loader: boolean = true;
  address: boolean = true;

  @Output() onClose = new EventEmitter();
  user: User;
  constructor(
    public productCartService: ProductCartService,
    public productService: ProductService,
    private commonService: CommonService,
    private alertService: AlertService,
    public chatService: ChatService,
    public deliverySettingsService: DeliverySettingsService,
    public userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    public eordersService: EordersService,
    private orderByPipe: OrderByPipe,
    private addressService: AddressService,
    public matDialog: MatDialog,
  ) {

    this.deliverySettingsService.params.pipe(untilDestroyed(this)).subscribe(data => this.deliverySettingsParams = data);

    this.eordersService.paymentGenerateItems.next(null);
    this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
      this.ticket = data;
      if (this.ticket.clientSiteId) {
        this.productService.getAllDataItems(this.ticket.clientSiteId);
        this.deliverySettingsParams['client_site_id'] = this.ticket.clientSiteId;
        this.deliverySettingsService.getAllItems();
      }
    });

    this.addressService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.addressParams = data;
      if (this.ticket.clientSiteId) {
        this.addressParams.people_id = this.ticket.people_id;
        this.addressParams.client_site_id = this.ticket.clientSiteId;
        this.addressService.getAllItems();
      }
    });

    this.productService.resetParams();
    this.deliverySettingsService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.deliverySettings = this.orderByPipe.transform(data, '-id').filter(t => t.is_delivarable == true);
    });

    this.deliverySettingsService.allPaymentMethods.pipe(untilDestroyed(this)).subscribe(data => {
      this.PaymentMethods = this.orderByPipe.transform(data, '-id').filter(t => t.isPaymentMethod == true);
    });

    this.eordersService.orderLoader.pipe(untilDestroyed(this)).subscribe(data => {
      this.isOrderLoaded = data;
    });

    this.addressService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        this.addressList = data;
      } else {
        this.addressList = null;
      }
    });

    this.addressService.statusAddressInfo.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.addressPanel();
        this.addressService.statusAddressInfoData.next(false);
      }
    });

    // this.eordersService.paymentItems.pipe(untilDestroyed(this)).subscribe(data => {
    //   if (data && data['_id']) {
    //     this.sendSummary(data)
    //   }
    // });
  }



  ngOnInit(): void {
    this.dataInit();
  }
  /** On destroy */
  ngOnDestroy(): void {
    this.eordersService.paymentGenerateItems.next(null);
    this.paymentMethodId = null;
  }

  /** Get Data */
  dataInit() {
    /** Get Cart details */
    this.productCartService.cartDetails.subscribe(data => {
      var productContentId = [];
      if (data && data['orderData'] && data['orderData']['product_items']) {
        this.cartData = [...data['orderData']['product_items']];
        this.cartData.forEach(element => {
          if (element.product_retailer_id) {
            productContentId.push(element.product_retailer_id)
          }
        });

        this.getProductName(productContentId.join(","))
      }
    });
    /** get All Product details */
    this.productService.allIDatatems.subscribe(data => {
      this.productLists = data;
      if (this.cartData && this.productLists) {
        this.productLists.forEach(element => {
          element['quantity'] = 0;
        });
        this.cartData.forEach(cart => {
          this.productLists.forEach(product => {
            if (cart.product_retailer_id === product.content_id || cart.product_retailer_id === product.title) {
              product['quantity'] = cart.quantity;
              cart['image_url'] = product.image_url;
              // cart['product_id'] = product._id;
            }
          });
        });
      }
    });

  }

  /** close cart panel */
  closePanel() {
    if (this.productCartService.cartClientPanel) {
      this.onClose.emit(false);
      this.productCartService.cartDataSource.next(false);
    } else {
      this.productCartService.cartDataSource.next(false);
    }

  }

  /** get product name */
  getProductName(id) {
    var data = {
      "product_ids": id,
      "client_site_id": this.ticket.clientSiteId ? this.ticket.clientSiteId : null,
    }
    let tempSub = this.commonService.storeItem('eproduct/specific', data, true, "optionOne").subscribe((res: any) => {
      if (res.success) {
        this.cartData.forEach((product, index) => {
          res.data.forEach(element => {
            if (product.product_retailer_id === element.content_id) {
              this.cartData[index]['product_retailer_id'] = element.title;
              this.cartData[index]['product_id'] = element.content_id;
              this.cartData[index]['image_url'] = element.image_url;
            }
            if (product.product_retailer_id === element.retailer_id) {
              this.cartData[index]['product_retailer_id'] = element.name;
              this.cartData[index]['product_id'] = element.retailer_id;
              this.cartData[index]['image_url'] = element.image_url;
            }
          });
        });
      } else {
        this.alertService.webErrorShow(res);
      }
      tempSub.unsubscribe();
    }, error => {
      this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
      console.error('There was an error!', error);
      tempSub.unsubscribe();
    })
  }

  /** get total price */
  getTotlePrice(): string {
    var totalPrice = 0;
    var deliveryCharges;
    parseInt(this.deliveryCharges);
    if (this.cartData) {
      this.cartData.forEach(element => {
        if (element.quantity >= 1) {
          totalPrice = totalPrice + element.quantity * element.item_price;
        } else {
          totalPrice = totalPrice + 0;
        }
      });
    }

    if (this.deliveryCharges) {
      deliveryCharges = +this.deliveryCharges;
      totalPrice = totalPrice + deliveryCharges;
    }
    totalPrice = totalPrice
    this.totalPrice = totalPrice;
    return totalPrice.toFixed(3).toString();
  }

  /** add delivary charges */
  openDeliveryCharges() {
    this.addressService.getAllItems();
    // this.deliveryChargesPopup = !this.deliveryChargesPopup;
    this.doLoader();
    this.isAddresList = true;
    this.cartView = false;
  }
  /** add delivary charges */
  closeDeliveryCharges() {
    this.deliveryChargesPopup = false;
  }
  /** add value */
  addDeliveryCharges() {
    this.deliveryChargesPopup = false;
  }

  /** delete product in cart */
  deleteCartProduct(i, id) {
    this.cartData.forEach((product, index) => {
      if (index === i) {
        this.cartData.splice(index, 1);
        this.getTotlePrice();
      }
    });
    this.productLists.forEach(product => {
      if (product.content_id === id || product.title === id) {
        product['quantity'] = 0;
      }
    });
  }
  regex = "^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$";

  /** number only accept */
  numberOnly(event: any): boolean {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      if (this.deliveryCharges) {
        var len = this.deliveryCharges.length;
        var index = this.deliveryCharges.indexOf('.');
        if (index > 0 && charCode == 46) {
          return false;
        }
        if (index > 0) {
          var CharAfterdot = (len + 1) - index;
          if (CharAfterdot > 4) {
            return false;
          }
        }
      }

    }
    return true;
  }


  /** Qty decrease in cart product */
  decreaseQty(i, id) {
    this.cartData.forEach((product, index) => {
      if (index === i) {
        if (this.cartData[index].quantity > 0) {
          this.cartData[index].quantity--;
          this.getTotlePrice();
        }
      }
    });
    this.productLists.forEach(product => {
      if (product.content_id === id || product.title === id) {
        product['quantity']--;
      }
    });
  }

  /** Qty increase in cart product */
  increaseQty(i, id) {
    this.cartData.forEach((product, index) => {
      if (index == i) {
        this.cartData[index].quantity++;
        this.getTotlePrice();
      }
    });
    this.productLists.forEach(product => {
      if (product.content_id === id || product.title === id) {
        product['quantity']++;
      }
    });
  }

  /** Generate summary details */
  sendSummary(payment = null,) {
    // var deliveryCharges;
    // var isCheckOerder = false;

    // if (this.deliveryCharges) {
    //   deliveryCharges = this.deliveryCharges
    // } else {
    //   deliveryCharges = 0;
    // }

    // if (!this.paymentMethodId) {
    //   this.alertService.webShow('Danger', 'add your Payment Method !!!');
    //   return;
    // }

    // if (this.cartData && this.cartData.length > 0) {
    //   this.cartData.forEach(cart => {
    //     if (cart.quantity < 1 || cart.quantity == 0) {
    //       this.alertService.webShow('Danger', 'your product quantity is empty or delete product !!!');
    //       isCheckOerder = true;
    //       return;
    //     }
    //   });
    // }

    // if (isCheckOerder) {
    //   return
    // }

    // if (this.cartData && this.cartData.length > 0 && this.cartData[0].quantity > 0) {

    //   var data = {
    //     totalPrice: this.totalPrice,
    //     deliveryCharges: deliveryCharges,
    //     product: this.cartData,
    //     paymentMode: this.paymentMethodId
    //   }

    //   if (payment && payment._id) {
    //     data['payment'] = payment;
    //   }

    //   // this.productCartService.cartSummaryData = true;
    //   // this.productCartService.summaryData.next(data);

    // } else {
    //   this.alertService.webShow('Danger', 'your cart is empty !!!');
    // }

    var user: any = JSON.parse(localStorage.getItem('tji_user'));
    var isCheckOerder = false;
    var summary = {
      "name": this.ticket.peopleName,
      "email": user.email,
      "phoneNumber": this.ticket.recipientId,
      "people_id": this.ticket.people_id,
      "client_site_id": this.ticket.clientSiteId,
      "ticket_id": this.ticket.ticketId,
      "orderSummry": 1,
      "productDetails": []
    }
    if (this.deliveryChargesPriceId) {
      summary['address_id'] = this.deliveryChargesPriceId;
    } else {
      this.alertService.webShow('Danger', 'add your delivery Address !!!');
      return;
    }

    if (this.paymentMethodId) {
      summary['paymentMethod'] = this.paymentMethodId;
    } else {
      this.alertService.webShow('Danger', 'add your Payment Method !!!');
      return;
    }

    if (this.cartData && this.cartData.length > 0) {
      this.cartData.forEach(cart => {
        if (cart.quantity < 1 || cart.quantity == 0) {
          this.alertService.webShow('Danger', 'your product quantity is empty or delete product !!!');
          isCheckOerder = true;
          return;
        }
      });
    }
    if (isCheckOerder) {
      return
    }

    if (this.cartData && this.cartData.length > 0 && this.cartData[0].quantity > 0) {
      this.cartData.forEach(element => {
        var data = {
          product_id: element.product_id,
          product_quantity: element.quantity,
        }
        summary["productDetails"].push(data);
      });
      this.eordersService.summaryGenerate('eorders/createOrder', summary)
    } else {
      this.alertService.webShow('Danger', 'your cart is empty !!!');
    }

  }

  /** view product panel */
  productPanel() {
    this.cartView = !this.cartView;
    if (this.cartView) {
      this.isAddProduct = false;
      this.isAddresAdd = false;
      this.isAddresList = false;
    } else {
      this.isAddProduct = true;
      this.isAddresAdd = false;
      this.isAddresList = false;
    }
    this.paymentMethodId = null;
  }

  /** Qty decrease in add product */
  decreaseProdQty(i) {
    this.productLists.forEach((product, index) => {
      if (index === i) {
        if (this.productLists[index].quantity > 0) {
          this.productLists[index].quantity--;
        }
      }
    });
  }

  /** Qty increase in add product */
  increaseProdQty(i) {
    this.productLists.forEach((product, index) => {
      if (index === i) {
        this.productLists[index].quantity++;
      }
    });
  }

  /** add product to cart */
  addProductData() {
    var finalProduct = []
    var productAddDatas = this.productLists.filter(obj => obj.quantity > 0)
    productAddDatas.forEach(element => {
      var data = {
        currency: element.currency_code,
        item_price: element.price,
        product_retailer_id: element.title,
        quantity: element.quantity,
        product_id: element.content_id,
        image_url: element.image_url
      }
      finalProduct.push(data)
    });
    let result = this.cartData.filter(currentProd => !finalProduct.some(newProd => currentProd.product_retailer_id === newProd.product_retailer_id));
    if (result.length > 0) {
      result.forEach(element => {
        finalProduct.push(element);
      });
    }
    this.cartData = finalProduct;
    this.getTotlePrice();
    this.productPanel();
  }
  deliveryChargesPriceId: any

  onSelectDeliveryCharge(value) {
    if (this.deliverySettings && this.deliverySettings.length > 0) {
      this.deliveryChargesPriceId = value;
      this.deliverySettings.forEach(element => {
        if (element._id == value) {
          if (element.price) {
            this.deliveryCharges = element.price;
          } else {
            this.deliveryCharges = 0;
          }
        }
      });
    }

    // if (value != null && value != undefined) {
    // this.deliveryCharges = value;
    // }
  }


  /** Generate summary details */
  sendSummaryLink() {
    var user: any = JSON.parse(localStorage.getItem('tji_user'));
    var isCheckOerder = false;
    var summary = {
      "name": this.ticket.peopleName,
      "email": user.email,
      "phoneNumber": this.ticket.recipientId,
      "people_id": this.ticket.people_id,
      "client_site_id": this.ticket.clientSiteId,
      "ticket_id": this.ticket.ticketId,
      "productDetails": []
    }
    if (this.deliveryChargesPriceId) {
      summary['address_id'] = this.deliveryChargesPriceId;
    } else {
      this.alertService.webShow('Danger', 'add your delivery Address !!!');
      return;
    }

    if (this.paymentMethodId) {
      summary['paymentMethod'] = this.paymentMethodId;
    } else {
      this.alertService.webShow('Danger', 'add your Payment Method !!!');
      return;
    }

    if (this.cartData && this.cartData.length > 0) {
      this.cartData.forEach(cart => {
        if (cart.quantity < 1 || cart.quantity == 0) {
          this.alertService.webShow('Danger', 'your product quantity is empty or delete product !!!');
          isCheckOerder = true;
          return;
        }
      });
    }

    if (isCheckOerder) {
      return
    }

    if (this.cartData && this.cartData.length > 0 && this.cartData[0].quantity > 0) {
      this.cartData.forEach(element => {
        var data = {
          product_id: element.product_id,
          product_quantity: element.quantity,
        }
        summary["productDetails"].push(data);
      });
      this.eordersService.isOrderLoader.next(true);
      this.eordersService.paymentGenerate('eorders/createOrder', summary)
    } else {
      this.alertService.webShow('Danger', 'your cart is empty !!!');
    }
  }

  paymentMethodId: any = null;

  paymentChange($event) {
    this.paymentMethodId = $event.value;
  }
  // addresses

  isAddresAdd: boolean = false;
  isAddresList: boolean = false;
  addressTitle: string = 'New Address';
  addressForm: UntypedFormGroup;
  addressData: any;
  selectedAddressType: string = 'home';
  addressName: string = 'my home';

  addAddressData(data: any = null) {
    this.isAddresList = false;
    this.isAddresAdd = true;
    this.addressData = data;
    this.addressForm = this.createForm();
    if (data && data._id) {
      this.selectedAddressType = data.addressType;
      this.addressName = this.addressData.addressName;
      this.addressTitle = 'Edit Address';
    } else {
      this.addressTitle = 'New Address';
    }
  }

  addressPanel() {
    this.isAddresList = true;
    this.isAddresAdd = false;
  }

  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      contactName: [this.addressData?.contactName || null, [Validators.required]],
      contactNumber: [this.addressData?.contactNumber || null, [Validators.required, Validators.minLength(1)]],
      AltcontactNumber: [this.addressData?.AltcontactNumber || null],
      governarate_id: [this.addressData?.governarate_id || null, [Validators.required]],
      is_default: [this.addressData?.is_default || false],
      area: [this.addressData?.area || null, [Validators.required, Validators.minLength(1)]],
      street: [this.addressData?.street || null, [Validators.required, Validators.minLength(1)]],
      block: [this.addressData?.block || null, [Validators.required, Validators.minLength(1)]],
      addressType: [this.addressData?.addressType || 'home', [Validators.required]],
      addressName: [this.addressData?.addressName || null, [Validators.required, Validators.minLength(1), Validators.maxLength(24)]],
      avenue: [this.addressData?.addressDetails?.avenue || null],
      delivery_directions: [this.addressData?.addressDetails?.delivery_directions || null],
      house: [this.addressData?.addressDetails?.house || null],
      building: [this.addressData?.addressDetails?.building || null],
      floor: [this.addressData?.addressDetails?.floor || null],
      officeNo: [this.addressData?.addressDetails?.officeNo || null],
      appartmentNo: [this.addressData?.addressDetails?.appartmentNo || null],
    });
  }

  addressTypes(value) {
    this.addressName = 'my ' + value;
    this.selectedAddressType = value;

    if (this.selectedAddressType == 'appartment') {
      this.addressForm.controls["appartmentNo"].setValidators(Validators.required);
      this.addressForm.controls["appartmentNo"].updateValueAndValidity();
      this.addressForm.controls["floor"].setValidators(Validators.required);
      this.addressForm.controls["floor"].updateValueAndValidity();
      this.addressForm.controls["building"].setValidators(Validators.required);
      this.addressForm.controls["building"].updateValueAndValidity();
      this.addressForm.get('house').clearValidators();
      this.addressForm.get('house').updateValueAndValidity();
      this.addressForm.get('officeNo').clearValidators();
      this.addressForm.get('officeNo').updateValueAndValidity();
    } else if (this.selectedAddressType == 'office') {
      this.addressForm.controls["officeNo"].setValidators(Validators.required);
      this.addressForm.controls["officeNo"].updateValueAndValidity();
      this.addressForm.controls["floor"].setValidators(Validators.required);
      this.addressForm.controls["floor"].updateValueAndValidity();
      this.addressForm.controls["building"].setValidators(Validators.required);
      this.addressForm.controls["building"].updateValueAndValidity();
      this.addressForm.get('house').clearValidators();
      this.addressForm.get('house').updateValueAndValidity();
      this.addressForm.get('appartmentNo').clearValidators();
      this.addressForm.get('appartmentNo').updateValueAndValidity();
    } else {
      this.addressForm.controls["house"].setValidators(Validators.required);
      this.addressForm.controls["house"].updateValueAndValidity();
      this.addressForm.get('building').clearValidators();
      this.addressForm.get('building').updateValueAndValidity();
      this.addressForm.get('floor').clearValidators();
      this.addressForm.get('floor').updateValueAndValidity();
      this.addressForm.get('officeNo').clearValidators();
      this.addressForm.get('officeNo').updateValueAndValidity();
      this.addressForm.get('appartmentNo').clearValidators();
      this.addressForm.get('appartmentNo').updateValueAndValidity();
    }
  }

  submit() {
    if (this.addressForm.invalid) {
      return
    }

    var formData = {
      people_id: this.ticket.people_id,
      client_site_id: this.ticket.clientSiteId,
      contactName: this.addressForm.get('contactName').value,
      contactNumber: parseInt(this.addressForm.get('contactNumber').value),
      governarate_id: this.addressForm.get('governarate_id').value,
      is_default: this.addressForm.get('is_default').value,
      area: this.addressForm.get('area').value,
      street: this.addressForm.get('street').value,
      block: this.addressForm.get('block').value,
      addressType: this.addressForm.get('addressType').value,
      addressName: this.addressForm.get('addressName').value,
    }

    if (this.addressForm.get('AltcontactNumber').value && this.addressForm.get('AltcontactNumber').value.length > 0) {
      formData["AltcontactNumber"] = parseInt(this.addressForm.get('AltcontactNumber').value);
    }

    var addressDetails = {}

    if (this.selectedAddressType == 'appartment') {
      addressDetails = {
        "building": this.addressForm.get('building').value,
        "floor": this.addressForm.get('floor').value,
        "appartmentNo": this.addressForm.get('appartmentNo').value,
        "avenue": this.addressForm.get('avenue').value ? this.addressForm.get('avenue').value : "",
        "delivery_directions": this.addressForm.get('delivery_directions').value ? this.addressForm.get('delivery_directions').value : "",
      }
      formData['addressDetails'] = addressDetails;
    } else if (this.selectedAddressType == 'office') {
      addressDetails = {
        "building": this.addressForm.get('building').value,
        "floor": this.addressForm.get('floor').value,
        "officeNo": this.addressForm.get('officeNo').value,
        "avenue": this.addressForm.get('avenue').value ? this.addressForm.get('avenue').value : "",
        "delivery_directions": this.addressForm.get('delivery_directions').value ? this.addressForm.get('delivery_directions').value : "",
      }
      formData['addressDetails'] = addressDetails;
    } else {
      addressDetails = {
        "house": this.addressForm.get('house').value,
        "avenue": this.addressForm.get('avenue').value ? this.addressForm.get('avenue').value : "",
        "delivery_directions": this.addressForm.get('delivery_directions').value ? this.addressForm.get('delivery_directions').value : "",
      }
      formData['addressDetails'] = addressDetails;
    }

    if (this.addressData && this.addressData._id) {
      formData['id'] = this.addressData._id;
      this.addressService.update('deliverySettings/addAddress', formData);
    } else {
      this.addressService.store('deliverySettings/addAddress', formData);
    }
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 5000);
  }

  getAddrestData(address) {
    if (address.is_delivarable && address._id) {
      this.deliveryChargesPriceId = address._id;
      this.deliveryCharges = address.governarateAmount;
      this.productPanel();
    } else {
      this.alertService.webShow('Danger', 'This address governarate is disabled !!!');
    }
  }

  /** Delete address  */
  deleteAddress(address): void {
    let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600',
      data: {
        type: 'delete',
        message: 'Are you sure you want to delete?',
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        var id = {
          id: address._id
        }

        if (address._id == this.deliveryChargesPriceId) {
          delete this.deliveryChargesPriceId;
          delete this.deliveryCharges;
        }

        this.addressService.destroy('deliverySettings/deleteAddress', id);
      }
    });
  }

}
