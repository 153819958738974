import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tjiAnimation } from 'src/app/@tji/animation';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';

import {
	User, UserService, PeopleService,
	ModalHasFlagService, ModalHasFlag,
	ChatService,
	Flag,
	FlagService,
	ClosedTicketService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	animations: tjiAnimation
})

export class ListComponent implements OnInit, OnDestroy {
	user: User;
	ticket: any;
	peopleParam: any;

	ticketFlagParam: any = null;
	animStateTicket = false;
	animStateTwitter = false;
	animStateTwitterdm = false;
	animStateMessenger = false;
	animStateWhatsapp = false;
	animStateWebchat = false;
	animStateYoutube = false;
	animStateInstagram = false;
	animStateInstadm = false;

	hasNewTicket: boolean = false;
	tweetHasNewTicket: boolean = false;
	tweetdmHasNewTicket: boolean = false;
	messengerHasNewTicket: boolean = false;
	whatsappHasNewTicket: boolean = false;
	webchatHasNewTicket: boolean = false;
	youtubeHasNewTicket: boolean = false;
	instagramHasNewTicket: boolean = false;
	instadmHasNewTicket: boolean = false;
	viewScreen: any;
	expanded: string = 'openticket';
	flags: Flag[] = [];
	selectedFlag: any;
	closedTicketParams: any;

	constructor(public userService: UserService,
		public chatService: ChatService,
		public peopleService: PeopleService,
		public modalHasFlagService: ModalHasFlagService,
		public flagService: FlagService,
		public closedTicketService: ClosedTicketService,
		private router: Router,
		public matDialog: MatDialog) {
		this.flagService.allFlag.pipe(untilDestroyed(this)).subscribe(data => this.flags = data);
		this.userService.user.subscribe(data => this.user = data);
		this.peopleService.params.pipe(untilDestroyed(this)).subscribe(data => this.peopleParam = data);
		this.closedTicketService.params.pipe(untilDestroyed(this)).subscribe(data => this.closedTicketParams = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => this.ticket = data);
		this.chatService.viewScreen.pipe(untilDestroyed(this)).subscribe(data => this.viewScreen = data);
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
	}

	ngOnInit() {
		this.getTicketFlags();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getTicketFlags() {
		if (this.ticket && this.ticket.id) {
			this.chatService.onChangeTicket.subscribe(data => {
				this.ticketFlagParam.client_id = (this.ticket && this.ticket.client_id) ? this.ticket.client_id : null;
				this.ticketFlagParam.client_site_id = (this.ticket && this.ticket.clientSiteId) ? this.ticket.clientSiteId : null;
				this.ticketFlagParam.resource_id = (this.ticket && this.ticket.id) ? this.ticket.id : null;
				if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
					this.ticketFlagParam.resource_id) {
					this.modalHasFlagService.getAllItems();
				}
			});
		}
	}

	animDoneTicket() {
		this.animStateTicket = !this.animStateTicket;
	}

	animDoneTwitter() {
		this.animStateTwitter = !this.animStateTwitter;
	}

	animDoneTwitterdm() {
		this.animStateTwitterdm = !this.animStateTwitterdm;
	}

	animDoneMessenger() {
		this.animStateMessenger = !this.animStateMessenger;
	}

	animDoneWhatsapp() {
		this.animStateWhatsapp = !this.animStateWhatsapp;
	}

	animDoneWebchat() {
		this.animStateWebchat = !this.animStateWebchat;
	}

	animDoneYoutube() {
		this.animStateYoutube = !this.animStateYoutube;
	}

	animDoneInstagram() {
		this.animStateInstagram = !this.animStateInstagram;
	}

	animDoneInstadm() {
		this.animStateInstadm = !this.animStateInstadm;
	}

	onSelectCustomerPanel(accountType = null) {
		if (accountType) {
			this.peopleParam.client_id = this.userService.getAuthClientId();
			this.peopleParam.accountType = accountType.toLowerCase();
			this.peopleService.getAllItems();
		}
	}
	selectFlag(value) {
		if (value == null) {
			this.closedTicketService.changeLibraries([]);
			this.closedTicketParams["flag_id"] = '';
			delete this.closedTicketParams["flag_id"];
			this.closedTicketParams.current = 1,
			this.closedTicketParams.limit = 15,
			this.selectedFlag = null;
			this.getClosedTickets();
		} else {
			this.closedTicketService.changeLibraries([]);
			this.closedTicketParams["flag_id"] = value;
			this.closedTicketParams.current = 1,
				this.closedTicketParams.limit = 20,
				this.getClosedTickets();
		}
	}

	resetParams() {
		this.closedTicketService.changeLibraries([]);
		this.closedTicketParams["flag_id"] = '';
		delete this.closedTicketParams["flag_id"];
		this.closedTicketParams.current = 1,
			this.closedTicketParams.limit = 15,
			this.selectedFlag = null;
		this.getClosedTickets();
	}

	getClosedTickets() {
		if (this.userService.permissionMatch(['Manage Whatsapp'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
		}
		if (this.userService.permissionMatch(['Manage Instadm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
		}
		if (this.userService.permissionMatch(['Manage Messenger'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
		if (this.userService.permissionMatch(['Manage Instagram'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
	}
}
