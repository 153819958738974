import { Component, ElementRef, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { ShareMediaFormComponent } from './../shareMediaForm/shareMediaForm.component';
import { FormComponent } from './../form/form.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import {
  User, UserService,
  MediaService, Media, MediaModel,
  ChatService,
  AlertService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'app-template-gallery',
  templateUrl: './template-gallery.component.html',
  styleUrls: ['./template-gallery.component.scss'],
  providers: [OrderByPipe]
})
export class TemplateGalleryComponent implements OnInit, OnDestroy {
  loader: boolean = true;
  subLoader: boolean = false;
  authUser: User;

  galleries: Media[] = [];
  param: any;
  paginator: MatPaginator;
  userType = JSON.parse(localStorage.getItem('tji_user'));

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  mediaForm: UntypedFormGroup;
  showCaption: boolean = false;
  action: string;

  dialogTitle: string = 'Gallery List';

  constructor(public userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public mediaService: MediaService,
    public chatService: ChatService,
    private snackBar: MatSnackBar,
    private router: Router,
    private orderByPipe: OrderByPipe,
    public matDialogRef: MatDialogRef<TemplateGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private alertService: AlertService,) {
    this.getInit(_data);
    this.userService.user.pipe(untilDestroyed(this)).subscribe(data => this.authUser = data);
    this.mediaService.totalItem.pipe(untilDestroyed(this)).subscribe(data => {
      this.dataLength = data;
    });
    // this.mediaService.loader.pipe(untilDestroyed(this)).subscribe(data => {
    //   this.loader = data;
    // });
    this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
    });
    this.mediaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        // this.cd.detectChanges();
        this.galleries = this.orderByPipe.transform(data, '-_id');
      } else {
        this.galleries = data;
      }
    });
    this.mediaForm = this.createForm();
  }

  getInit(data) {
    // Set the defaults
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Gallery List';
        break;
      case 'edit':
        this.dialogTitle = 'Update Gallery';
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.doLoader();
    // this.cd.detectChanges();
    // this.loader = true;
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.mediaService.unSubscribeFilter();
    this.mediaService.unSubscribe();
    this.cd.detectChanges();
  }
  /** Create form */
  createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      file: [null],
      asset: [null],
      asset_name: [null],
      asset_type: [null],
    });
  }

  doLoader() {
    setTimeout(() => {
      this.loader = !this.loader;
    }, 1000);
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }


  typeData: string = 'image';
  imageHandler(event) {
    let reader = new FileReader();
    let fileData = null;

    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        var types = file.type.split("/");
        var typeDatas = types[1];
        this.typeData = null;
        
        switch (typeDatas) {
          case "JPEG": case "JPG":
          case "PNG": case "GIF":
          case "TIFF": case "RAW":
          case "jpeg": case "jpg":
          case "png": case "gif":
          case "tiff": case "raw":
          case "WebP": case "webp": case "WEBP":
            this.typeData = 'image'
            break;
          case "DOC": case "DOCX":
          case "ODT": case "PDF":
          case "PPT": case "PPTX":
          case "TXT": case "XLS":
          case "XLSX": case "ZIP":
          case "CVS": case "vnd.ms-excel":
          case "doc": case "csv":
          case "docx": case "odt":
          case "pdf": case "pptx":
          case "text": case "xls":
          case "xlsx": case "zip": case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "vnd.openxmlformats-officedocument.wordprocessingml.document":
            this.typeData = 'document'
            break;
          case "MP3": case "WAV":
          case "AAC": case "FLAC":
          case "Ogg": case "Ogg Vorbis":
          case "PCM": case "MPEG":
          case "mp3": case "wav":
          case "aac": case "flac":
          case "ogg": case "mpeg":
          case "ogg vorbis": case "pcm":
          case "vnd.dlna.adts":
            this.typeData = 'voice'
            break;
          case "WEBM": case "MPG":
          case "MP2":
          case "MPE": case "MPV":
          case "OGG": case "MP4":
          case "AVI": case "WMV":
          case "MOV": case "QT":
          case "FLV": case "SWF":
          case "AVCHD":
          case "webm": case "mpg":
          case "mp2":
          case "mpe": case "mpv":
          case "ogg": case "mp4":
          case "avi": case "wmv":
          case "mov": case "qt":
          case "flv": case "swf":
          case "avchd":
            this.typeData = 'video'
          default:
            break;
        }

        if (this.param && this.param.type != this.typeData) {
          this.alertService.webShow('Danger', 'This Format Not Allowed for this Template');
          return false;
        }

        this.mediaForm.patchValue({
          asset: reader.result,
          asset_name: file.name.replace(/ /g, "_"),
          asset_type: this.typeData,
        });
        if (this.typeData != null) {
          this.mediaService.addStore(this.mediaForm.value);
          setTimeout(() => {
            this.mediaForm.patchValue({
              file: null,
              asset: null,
              asset_name: null,
              asset_type: null
            });
          }, 300);
        } else {
          this.mediaForm.patchValue({
            file: null,
            asset: null,
            asset_name: null,
            asset_type: null
          });
          this.alertService.webShow('Danger', 'This Format Not Supported Contact admin');
        }
      };
    }
  }
  import(url: any = null) {
    var data = {
      type: 'new',
      url: url
    }
    this.matDialogRef.close(data);
  }
}
