import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/collections';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollToBottomDirective } from 'src/app/library/directives/scroll-to-bottom.directive';

import { QuickMessageFormComponent } from 'src/app/appAccount/agentDashboard/quickMessageForm/quickMessageForm.component';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import { ReassignFormComponent } from './../reassignForm/reassignForm.component';

import {
	User, UserService,
	People, PeopleService,
	LeadReserveService, LeadReserve, LeadReserveModel,
	CommentService, Comment,
	LeadsHistoryService,
	ChatService, CommonService, ProductCartService, AlertService, FlagService, ModalHasFlagService
} from 'src/app/@tji/_dbShare';
import { Console } from 'console';

@UntilDestroy()
@Component({
	selector: 'ticket-chat-info',
	templateUrl: './chatInfo.component.html',
	styleUrls: ['./chatInfo.component.scss']
})

export class ChatInfoComponent implements OnInit, OnDestroy {
	@Output() onViewGallery = new EventEmitter();
	user: User;
	ticket: any = null;
	cartData: any = null;
	ticketClassName: string = null;
	isDirectMessage: boolean = false;
	isTicketClosed: boolean = false;
	activeConcurrentTickets: any = [];
	activeConcurrentTicketsLength: number = 0;
	concurrentLimit: number = 0;
	leadsHistoryParam: any;
	people: People;
	baseImage: string = this.commonService.logo_image;

	constructor(public chatService: ChatService,
		public userService: UserService,
		public leadsHistoryService: LeadsHistoryService,
		public leadReserveService: LeadReserveService,
		public peopleService: PeopleService,
		public commentService: CommentService,
		public commonService: CommonService,
		public productCartService: ProductCartService,
		private router: Router,
		public matDialog: MatDialog,
		private alertService: AlertService,
		public flagService: FlagService,
		public modalHasFlagService: ModalHasFlagService,) {
		this.userService.user.subscribe(data => this.user = data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => this.people = data);
		this.modalHasFlagService.params.pipe(untilDestroyed(this)).subscribe(data => this.ticketFlagParam = data);
		this.flagService.params.pipe(untilDestroyed(this)).subscribe(data => this.flagParam = data);
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data => {
			this.ticket = data;
			if (this.ticket && this.ticket.updated_at) {
				this.getDiffDays(this.ticket.updated_at)
			}
		});
		this.chatService.concurrentLimit.pipe(untilDestroyed(this)).subscribe(data => this.concurrentLimit = data);
		this.chatService.siteName.pipe(untilDestroyed(this)).subscribe(data => this.ticketClassName = data);
		this.chatService.concurrentTickets.pipe(untilDestroyed(this)).subscribe(data => {
			this.activeConcurrentTickets = data;
			this.activeConcurrentTicketsLength = data.length;
		});
		this.productCartService.cartDetails.subscribe(data => {
			if (data && data['id']) {
				this.cartData = data;
			}
		});
	}

	ngOnInit() { }

	getDiffDays(sDate) {
		var startDate = new Date(sDate);
		var endDate = new Date();

		var diff = (startDate.getTime() - endDate.getTime()) / 1000;
		diff /= (60 * 60);
		var diffInHours = Math.abs(Math.round(diff));
		if (diffInHours >= 24) {
			this.isTicketClosed = true;
		} else {
			this.isTicketClosed = false;
		}
		// var diff = endDate.valueOf() - startDate.valueOf();
		// var diffInHours = diff / 1000 / 60 / 60;
		// console.log('result1', diffInHours, this.isTicketClosed);
	}


	/** On destroy */
	ngOnDestroy(): void {
		this.chatService.unSubscribeAll();
	}

	/** Add Comments */
	addComment(comment: Comment = null): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: this.ticket,
				comment: comment,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.store(result.formValue);
			}
		});
	}

	/** Add / Update Tag */
	reassignTicket(): void {
		if (this.ticket && this.ticket.siteName) {
			this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName);
			let dialogRef = this.matDialog.open(ReassignFormComponent, {
				width: "600px",
				data: {
					action: 'edit',
					item: this.ticket,
				}
			});

			dialogRef.afterClosed()
				.subscribe(response => {
					if (!response) { return; }
					const actionType: string = response[0];
					const formData: UntypedFormGroup = response[1];
					const id: number = (response[2]) ? response[2] : null;
					let updateData: any = formData.value;
					updateData['client_site_id'] = this.ticket.clientSiteId;
					let newAgentId: any = updateData.agent_id;
					if (newAgentId && newAgentId === this.ticket.agent_id) {
						updateData.status_mode = null;
					}
					if (actionType && actionType === 'update' && this.ticket && this.ticket.id && this.ticket.siteName) {
						this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName, "update", updateData);
						this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName, "removeLibrary");
					}
				});
		}
	}

	/** Re Open Ticket */
	reOpenTicket(): void {
		if (this.ticket && this.ticket.siteName) {
			this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName);

			let dialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '600',
				data: {
					type: 'info',
					title: 'Re-Open Ticket',
					message: 'Are you sure you want to Re-open this Ticket and Assigned to you?',
				}
			});

			dialogRef.afterClosed()
				.subscribe(response => {
					if (!response || !response.data) { return; }
					const actionType: string = 'update';
					let updateData: any = {};
					updateData.client_site_id = this.ticket.clientSiteId;
					updateData.ticket_id = this.ticket.id;
					updateData.agent_id = this.ticket.agent_id;
					const id: number = this.ticket.id;
					let newAgentId: any = updateData.agent_id;
					if (actionType && actionType === 'update') {
						this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName, "update", updateData);
					}
				});
		}
	}

	/** Close Tickets */
	closeTicket(ticket: any): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'close',
				title: 'Close Ticket',
				resource: ticket,
				clientSiteId: ticket.clientSiteId,
				message: 'Are you sure you want to Close this Ticket?',
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				// this.ticket.is_closed = true;
				// ticket.is_closed = true;
				// this.chatService.changeCloseTicketEvent(true);
				result.formValue.is_closed = true;
				var data = {
					"client_site_id": result.formValue.clientSiteId,
					"ticket_id": result.formValue.id,
				}
				var user: any = JSON.parse(localStorage.getItem('tji_user'));
				let comments: any = {
					"client_site_id": result.formValue.clientSiteId,
					"comment_id": null,
					"ticket_id": result.formValue.id,
					"user_id": (user && user.id) ? user.id : null,
					"description": result.formValue.description,
					"title": result.formValue.title
				}
				if (result.formValue.description) {
					this.commentService.closeTicketComment('comments/addcomment', comments);
				}
				if (result.formValue.set_flags && result.formValue.set_flags.length > 0) {
					result.formValue.set_flags.forEach(flag => {
						let modalHasFlag: any = {
							"ticket_id": result.formValue.id,
							"flag_id": flag,
							"client_site_id": result.formValue.clientSiteId,
						};
						this.modalHasFlagService.closeTicketFlag('flag/addflagMap', modalHasFlag);
					});
				}
				if (this.ticket && this.ticket.id && this.ticket.siteName) {
					this.chatService.ticketChangeProcess(this.ticket, this.ticket.siteName, "close", data);
				}
			}
			
		});
	}

	getLeadsHistory(ticket) {
		this.leadsHistoryService.resetParams();
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}

	getComments() {
		this.chatService.getComments(this.ticket);
	}

	/** Reserve Lead */
	reserveLead(people: People): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				title: 'Reserve Lead',
				message: 'Are you sure you want to Reserve this Lead?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			let storeData: LeadReserve = new LeadReserveModel({});
			if (this.user && this.user.person_type && this.user.person_type === 'agent') {
				storeData.people_id = people.id;
				storeData.agent_id = this.user.person_id;
				storeData.client_id = this.userService.getAuthClientId();
				if (result && result.data) {
					this.leadReserveService.store(storeData);
					setTimeout(() => {
						this.peopleService.getItem({ 'id': people.id });
					}, 500);
				}
			}
		});
	}

	/** Reserve Lead */
	removeReserveLead(people: People): void {
		let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'danger',
				title: 'Remove Lead Reservation',
				message: 'Are you sure you want to Remove the Lead Reserve?',
			}
		});
		confirmDialogRef.afterClosed().subscribe(result => {
			let removeData: LeadReserve = new LeadReserveModel({});
			if (this.user && this.user.person_type && this.user.person_type === 'agent') {
				removeData.people_id = people.id;
				removeData.agent_id = people.reserveAgentId;
				removeData.client_id = this.userService.getAuthClientId();
				if (result && result.data) {
					this.leadReserveService.postUrl(removeData, 'leadreserven/remove');
					setTimeout(() => {
						this.peopleService.getItem({ 'id': people.id });
					}, 5000);
				}
			}
		});
	}
	
	ticketClosed() {
		this.alertService.webShow('danger', 'your ticket validity expired  !!!');
	}
	getFlagsData(ticket) {
		this.getTicketFlags(ticket);
		this.getFlags(ticket);
	}
	ticketFlagParam: any;
	flagParam: any;
	getTicketFlags(ticket) {
		if (ticket && ticket.id) {
			this.ticketFlagParam.client_id = ticket.client_id;
			this.ticketFlagParam.client_site_id =(ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
			this.ticketFlagParam.resource_id = ticket.id;
			this.ticketFlagParam.ticket_id = ticket.id;
			this.ticketFlagParam.resource_type = ticket.className;
			if (this.ticketFlagParam.client_id && this.ticketFlagParam.client_site_id &&
				this.ticketFlagParam.resource_id && this.ticketFlagParam.resource_type) {
				this.modalHasFlagService.getAllItems();
			}
		}
	}

	getFlags(ticket) {
		this.flagParam.client_id = ticket.client_id;
		this.flagParam.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
		this.flagService.getAllItems();
	}
}
