import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { WhatsappTicket } from './whatsapp_ticket.interface';
import { WhatsappTicketModel } from './whatsapp_ticket_model.model';
import { ClosedTicketService } from 'src/app/@tji/_dbShare/chat/closedTicket.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class WhatsappTicketService implements Resolve<any> {
	url: string = 'whatsapp-ticket';
	newUrl: string = 'tickets/whatsappList';
	isParams: boolean = true;
	isResetHistory: boolean = false;
	routeParams: any;
	defaultParams: any = {
		'limit': 25,
		'current': 1,
		'is_closed': '0',
		'sort': 'created_at|desc',
	};
	sortIdentity: any = {
		'name': 'name'
	};
	onCloseEvent: EventEmitter<boolean> = new EventEmitter();

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<WhatsappTicket[]>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<WhatsappTicket>(new WhatsappTicketModel({}));
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private displayItemsSource = new BehaviorSubject<WhatsappTicket[]>([]);
	displayItems = this.displayItemsSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	public isCloseTicketSource = new BehaviorSubject<boolean>(false);
	isCloseTicket = this.isCloseTicketSource.asObservable();

	public isfilterLoader = new BehaviorSubject<boolean>(false);
	filterLoader = this.isfilterLoader.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private closedTicketService: ClosedTicketService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.pramsChecks(),
				// this.getAllItems(),
				this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	pramsChecks() {
		if (this.isParams) {
			this.resetParams();
		}
	}

	unSubscribe() {
		// console.log('UnSubscribed WhatsappTicketService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on WhatsappTicketService');
	}

	concatlibrary(allItems: WhatsappTicket[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: WhatsappTicket) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			oldLists = oldLists.filter(x => {
				return x.id !== item.id;
			});
		}
		this.changeLibraries(oldLists);
	}

	removelibraryById(id: number) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && id) {
			oldLists = oldLists.filter(x => {
				return x.id !== id;
			});
		}
		this.changeLibraries(oldLists);
	}

	replacelibrary(item: WhatsappTicket) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	updateReplacelibrary(item: WhatsappTicket) {
		let oldLists = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLists = data);
		if (oldLists && oldLists.length > 0 && item && item.id) {
			for (var i = 0; i < oldLists.length; ++i) {
				if (oldLists[i].id === item.id) {
					oldLists[i] = item;
					isReplaced = true;
					break;
				}
			}
		}
		// if (!isReplaced) { oldLists.concat([item]); }
		this.changeLibraries(oldLists);
	}

	changeLibraries(libraries: any) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: WhatsappTicket[]) {
		allItems = allItems.filter(function (e) { return e });
		allItems = [...new Map(allItems.map((ticket) => [ticket.id, ticket])).values()];
		this.concatlibrary(allItems);
		this.allItemsSource.next(allItems);
		this.onChangeAllItems.emit(allItems);
	}

	changeItem(item: WhatsappTicket) {
		this.replacelibrary(item);
		this.itemSource.next(item);
		this.onChangeItem.emit(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeDisplayItems(displayItems: WhatsappTicket[]) {
		this.displayItemsSource.next(displayItems);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	changeAllItemsByItem(item: WhatsappTicket) {
		let allItems = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id == item.id) {
					allItems.splice(i, 1, item);
					isChanged = true;
					break;
				}
			}
		}
		if (!isChanged) { allItems = allItems.concat([item]); }
		this.changeAllItems(allItems);
	}

	updateAllItemsByItem(item: WhatsappTicket) {
		let allItems = [];
		let isChanged: boolean = false;
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id === item.id) {
					allItems[i] = item;
					isChanged = true;
					break;
				}
			}
		}
		if (!isChanged) { item.is_closed = false; allItems = allItems.concat([item]); }
		this.changeAllItems(allItems);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 25,
			'current': 1,
			'sort': 'created_at|desc',
			'is_closed': (this.routeParams && this.routeParams.param && this.routeParams.param === 'closed') ? '1' : '0',
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	// getAllItems(params: any = null) {
	// 	params = this.paramsInit(params);
	// 	this.commonService.getAll(this.url, params)
	// 		.pipe(untilDestroyed(this, 'unSubscribe'))
	// 		.subscribe(data => {
	// 			if (params.all && params.all === 1) {
	// 				this.changeAllItems(data.data);
	// 				this.changeTotalItem(data.data.length);
	// 			} else {
	// 				this.changeAllItems(data.data.data);
	// 				this.changeTotalItem(data.data.total);
	// 			}
	// 		},
	// 			error => console.log('Error ::' + error)
	// 		);
	// }

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		if (params.flag_id && params.flag_id.length < 5) {
			delete params.flag_id
		}
		this.commonService.storeItem(this.newUrl, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.rows && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var dataFormats = {
								id: ticketList.id,
								ticketId: ticketList.id,
								whatsapp_id: ticketList.whatsapp_id,
								code: ticketList.code,
								client_id: ticketList.client_id,
								agent_id: ticketList.agent_id,
								priority: ticketList.priority,
								is_active: ticketList.is_active,
								is_closed: ticketList.is_closed ? true : false,
								comment: ticketList.comment,
								people_id: ticketList.people_id,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								last_message_on: ticketList.updated_at,
								deleted_at: ticketList.deleted_at,
								is_closeable: ticketList.is_closeable,
								agentName: ticketList.agentInfo?.name,
								agentAvatar: ticketList.agentInfo?.person_details?.image_api,
								createdByAvatar: ticketList.agentInfo?.person_details?.image_api,
								agentLiveStatus: ticketList.agentInfo?.person_details?.agentLiveStatus,
								statusChanges: null,
							}
							if (ticketList.updated_at && ticketList.updated_at != '0000-00-00 00:00:00') {
								dataFormats['updated_at'] = moment(ticketList.updated_at + '.000+0300').local();
							} else {
								dataFormats['updated_at'] = moment(ticketList.created_at + '.000+0300').local();
							}
							additionalData.ChatData.forEach(ChatData => {
								if (ChatData.people_id == ticketList.people_id) {
									if (ChatData.people_id == ticketList.people_id) {
										dataFormats["clientSiteId"] = ChatData.client_site_id;
										dataFormats["sms_message_sid"] = ChatData.sms_message_sid;
										dataFormats["text"] = ChatData.text;
										dataFormats["from"] = ChatData.from;
										dataFormats["to"] = ChatData.to;
										dataFormats["user_image"] = ChatData.user_image;
										dataFormats["media"] = ChatData.media;
										dataFormats["json"] = ChatData.json;
										dataFormats["status"] = ChatData.status;
										dataFormats["is_read"] = ChatData.is_read;
										dataFormats["parent_id"] = ChatData.id;
										dataFormats["unReadCount"] = ticketList.unreadcount?ticketList.unreadcount:0;
										if (ChatData && ChatData.clientSiteInfo) {
											dataFormats["clientSiteTextColor"] = ChatData.clientSiteInfo?.textcolor;
											dataFormats["clientSiteName"] = ChatData.clientSiteInfo?.account_name;
											if (ChatData && ChatData.clientSiteInfo && ChatData.clientSiteInfo.details) {
												dataFormats["siteName"] = ChatData.clientSiteInfo.details?.name;
												dataFormats["siteId"] = ChatData.clientSiteInfo.details?.id;
												dataFormats["siteIcon"] = ChatData.clientSiteInfo.details?.icon;
												dataFormats["siteColor"] = ChatData.clientSiteInfo.details?.color;
												dataFormats["clientSiteColor"] = ChatData.clientSiteInfo.details?.color;
											}
										}
									}
								}
							});
							if (additionalData && additionalData.peopleData) {
								additionalData.peopleData.forEach(peopleData => {
									if (ticketList.people_id == peopleData.id) {
										dataFormats["peopleName"] = peopleData?.name;
										dataFormats["peopleAvatar"] = peopleData?.peopleAvatar;
										dataFormats["recipientId"] = peopleData?.unique_ref;
										if (peopleData && peopleData.labelInfo) {
											dataFormats["peopleLabelId"] = peopleData.labelInfo?.id;
											dataFormats["peopleLabelName"] = peopleData.labelInfo?.name;
											dataFormats["peopleLabelColor"] = peopleData.labelInfo?.color;
											dataFormats["peopleLabelColorText"] = peopleData.labelInfo?.color_text;
										}
									}
								});
							}
							var statusData = [];
							if (ticketData && ticketData.ticketStatus) {
								ticketData.ticketStatus.forEach(ticketStatus => {
									if (ticketStatus.model_id == ticketList.id) {
										statusData.push(ticketStatus);
									}
								});
								if (statusData && statusData.length > 0) {
									let lastStatusData = statusData.pop();
									dataFormats["statusName"] = lastStatusData.name;
									dataFormats["className"] = lastStatusData.model_type;
									// console.log("time 1", lastStatusData.created_at);
									// console.log("convert time 1", moment(lastStatusData.created_at + '.000+0300').local());
									// console.log("convert ticket time",  moment(ticketList.created_at+'.000+0300').local())
									// dataFormats["abandonAt"] = moment(lastStatusData.created_at + '.000+0300').local();
								}
							}
							dataFormat.push(dataFormats);
						});
					}
					this.isfilterLoader.next(false);
					this.changeAllItems(dataFormat);
					this.changeTotalItem(data.pagnitation.totalResult);
				} else {
					this.isfilterLoader.next(false);
					this.alertService.webErrorShow(data);
				}
			},
				error =>{ 
					console.log('Error ::' + error); 
					this.isfilterLoader.next(false);
				}
				
			);
	}

	getAllLiveItems(params: any = null) {
		params = this.paramsInit(params);
		params.current = 1;
		this.commonService.storeItem(this.newUrl, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				// console.log('getAllLiveItems=============',data);
				this.changeAllItems(data.data.data);
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.getAll(this.url, params)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {

				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var dataFormats = {
								id: ticketList.id,
								ticketId: ticketList.id,
								whatsapp_id: ticketList.whatsapp_id,
								code: ticketList.code,
								client_id: ticketList.client_id,
								agent_id: ticketList.agent_id,
								priority: ticketList.priority,
								is_active: ticketList.is_active,
								is_closed: ticketList.is_closed ? true : false,
								comment: ticketList.comment,
								people_id: ticketList.people_id,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								last_message_on: ticketList.updated_at,
								deleted_at: ticketList.deleted_at,
								is_closeable: ticketList.is_closeable,
								agentName: ticketList.agentInfo?.name,
								agentAvatar: ticketList.agentInfo?.person_details?.image_api,
								createdByAvatar: ticketList.agentInfo?.person_details?.image_api,
								agentLiveStatus: ticketList.agentInfo?.person_details?.agentLiveStatus,
								statusChanges: null,
							}
							additionalData.ChatData.forEach(ChatData => {
								if (ChatData.people_id == ticketList.people_id) {
									if (ChatData.people_id == ticketList.people_id) {
										dataFormats["clientSiteId"] = ChatData.client_site_id;
										dataFormats["sms_message_sid"] = ChatData.sms_message_sid;
										dataFormats["text"] = ChatData.text;
										dataFormats["from"] = ChatData.from;
										dataFormats["to"] = ChatData.to;
										dataFormats["user_image"] = ChatData.user_image;
										dataFormats["media"] = ChatData.media;
										dataFormats["json"] = ChatData.json;
										dataFormats["status"] = ChatData.status;
										dataFormats["is_read"] = ChatData.is_read;
										dataFormats["parent_id"] = ChatData.id;
										dataFormats["unReadCount"] = ticketList.unreadcount?ticketList.unreadcount:0;
										if (ChatData && ChatData.clientSiteInfo) {
											dataFormats["clientSiteTextColor"] = ChatData.clientSiteInfo?.textcolor;
											dataFormats["clientSiteName"] = ChatData.clientSiteInfo?.account_name;
											if (ChatData && ChatData.clientSiteInfo && ChatData.clientSiteInfo.details) {
												dataFormats["siteName"] = ChatData.clientSiteInfo.details?.name;
												dataFormats["siteId"] = ChatData.clientSiteInfo.details?.id;
												dataFormats["siteIcon"] = ChatData.clientSiteInfo.details?.icon;
												dataFormats["siteColor"] = ChatData.clientSiteInfo.details?.color;
												dataFormats["clientSiteColor"] = ChatData.clientSiteInfo.details?.color;
											}
										}
									}
								}
							});
							if (additionalData && additionalData.peopleData) {
								additionalData.peopleData.forEach(peopleData => {
									if (ticketList.people_id == peopleData.id) {
										dataFormats["peopleName"] = peopleData?.name;
										dataFormats["peopleAvatar"] = peopleData?.peopleAvatar;
										dataFormats["recipientId"] = peopleData?.unique_ref;
										if (peopleData && peopleData.labelInfo) {
											dataFormats["peopleLabelId"] = peopleData.labelInfo?.id;
											dataFormats["peopleLabelName"] = peopleData.labelInfo?.name;
											dataFormats["peopleLabelColor"] = peopleData.labelInfo?.color;
											dataFormats["peopleLabelColorText"] = peopleData.labelInfo?.color_text;
										}
									}
								});
							}
							var statusData = [];
							if (ticketData && ticketData.ticketStatus) {
								ticketData.ticketStatus.forEach(ticketStatus => {
									if (ticketStatus.model_id == ticketList.id) {
										statusData.push(ticketStatus);
									}
								});
								if (statusData && statusData.length > 0) {
									let lastStatusData = statusData.pop();
									dataFormats["statusName"] = lastStatusData.name;
									dataFormats["className"] = lastStatusData.model_type;
									// console.log("time 1", lastStatusData.created_at);
									// console.log("convert time 1", moment(lastStatusData.created_at + '.000+0300').local());
									// dataFormats["abandonAt"] = moment(lastStatusData.created_at + '.000+0300').local();
								}
							}
							dataFormat.push(dataFormats);
						});
					}
					let lists = [];
					if (data.pagnitation && data.pagnitation.pageCount && data.pagnitation.pageCount <= data.pagnitation.current) {
						params.current = data.pagnitation.pageCount;
						this.changeParams(params);
					}
					this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
						lists = result.concat(dataFormat);
					});
					this.changeAllItems(lists);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatItem(item: WhatsappTicket) {
		let lists = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
			lists = result.concat([item]);
		});
		this.changeAllItems(lists);
	}

	spliceItem(id: number) {
		let allItems = [];
		this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
		if (allItems && allItems.length > 0) {
			for (var i = 0; i < allItems.length; ++i) {
				if (allItems[i].id == id) { allItems.splice(i, 1); }
				this.removelibraryById(id);
			}
		}
		this.changeAllItems(allItems);
	}

	getItem(params: any = null) {
		var conditions: any = {};
		if (params && params.id) {
			conditions['condition'] = "id|=|" + params.id
		}
		if (this.routeParams && this.routeParams.id) {
			conditions['condition'] = "id|=|" + this.routeParams.id
		}
		this.routeParams = (params) ? params : this.routeParams;
		if (conditions && conditions.condition) {
			this.commonService.storeItem(this.newUrl, conditions, true, 'optionOne')
				.pipe(untilDestroyed(this, 'unSubscribe'))
				.subscribe(data => {
					if (data.success) {
						var ticketData = data.data;
						var additionalData = data.additional;
						var dataFormat;
						if (ticketData && ticketData.rows.length > 0) {
							ticketData.rows.forEach(ticketList => {
								var dataFormats = {
									id: ticketList.id,
									ticketId: ticketList.id,
									whatsapp_id: ticketList.whatsapp_id,
									code: ticketList.code,
									client_id: ticketList.client_id,
									agent_id: ticketList.agent_id,
									priority: ticketList.priority,
									is_active: ticketList.is_active,
									is_closed: ticketList.is_closed ? true : false,
									comment: ticketList.comment,
									people_id: ticketList.people_id,
									created_by: ticketList.created_by,
									updated_by: ticketList.updated_by,
									created_at: moment(ticketList.created_at + '.000+0300').local(),

									last_message_on: ticketList.updated_at,
									deleted_at: ticketList.deleted_at,
									is_closeable: ticketList.is_closeable,
									agentName: ticketList.agentInfo?.name,
									agentAvatar: ticketList.agentInfo?.person_details?.image_api,
									createdByAvatar: ticketList.agentInfo?.person_details?.image_api,
									agentLiveStatus: ticketList.agentInfo?.person_details?.agentLiveStatus,
									statusChanges: null,
								}
								if (ticketList.updated_at && ticketList.updated_at != '0000-00-00 00:00:00') {
									dataFormats['updated_at'] = moment(ticketList.updated_at + '.000+0300').local();
								} else {
									dataFormats['updated_at'] = moment(ticketList.created_at + '.000+0300').local();
								}
								additionalData.ChatData.forEach(ChatData => {
									if (ChatData.people_id == ticketList.people_id) {
										if (ChatData.people_id == ticketList.people_id) {
											dataFormats["clientSiteId"] = ChatData.client_site_id;
											dataFormats["sms_message_sid"] = ChatData.sms_message_sid;
											dataFormats["text"] = ChatData.text;
											dataFormats["from"] = ChatData.from;
											dataFormats["to"] = ChatData.to;
											dataFormats["user_image"] = ChatData.user_image;
											dataFormats["media"] = ChatData.media;
											dataFormats["json"] = ChatData.json;
											dataFormats["status"] = ChatData.status;
											dataFormats["is_read"] = ChatData.is_read;
											dataFormats["parent_id"] = ChatData.id;
											dataFormats["unReadCount"] = ticketList.unreadcount?ticketList.unreadcount:0;
											if (ChatData && ChatData.clientSiteInfo) {
												dataFormats["clientSiteTextColor"] = ChatData.clientSiteInfo?.textcolor;
												dataFormats["clientSiteName"] = ChatData.clientSiteInfo?.account_name;
												if (ChatData && ChatData.clientSiteInfo && ChatData.clientSiteInfo.details) {
													dataFormats["siteName"] = ChatData.clientSiteInfo.details?.name;
													dataFormats["siteId"] = ChatData.clientSiteInfo.details?.id;
													dataFormats["siteIcon"] = ChatData.clientSiteInfo.details?.icon;
													dataFormats["siteColor"] = ChatData.clientSiteInfo.details?.color;
													dataFormats["clientSiteColor"] = ChatData.clientSiteInfo.details?.color;
												}
											}
										}
									}
								});
								if (additionalData && additionalData.peopleData) {
									additionalData.peopleData.forEach(peopleData => {
										if (ticketList.people_id == peopleData.id) {
											dataFormats["peopleName"] = peopleData?.name;
											dataFormats["peopleAvatar"] = peopleData?.peopleAvatar;
											dataFormats["recipientId"] = peopleData?.unique_ref;
											if (peopleData && peopleData.labelInfo) {
												dataFormats["peopleLabelId"] = peopleData.labelInfo?.id;
												dataFormats["peopleLabelName"] = peopleData.labelInfo?.name;
												dataFormats["peopleLabelColor"] = peopleData.labelInfo?.color;
												dataFormats["peopleLabelColorText"] = peopleData.labelInfo?.color_text;
											}
										}
									});
								}
								var statusData = [];
								if (ticketData && ticketData.ticketStatus) {
									ticketData.ticketStatus.forEach(ticketStatus => {
										if (ticketStatus.model_id == ticketList.id) {
											statusData.push(ticketStatus);
										}
									});
									if (statusData && statusData.length > 0) {
										let lastStatusData = statusData.pop();
										dataFormats["statusName"] = lastStatusData.name;
										dataFormats["className"] = lastStatusData.model_type;
									}
								}
								dataFormat = dataFormats;
							});
						}
						this.changeAllItemsByItem(dataFormat);
						this.changeItem(dataFormat);
					} else {
						this.alertService.webErrorShow(data);
					}
				},
					error => console.log('Error ::' + error)
				);
		}
		else {
			this.changeItem(new WhatsappTicketModel({}));
		}
	}

	store(data: any) {
		this.commonService.storeItem(this.url, data)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.concatItem(data.data);
				this.changeItem(data.data);
				// this.alert('Success', 'Created Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.commonService.apiAlertResponse('Danger', error);
				}
			);
	}

	update(url: any, data: any) {
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.success) {
					var ticketList = data.data;
					var dataFormat;
					if (ticketList && ticketList.id) {
						var dataFormats = {
							id: ticketList.id,
							ticketId: ticketList.id,
							whatsapp_id: ticketList.whatsapp_id,
							code: ticketList.code,
							client_id: ticketList.client_id,
							agent_id: ticketList.agent_id,
							priority: ticketList.priority,
							is_active: ticketList.is_active,
							is_closed: ticketList.is_closed ? true : false,
							comment: ticketList.comment,
							people_id: ticketList.people_id,
							created_by: ticketList.created_by,
							updated_by: ticketList.updated_by,
							created_at: moment(ticketList.created_at + '.000+0300').local(),
							last_message_on: ticketList.updated_at,
							deleted_at: ticketList.deleted_at,
							is_closeable: ticketList.is_closeable,
							statusChanges: null,
						}

						if (ticketList.agentInfo) {
							dataFormats["agentName"] = ticketList.agentInfo.name;
							if (ticketList.agentInfo.person_details) {
								dataFormats["agentAvatar"] = ticketList.agentInfo.person_details.image_api;
								dataFormats["createdByAvatar"] = ticketList.agentInfo.person_details.image_api;
								dataFormats["agentLiveStatus"] = ticketList.agentInfo.person_details.agentLiveStatus ? ticketList.agentInfo.person_details.agentLiveStatus : 'online';
							}
						}

						if (ticketList.clientSiteInfo) {
							dataFormats["clientSiteTextColor"] = ticketList.clientSiteInfo?.textcolor;
							dataFormats["clientSiteName"] = ticketList.clientSiteInfo?.account_name;
							dataFormats["clientSiteId"] = ticketList.clientSiteInfo?.id;
							if (ticketList && ticketList.clientSiteInfo && ticketList.clientSiteInfo.details) {
								dataFormats["siteName"] = ticketList.clientSiteInfo.details?.name;
								dataFormats["siteId"] = ticketList.clientSiteInfo.details?.id;
								dataFormats["siteIcon"] = ticketList.clientSiteInfo.details?.icon;
								dataFormats["siteColor"] = ticketList.clientSiteInfo.details?.color;
								dataFormats["clientSiteColor"] = ticketList.clientSiteInfo.details?.color;
							}
						}

						if (ticketList.peopleData && ticketList.peopleData.id) {
							dataFormats["peopleName"] = ticketList.peopleData?.name;
							dataFormats["peopleAvatar"] = ticketList.peopleData?.peopleAvatar;
							dataFormats["recipientId"] = ticketList.peopleData?.unique_ref;
							if (ticketList.peopleData && ticketList.peopleData.labelInfo) {
								dataFormats["peopleLabelId"] = ticketList.peopleData.labelInfo?.id;
								dataFormats["peopleLabelName"] = ticketList.peopleData.labelInfo?.name;
								dataFormats["peopleLabelColor"] = ticketList.peopleData.labelInfo?.color;
								dataFormats["peopleLabelColorText"] = ticketList.peopleData.labelInfo?.color_text;
							}
						}
						var statusData = [];
						if (ticketList.ticketStatus) {
							if (statusData && statusData.length > 0) {
								let lastStatusData = statusData.pop();
								dataFormats["statusName"] = lastStatusData.name;
								dataFormats["className"] = lastStatusData.model_type;
							}
						}
					}
					dataFormat = dataFormats;
					this.changeAllItemsByItem(dataFormat);
					this.changeItem(dataFormat);
				} else {
					this.alertService.webErrorShow(data);
				}
				// this.alert('Info', 'Updated Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					if (error == 'This Leads has an Open Tickets already...') {
						this.alertService.socketShow('info', 'Reopen not allowed for this contact !!!');
					} else {
						this.commonService.apiAlertResponse('Danger', error);
					}
				}
			);
	}

	destroy(id: number) {
		this.commonService.deleteItem(this.url, id)
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				this.spliceItem(id);
				this.spliceItem(id);
				// this.alert('Danger', 'Destroyed Successfully !!!');
			},
				error => {
					console.log('Error ::' + error);
					// this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
					this.commonService.apiAlertResponse('Warning', error);
				}
			);
	}

	close(id: number, data: any, url: string = null) {
		// url = (url) ? url : this.url;
		this.commonService.storeItem(url, data, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data && data.success) {
					this.spliceItem(id);
					this.removelibraryById(id);
					this.isCloseTicketSource.next(true);
					this.alert('Info', 'Closed Successfully !!!');
				} else {
					if (data.errors.message == 'Invalid feed!') {
						this.changeAllItems([]);
						this.isCloseTicketSource.next(false);
						this.getAllItems();

					} else {
						this.alertService.webErrorShow(data);
						this.isCloseTicketSource.next(false);
					}
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		this.isParams = false;
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = event.pageIndex + 1;
				newParams.limit = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity.name : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.sort = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				// this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getExportUrl(data: any, exportType = 'excel') {
		let domain = this.commonService.backendUrl;
		let param = btoa(JSON.stringify(data));
		let url = '';
		if (exportType == 'pdf') {
			url = domain + 'report/closed-ticket-exportpdf/' + param;
		} else {
			url = domain + 'report/closed-ticket-exportexcel/' + param;
		}

		return url;
	}

}
