import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShareModule } from "../../app-share.module";

import { AiCollectionRoutingModule } from './aiCollection-routing.module';
import { MainComponent } from './main/main.component';
import { PaymentListComponent } from './paymentList/paymentList.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentChatInfoComponent } from './payment-chat-info/payment-chat-info.component';
import { CurrentPaymentComponent } from './current-payment/current-payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentPendingComponent } from './payment-pending/payment-pending.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { SidebarComponent } from './sidebar/sidebar.component';


@NgModule({
  declarations: [
    MainComponent,
    PaymentListComponent,
    PaymentFormComponent,
    PaymentChatInfoComponent,
    CurrentPaymentComponent,
    PaymentSuccessComponent,
    PaymentPendingComponent,
    PaymentFailedComponent,
    SidebarComponent
  ],
  imports: [
    AppShareModule,
    CommonModule,
    AiCollectionRoutingModule
  ],
  exports: [
    MainComponent,
    PaymentListComponent,
    PaymentFormComponent,
    PaymentChatInfoComponent,
    CurrentPaymentComponent,
    PaymentSuccessComponent
  ]
})
export class AiCollectionModule { }
