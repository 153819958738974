import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tjiAnimation } from 'src/app/@tji/animation';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

import { ChangePasswordComponent } from './../changePassword/changePassword.component';
import { OtpDialogComponent } from 'src/app/library/dialog/otp/otp.component';

import { CookieService } from 'ngx-cookie-service';
import { PushNotificationOptions, PushNotificationService } from 'ngx-push-notifications';
import {
	UserService, PusherService, ChatService, ClientSiteService, ClientSite,
	NotifyService, Notify, EventService, TeamChatService, CommonService,
	ChatUserService, ChatUser, SocketService, UserProfileService,
	MediaService, Media,
	CommentService, ChildCommentService, CountService,
	WebclipService, Webclip, WebclipModel,
	AgentService,
} from 'src/app/@tji/_dbShare';
import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@UntilDestroy()
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: tjiAnimation
})
export class HeaderComponent implements OnInit, OnDestroy {

	countData: Array<any> = [];
	authUser: any;
	authUserId: number;
	isAuth: boolean = true;
	authUserClientId: number = this.userService.getAuthClientId();
	apiError: String;
	logoImg = 'assets/img/scrm/logo_avatar_grey.png';
	avatar = 'assets/img/no-user.jpg';
	profileImage = '';

	notifies: Notify[] = [];
	notifyParam: any;
	channel: any;
	notifyChannel: any;

	expiredText: string = null;
	// Temprory
	showNotice: boolean = false;
	clientSites: ClientSite[] = [];
	eventData: any;
	loop = true;

	isNotifySound: boolean = false;
	isChecked: boolean = true;

	isWebNotify: boolean = false;
	isWebChecked: boolean = true;

	isDesktopNotify: boolean = false;
	isDesktopChecked: boolean = true;

	countJsonData: any = null;

	chatUsers: ChatUser[] = [];
	languages: Array<any> = environment.languages;

	premiumDashboardWebclip: Webclip;

	isDueDay: boolean = false;
	dueDaysWarning: any = null;
	userInfo: any = null;
	userAvailability: any = null;

	@ViewChild('darkModeSwitch', { read: ElementRef }) element: ElementRef | undefined;
	checked: boolean = false;
	disabled = false;

	// @HostListener('window:blur', ["$event"]) onFocusout(event) {
	// 	document.addEventListener("visibilitychange", () => {
	// 		if (document.visibilityState === "visible") {
	// 			document.title = "Social Media Management CRM Software, CRM Tool – aioctopus.com";
	// 			this.longLiveStatus();
	// 		} else {
	// 			document.title = document.visibilityState;
	// 		}
	// 	});
	// }

	constructor(private _formBuilder: UntypedFormBuilder,
		public userService: UserService,
		public commonService: CommonService,
		public pusherService: PusherService,
		public notifyService: NotifyService,
		public userProfileService: UserProfileService,
		public clientSiteService: ClientSiteService,
		public chatService: ChatService,
		public commentService: CommentService,
		public childCommentService: ChildCommentService,
		private chatUserService: ChatUserService,
		private teamChatService: TeamChatService,
		private countService: CountService,
		private cd: ChangeDetectorRef,
		private socketService: SocketService,
		private eventService: EventService,
		public pushNotificationService: PushNotificationService,
		public webclipService: WebclipService,
		public mediaService: MediaService,
		public agentService: AgentService,
		private renderer: Renderer2,
		private snackBar: MatSnackBar,
		private router: Router,
		private cookieService: CookieService,
		public matDialog: MatDialog,
		public translate: TranslateService) {
		this.userService.authUser();
		this.userService.user.subscribe(data => {
			this.authUser = data;
			if (this.authUser && this.authUser.person && this.authUser.person.person_details && this.authUser.person.person_details.image_api) {
				this.profileImage = this.authUser.person.person_details.image_api;
			} else {
				this.profileImage = 'assets/img/no-user'
			}
			this.isAuth = (this.authUser && this.authUser.id > 0) ? true : false;
			this.authUserId = (this.authUser && this.authUser.id > 0) ? this.authUser.id : null;
			this.isNotifySound = (data && data.enable_sound == 1) ? true : false;
			this.isChecked = (this.isNotifySound === true) ? true : false;

			this.isWebNotify = (data && data.is_web_notify == 1) ? true : false;
			this.isWebChecked = (this.isWebNotify === true) ? true : false;

			this.isDesktopNotify = (data && data.is_desktop_notify == 1) ? true : false;
			this.isDesktopChecked = (this.isDesktopNotify === true) ? true : false;
		});
		this.eventService.eventData.pipe(untilDestroyed(this)).subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.notifyService.params.subscribe(data => this.notifyParam = data);
		this.notifyService.allItems.subscribe(data => this.notifies = data);
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
			if (this.clientSites && this.clientSites.filter(x => x.is_authorized === false).length > 0) {
				this.expiredText = 'One or more account has Unauthorized. Please Authorize it again, otherwise you cannot send or receive any messages.';
			} else {
				this.expiredText = null;
			}
		});
		this.chatUserService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			this.chatUsers = data;
		});
		this.webclipService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.premiumDashboardWebclip = (data && data.id && data.for && (data.for.toLowerCase() === 'premium-dashboard')) ? data : null;
		});
		this.countService.jsonData.pipe(untilDestroyed(this)).subscribe(data => {
			this.countJsonData = (data) ? Object.entries(data).map(([type, value]) => ({ type, value })) : [];
		});

		this.translate.addLangs(['en', 'ar']);
		this.setCurrentLang();
		this.checkDueDays();
		this.userInfo = JSON.parse(localStorage.getItem('tji_user'));
		this.userAvailability = localStorage.getItem('availability');

		this.agentService.isAcitve.pipe(untilDestroyed(this)).subscribe(data => {
			if (data == true) {
				this.activeAvailability();
			}
		});

	}

	ngOnInit() {
		this.getCount();
		// this.notifyService.getUnreadCounts({});
		this.getNotifies();
		// this.getCounts();
		// this.getChatUsers();
		// this.subscribePusher();
		this.subscribeWebPush();
		this.getExpiredAccount();
		// this.subscribeOctopusPusher();
		this.subscribePusherByAuthUser();
		setTimeout(() => {
			this.longLive();
		}, 10000);
		setTimeout(() => {
			this.activeAvailability();
		}, 1000);
	}

	ngAfterViewInit() {
		this.setIcon();
	}


	ngOnDestroy() {
		this.loop = false;
		this.chatUserService.unSubscribe();
		this.chatUserService.unSubscribeFilter();
	}

	longLive() {
		this.userService.keepLiveAuthUser('profile/keepAlive');
		setTimeout(() => {
			this.longLive();
		}, 1200000);
	}

	subscribeWebPush(): void {
		this.pushNotificationService.requestPermission();
	}

	getCount() {
		// this.countService.getItem();
		// setTimeout(() => {
		//     this.getCount();
		// }, 120000);
	}
	// doNotify() {
	//   const title = 'Hello';
	//   const options = new PushNotificationOptions();
	//   options.body = 'Native Push Notification';
	//   this.pushNotificationService.create(title, options).subscribe((notif) => {
	//     if (notif.event.type === 'show') {
	//       setTimeout(() => {
	//         notif.notification.close();
	//       }, 30000);
	//     }
	//     if (notif.event.type === 'click') {
	//       notif.notification.close();
	//     }
	//     if (notif.event.type === 'close') {
	//     }
	//   },
	//   (err) => {
	//        console.log(err);
	//   });
	// }

	logout() {
		this.userService.logout('api/profile/logout', {}).subscribe(data => {
			if (data && data.success) {
				this.isAuth = (this.authUser && this.authUser.id > 0) ? true : false;
				this.pusherService.unSubscribeChannel('authuser');
				setTimeout(() => {
					localStorage.clear();
					this.cookieService.deleteAll();
					this.clearCookie();
					this.userService.clearCookie();
					this.userService.storedUser = null;
					this.userService.storedRoles = [];
					this.userService.storedPermissions = [];
					this.socketService.changeLibraries([]);
					this.router.navigateByUrl('/');
					window.location.reload();
				}, 100);
			}
		});
	}

	public clearCookie() {
		if (this.cookieService.check('tji_token')) {
			this.cookieService.delete('tji_token', '/');
		}
		if (this.cookieService.check('tji_user')) {
			this.cookieService.delete('tji_user', '/');
		}
		if (this.cookieService.check('tji_permissions')) {
			this.cookieService.delete('tji_permissions', '/');
		}
		if (this.cookieService.check('tji_roles')) {
			this.cookieService.delete('tji_roles', '/');
		}
		if (this.cookieService.check('language')) {
			this.cookieService.delete('language', 'en');
		}
	}

	getNotifies() {
		this.notifyParam.all = 1;
		this.notifyParam.count = 20;
		if (this.showNotice) {
			this.notifyService.getByUrl(this.notifyParam, 'unread-notifications');
		}
		// this.notifyService.getUnreadCounts({});
		setTimeout(() => {
			if (this.loop) {
				this.getNotifies();
			}
		}, 120050);
	}

	getCounts() {
		this.countService.getItem();
		if (this.countJsonData && this.countJsonData.length < 1) {
			setTimeout(() => {
				this.getCounts();
			}, 50000);
		}
	}

	getChatUsers() {
		this.chatUserService.getAllItems();
	}

	setCurrentLang() {
		let cookieLanguage: string = this.commonService.getLanguage();
		let language: string = (cookieLanguage) ? cookieLanguage : this.translate.currentLang;
		language = (language) ? language : this.translate.getBrowserLang();
		let lang: string = language.match(/en|ar/) ? language : 'en';
		if (!cookieLanguage || cookieLanguage === null) { this.commonService.setLanguage(lang); }
		if (lang) { this.translate.use(lang); }
		if (!this.translate.currentLang) {
			this.translate.use('en')
			this.translate.setDefaultLang('en');
			this.commonService.setLanguage('en');
		}
	}

	getCurrentLangIcon(): string {
		let cookieLanguage: string = this.commonService.getLanguage();
		let language: string = (cookieLanguage) ? cookieLanguage : this.translate.currentLang;
		if (language && language === 'ar') {
			document.querySelector('html')?.setAttribute('dir', 'rtl');
			return 'fa-ar';
		} else {
			document.querySelector('html')?.removeAttribute('dir');
			return 'fa-en';
		}
	}

	setCurrentLanguage(lang: string) {
		let updateValue = { 'value': lang, 'key': 'default_lang', 'id': this.authUser.id };
		this.userProfileService.updateProfileSettings(updateValue, 'default_lang');
		this.commonService.setLanguage(lang);
		this.translate.use(lang);
	}

	markAsRead(notify: Notify) {
		this.notifyService.markAsRead(notify.id);
	}

	markAsAllRead() {
		this.notifyService.markAsAllRead();
	}

	subscribePusher() {
		this.channel = this.pusherService.subscribeChannel('authuser');
		this.channel.bind('syncdashboard', (data) => {
			this.getNotifies();
			// this.pusherService.alertPusher('Info', data.data.message, data.data.icon);
			// this.pusherService.alert('Info', data.data.message);
		});
		this.channel.bind('newmessage', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', data.data.message, data.data.icon);
		});
		this.channel.bind('ticketassigned', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', 'A New Ticket Assigned', 'receipt');
		});
		this.channel.bind('ticketreassigned', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', 'A New Ticket Re-Assigned', 'receipt');
		});
		this.channel.bind('ticketremoved', (data) => {
			this.pusherService.alertPusher('Info', data.data.message, 'receipt');
		});
		this.channel.bind('aticketassigned', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', 'A New Ticket Assigned', 'receipt');
		});
		this.channel.bind('aticketreassigned', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', 'A New Ticket Re-Assigned', 'receipt');
		});
		this.channel.bind('aticketremoved', (data) => {
			this.pusherService.alertPusher('Info', data.data.message, 'receipt');
		});
		this.channel.bind('comment', (data) => {
			this.pusherService.alertPusher('Info', data.data.message, 'comments-o');
		});
		this.channel.bind('accountsessionexpired', (data) => {
			this.expiredText = data.data.message;
			// this.pusherService.alertPusher('Info', data.data.message, data.data.icon);
		});
		this.channel.bind('ticketabandon', (data) => {
			this.pusherService.alertPusher('Info', data.data.message, data.data.icon);
			var ticketId = (data && data.data && data.data.model_id) ? data.data.model_id : null;
			var siteName = (data && data.data && data.data.site) ? data.data.site : null;
			if (ticketId && siteName) {
				this.chatService.getTicket(ticketId, siteName);
			}
		});
		this.channel.bind('mediashare', (data) => {
			this.getNotifies();
			this.pusherService.alertPusher('Info', data.data.message, data.data.icon);
		});
	}

	// subscribeOctopusPusher() {
	// 	if (environment.enableOctopusSocket) {
	// 		if (this.authUserClientId && this.authUserClientId > 0) {
	// 			let channel = 'client-users.' + this.authUserClientId;
	// 			this.socketService.subscribeChannelRaw(channel, 'ClientUsersEvent');
	// 			// let channel1 = 'client-notification.' + this.authUserClientId;
	// 			// this.socketService.subscribeChannelRaw(channel1, 'ClientUsersEvent');
	// 		}
	// 	}
	// }

	subscribePusherByAuthUser() {
		if (environment.enableOctopusSocket) {
			let channel = 'App.Models.User.' + this.authUserId;
			this.socketService.subscribeChannelRaw(channel, 'authme');
		}
	}

	checkEventData() {
		let eventName = (this.eventData && this.eventData.eventName) ? this.eventData.eventName : null;
		if (eventName) {
			let data = this.eventData.data;
			switch (eventName) {
				case 'login':
				case 'Login':
					let auth = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					// this.chatUserService.changeAllItemsByItem(auth);
					this.eventService.showAlert((auth.isOnline) ? 'Logged In' : 'Logged Out',
						(auth.isOnline) ? auth.displayName + ' is now Online.' : auth.displayName + ' is now Offline.', 'info');
					if (auth.isOnline && auth.userInfo && auth.userInfo.id == this.authUser.id) {
						setTimeout(() => {
							localStorage.clear();
							this.cookieService.deleteAll();
							this.clearCookie();
							this.userService.clearCookie();
							this.userService.storedUser = null;
							this.userService.storedRoles = [];
							this.userService.storedPermissions = [];
							this.socketService.changeLibraries([]);
							this.router.navigateByUrl('/');
							window.location.reload();
						}, 100);
					}
					break;
				case 'Logout':
				case 'logout':
					let authData = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					// this.chatUserService.changeAllItemsByItem(authData);
					this.eventService.showAlert('Logged Out', authData.displayName + ' is now Offline.', 'info');
					if (authData.userInfo && authData.userInfo.id == this.authUser.id) {
						setTimeout(() => {
							localStorage.clear();
							this.cookieService.deleteAll();
							this.clearCookie();
							this.userService.clearCookie();
							this.userService.storedUser = null;
							this.userService.storedRoles = [];
							this.userService.storedPermissions = [];
							this.socketService.changeLibraries([]);
							this.router.navigateByUrl('/');
							window.location.reload();
						}, 100);
					}
					break;
				case 'availabilityforticket':
				case 'Availabilityforticket':
					let availability = (this.eventData && this.eventData.data) ? this.eventData.data : null;
					if (availability && availability.userInfo && availability.userInfo.id != this.authUser.id) {
						this.eventService.showAlert((availability.availability) ? 'Available' : 'Not Available',
							(availability.availability) ? availability.displayName + ' shall start receiving tickets from Bot..' : availability.displayName + ' shall no longer receive Ticket From Bot.', 'info');
					}
					break;
				case 'CountTypeEvent':
				case 'CountTypeEvent':
					let countTypeData = (this.eventData && this.eventData.data) ? this.eventData.data : [];
					if (countTypeData) { this.countService.changeJsonDataByType(countTypeData); }
					break;
				case 'CountEvent':
				case 'countEvent':
					let countData = (this.eventData && this.eventData.data) ? this.eventData.data : [];
					if (countData) { this.countService.changeJsonData(countData); }
					break;
				case 'AuthUserCountEvent':
				case 'authUserCountEvent':
					let count_data = (this.eventData && this.eventData.data) ? this.eventData.data : [];
					if (count_data && count_data.length > 0) { this.notifyService.changeUnreadCounts(count_data); }
					break;
				case 'CommentEvent':
				case 'commentEvent':
					if (data && data.comment_id) {
						this.childCommentService.replacelibrary(data);
						this.commentService.incrementChildCountByCommentId(data.comment_id);
					} else {
						this.commentService.replacelibrary(data);
					}
					break;
				case 'syncdashboard':
				case 'newfeed':
				case 'newmessage':
				case 'ticketassigned':
				case 'ticketreassigned':
					this.getNotifies();
					break;
				case 'accountsessionexpired':
					this.expiredText = data.data.message;
					break;
				case 'ticketabandon':
					var ticketId = (data && data.data && data.data.model_id) ? data.data.model_id : null;
					var siteName = (data && data.data && data.data.site) ? data.data.site : null;
					if (ticketId && siteName) {
						this.chatService.getTicket(ticketId, siteName);
					}
					break;
				case 'mediashare':
					var mediaItem = (data && data.id && data.tableName === 'media') ? data : null;
					if (mediaItem && mediaItem.id) {
						this.mediaService.concatItem(mediaItem);
					}
					break;
				default:
					break;
			}
		}
	}

	getExpiredAccount() {
		if (!this.userService.roleMatch(['Super Admin', 'Reseller'])) {
			this.clientSiteService.getAllItems([]);
			this.agentService.getAllAgent();
		}
	}

	/** Add / Update Agent */
	changePassword(): void {
		let dialogRef = this.matDialog.open(ChangePasswordComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: this.authUser,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				this.userService.changePassword('profile/changePassword', formData.value);
			});
	}

	/** Show OTP Premium Dashboard */
	getPremiumDashboardKey() {
		let webclip = this.premiumDashboardWebclip;
		if (!(webclip && webclip.id && webclip.for === 'premium-dashboard')) {
			let webclipData: Webclip = new WebclipModel({});
			webclipData.user_id = this.authUserId;
			webclipData.client_id = this.authUserClientId;
			webclipData.for = 'premium-dashboard';
			this.webclipService.store(webclipData, 'dashboard/generateKey');
		}

		let dialogRef = this.matDialog.open(OtpDialogComponent, {
			width: "600px"
		});
	}

	changeNotifySound() {
		let changeStatus = (this.isNotifySound === true) ? '0' : '1';
		let updateValue = { 'value': changeStatus, 'key': 'enable_sound', 'id': this.authUser.id };
		this.userProfileService.updateProfileSettings(updateValue, 'enable_sound');
	}

	changeDesktopNotifyStatus() {
		let changeStatus = (this.isDesktopNotify === true) ? '0' : '1';
		let updateValue = { 'value': changeStatus, 'key': 'is_desktop_notify', 'id': this.authUser.id };
		this.userProfileService.updateProfileSettings(updateValue, 'is_desktop_notify');
	}

	changeWebNotifyStatus() {
		let changeStatus = (this.isWebNotify === true) ? '0' : '1';
		let updateValue = { 'value': changeStatus, 'key': 'is_web_notify', 'id': this.authUser.id };
		this.userProfileService.updateProfileSettings(updateValue, 'is_web_notify');
	}

	playAudio() {
		this.commonService.playAudio();
	}
	checkDueDays() {
		var errors = JSON.parse(localStorage.getItem('tji_user'));
		if (errors && errors['warningMessage'] && errors['warningMessage']['message']) {
			this.isDueDay = true;
			this.dueDaysWarning = errors['warningMessage']['message'];
		}
	}

	setIcon() {
		if (this.element) {
			const targetSpan: HTMLElement = this.element.nativeElement.querySelector('.mat-slide-toggle-thumb');
			while (targetSpan.firstChild) {
				targetSpan.firstChild.remove();
			}
			const elem = this.renderer.createElement('mat-icon');
			const icon = this.checked ? 'mark_unread_chat_alt' : 'speaker_notes_off';
			elem.setAttribute('class', 'mat-icon notranslate material-icons mat-icon-no-color light-mode-switch-icon');
			elem.textContent = icon
			targetSpan.appendChild(elem);
		}
		// this.cd.detectChanges();
	}

	activeAvailability() {
		this.userAvailability = localStorage.getItem('availability');
		if (this.userAvailability && this.userAvailability == 1 && this.userInfo.person_type == 'agent') {
			this.checked = true;
			this.setIcon();
			return true;
		} else {
			this.checked = false;
			this.setIcon();
			return true;
		}
	}

	// inactiveAvailability(): boolean {
	// 	this.userAvailability = localStorage.getItem('availability');
	// 	if (this.userAvailability && this.userAvailability == 0 && this.userInfo.person_type == 'agent') {
	// 		return true;
	// 	}
	// 	return false;
	// }

	/** activeTicket */
	changectiveAvailability(event: MatSlideToggleChange): void {
		this.checked = event.source.checked;
		// this.checked = !this.checked;
		if (this.checked) {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '600',
				data: {
					type: 'alert',
					message: 'You will start receiving tickets from Bot.',
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.data) {
					var data = {
						id: this.userInfo.person_id,
						availability: 1
					}
					this.agentService.ActiveAgentTick("agent/availableforticket", data);
				} else {
					this.checked = false;
					this.setIcon();
					this.cd.detectChanges();
				}
			});

		} else {
			let confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
				disableClose: false,
				width: '600',
				data: {
					type: 'alert',
					message: 'You will no longer receive Ticket From Bot.',
				}
			});
			confirmDialogRef.afterClosed().subscribe(result => {
				if (result && result.data) {
					var data = {
						id: this.userInfo.person_id,
						availability: 0
					}
					this.agentService.ActiveAgentTick("agent/availableforticket", data);
				} else {
					this.checked = true;
					this.setIcon();
				}
			});
		}
	}
}
