import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';
import { TruncatePipe } from 'src/app/pipes/string/truncate.pipe';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { MediaViewComponent } from 'src/app/library/dialog/mediaView/mediaView.component';
// import { PersonSettingFormComponent } from '../personSettingForm/personSettingForm.component';

import {
	User, UserService,
	MediaService, Media, AlertService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'media-rightbar',
	templateUrl: './media-rightbar.component.html',
	styleUrls: ['./media-rightbar.component.scss'],
	providers: [OrderByPipe, TruncatePipe]
})

export class MediaRightbarComponent {
	@Output() onClose = new EventEmitter();
	authUser: User;
	medias: Media[] = [];
	mediaParam: any;
	mediaForm: UntypedFormGroup;
	isUploadLoader: boolean = false;
	loader: boolean = true;
	userType = JSON.parse(localStorage.getItem('tji_user'));
	constructor(private _formBuilder: UntypedFormBuilder,
		public mediaService: MediaService,
		public userService: UserService,
		public matDialog: MatDialog,
		private orderByPipe: OrderByPipe,
		private cd: ChangeDetectorRef,
		private alertService: AlertService) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.mediaService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.medias = this.orderByPipe.transform(data, '-_id');
			setTimeout(() => {
				this.cd.detectChanges();
			}, 300);
		});

		this.mediaService.params.pipe(untilDestroyed(this)).subscribe(data => this.mediaParam = data);
		this.mediaService.uploadLoader.pipe(untilDestroyed(this)).subscribe(data => this.isUploadLoader = data);
		this.mediaForm = this.createForm();
		this.mediaService.loader.pipe(untilDestroyed(this)).subscribe(data => {
			this.loader = data;
		});
	}

	ngOnInit() {
		this.getMedias();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.mediaService.resetParams();
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			file: [null],
			asset: [null],
			asset_name: [null],
			asset_type: [null],
		});
	}

	getMedias() {
		let clientId = this.userService.getAuthClientId();
		this.mediaParam.user_id = this.authUser.id;
		this.mediaParam.client_id = clientId;
		this.mediaService.getAllItems();
	}

	typeData: string = 'image';
	imageHandler(event) {
		let reader = new FileReader();
		let fileData = null;

		if (event.target.files && event.target.files.length) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);

			reader.onload = (e: any) => {
				this.mediaService.isUploadLoader.next(true);
				var types = file.type.split("/");
				this.typeData = null;
				var typeDatas = types[1];
				switch (typeDatas) {
					case "JPEG": case "JPG":
					case "PNG": case "GIF":
					case "TIFF": case "RAW":
					case "jpeg": case "jpg":
					case "png": case "gif":
					case "tiff": case "raw":
					case "WebP": case "webp": case "WEBP":
						this.typeData = 'image'
						break;
					case "DOC": case "DOCX":
					case "ODT": case "PDF":
					case "PPT": case "PPTX":
					case "TXT": case "XLS":
					case "XLSX": case "ZIP":
					case "CVS":
					case "doc": case "csv":
					case "docx": case "odt":
					case "pdf": case "pptx":
					case "text": case "xls":
					case "xlsx": case "zip":
					case "cvs": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
						this.typeData = 'document'
						break;
					case "MP3": case "WAV":
					case "AAC": case "FLAC":
					case "Ogg": case "Ogg Vorbis":
					case "PCM": case "MPEG":
					case "mp3": case "wav":
					case "aac": case "flac":
					case "ogg": case "mpeg":
					case "ogg vorbis": case "pcm":
					case "vnd.dlna.adts":
						this.typeData = 'voice'
						break;
					case "WEBM": case "MPG":
					case "MP2":
					case "MPE": case "MPV":
					case "OGG": case "MP4":
					case "AVI": case "WMV":
					case "MOV": case "QT":
					case "FLV": case "SWF":
					case "AVCHD":
					case "webm": case "mpg":
					case "mp2":
					case "mpe": case "mpv":
					case "ogg": case "mp4":
					case "avi": case "wmv":
					case "mov": case "qt":
					case "flv": case "swf":
					case "avchd":
						this.typeData = 'video'
					default:
						break;
				}

				if (this.typeData != null) {
					this.mediaForm.patchValue({
						asset: reader.result,
						asset_name: file.name.replace(/ /g, "_"),
						asset_type: this.typeData,
					});
					this.mediaService.addStore(this.mediaForm.value);
					setTimeout(() => {
						this.mediaForm.patchValue({
							file: null,
							asset: null,
							asset_name: null,
							asset_type: null
						});
					}, 300);
				} else {
					this.alertService.webShow('Danger', 'This format not supported. contact admin!!!');
					this.mediaService.isUploadLoader.next(false);
				}
			};
		}
	}

	previewMedia(media: Media) {
		let dialogRef = this.matDialog.open(MediaViewComponent, {
			data: {
				item: media,
			}
		});
	}

	onTabChange(event) {
		let shared: boolean = (event && event === 'shared') ? true : false;
		let fav: boolean = (event && event === 'favorite') ? true : false;
		let all: boolean = (event && event === 'all') ? true : false;
		event = (event && (event === 'image' || event === 'video' || event === 'voice' || event === 'document')) ? event : null;
		if (fav) {
			this.mediaParam.type = null;
			this.mediaParam.favorite = 1;
			this.mediaParam.null = null;
		} else if (shared) {
			this.mediaParam.type = null;
			this.mediaParam.favorite = null;
			this.mediaParam.shared = 1;
		}else {
			this.mediaParam.type = event
			this.mediaParam.favorite = null;
			this.mediaParam.shared = null;
		}
		this.loader = true;
		this.mediaService.getAllItems();
	}

}
