import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FbTemplateMessageService, TemplateMessageService } from 'src/app/@tji/_dbShare';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-itrsbot',
  templateUrl: './fbitrsbot.component.html',
  styleUrls: ['./fbitrsbot.component.scss']
})
export class FbItrsbotComponent implements OnInit {
  dialogTitle: string = 'Create';
  action: any;
  linkItrs: any;
  ItrsDataList: any;
  itrsId: any;
  itrsData: any;
  noItrsDataList: boolean = false;

  myControl = new FormControl<string | any>('');
  filteredOptions: Observable<any[]>;

  constructor(
    public fbTemplateMessageService: FbTemplateMessageService,
    public matDialogRef: MatDialogRef<FbItrsbotComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,

  ) {
    this.fbTemplateMessageService.currentItrs.subscribe(data => this.linkItrs = data);
    this.fbTemplateMessageService.currentAllItrs.subscribe(data => { 
      this.ItrsDataList = data 
      if (this.ItrsDataList && this.ItrsDataList.length > 0) {
        this.getFilter();
      }
    });
    var data = {
      "client_site_id": this.linkItrs?.client_site_id,
      "limit": 2500,
      "current": 1
    }
    this.getInit(_data);
    this.fbTemplateMessageService.getIntractiItem(data, 'optionOne');
    // this.getInfo();
  }

  ngOnInit(): void {
  }

  getInit(data) {
    this.action = data.action;
    switch (data.action) {
      case 'new':
        this.dialogTitle = 'Link To on ITRS';
        break;
      case 'edit':
        this.dialogTitle = 'Link To on ITRS';
        break;
      default:
        break;
    }
  }

  getInfo() {
    if (this.ItrsDataList.itractiveinfo.length > 1) {
      this.noItrsDataList = false;
    } else {
      this.noItrsDataList = true;
    }
  }

  selectItrs(itrsId: any, itrsbot_id: any) {
    this.linkItrs.requireValue
    // this.linkItrs.requireValue.parameters.forEach((button, index) => {
    //   if (index == this.linkItrs.idx) {
    //     button.payload = "temp_itrs_"+itrsId;
    //   }
    // });
    this.itrsData = {
      idx: this.linkItrs.idx,
      requireValue: this.linkItrs.requireValue,
      value: itrsId
    }
  }

  getFilter(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.ItrsDataList.slice();
      }),
    );
  }
  displayFn(itrs: any): string {
    return itrs && itrs.name ? itrs.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.ItrsDataList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

}
