import { Component, ElementRef, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	MediaService, Media,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'gallery-item-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss']
})

export class ImageComponent implements OnInit, OnDestroy {
	authUser: User;
	@Input() media: Media = null;
    @Input() showCaption: boolean = true;
    @Input() showSend: boolean = false;
    @Output() onSendMedia = new EventEmitter();
    @Output() onDelete = new EventEmitter();
	@Output() onFavorite = new EventEmitter();
	@Output() onShareMedia = new EventEmitter();
	@Input() isChatMedia: boolean = false;

	constructor(public userService: UserService,
        public mediaService: MediaService,
		private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.cd.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void { 
		this.isChatMedia = false;
		this.cd.detectChanges();
		this.mediaService.resetParams();
	}

    showPreview(media: Media) {
        this.mediaService.changeItem(media);
    }

}
