import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { CommonService, Eorders, EordersService, EventService, SettingsService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { OrderStatusUpdateComponent } from '../order-status-update/order-status-update.component';
import { OrderViewComponent } from '../order-view/order-view.component';
import { AudioService } from 'src/app/@tji/_dbShare/alert/audio.service';
import { filter } from 'rxjs';
import { OrderReassignFormComponent } from '../order-reassign-form/order-reassign-form.component';

@UntilDestroy()
@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {

  orderList: Eorders[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.orderList);
  displayedColumns = ['date', 'name', 'order id', 'amount', 'product count', 'payment method', 'agentavatar', 'agent', 'status', 'action'];
  loop = true;
  baseImage: string = this.commonService.logo_image;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  eventData: any;

  constructor(public eordersService: EordersService, public eventService: EventService, public matDialog: MatDialog,
    private orderByPipe: OrderByPipe, private audioService: AudioService, private settingsService: SettingsService, public commonService: CommonService,) {

    this.eordersService.resetParams();
    this.eordersService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
      if (this.param) {
        this.param["status"] = "Confirmed"
        this.eordersService.getAllItems();
      }
    });

    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });

    this.eordersService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data.length > 0) {
        var orderData = data.filter(x => x.status == "Confirmed");
        if (orderData && orderData.length > 0) {
          this.orderList = this.orderByPipe.transform(orderData, '-_id');
          this.dataSource = new MatTableDataSource(this.orderList);
          this.dataSource.sort = this.sort;
          this.loop = true;
        } else {
          this.loop = false;
          this.orderList = orderData;
        }
      } else {
        this.orderList = [];
      }
    });

    this.eordersService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.getNotifies();
  }

  ngOnInit(): void {
    this.dataInit();
  }

  ngOnDestroy(): void {
    this.loop = false;
    this.eordersService.isLoader.next(true);
    this.eordersService.resetParams();
    this.eordersService.unSubscribe();
    this.eordersService.unSubscribeFilter();
  }

  getNotifies() {
    if (this.orderList && this.orderList.length > 0) {
      this.orderList.forEach(orderStatus => {
        if (orderStatus._id) {
          this.audioService.playOrderStatusAudio();
          this.loop = true;
        } else {
          this.loop = false;
        }
      });
    } else {
      this.loop = false;
    }

    setTimeout(() => {
      if (this.loop) {
        this.loop = true;
        this.getNotifies();
      }
    }, 2800);
  }

  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }

  dataInit() {
    this.eordersService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }

  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'orderCreate':
          var orderData = this.eventData.data;
          if (orderData.peopleInfo && orderData.peopleInfo.id) {
            orderData['name'] = orderData.peopleInfo.name;
            orderData['ref'] = orderData.peopleInfo.unique_ref;
            orderData['created_name'] = orderData.peopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.eordersService.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.agentInfo && orderData.agentInfo.person_id) {
            if (orderData.agent_id == orderData.agentInfo.person_id) {
              orderData['agentName'] = orderData.agentInfo.name;
              orderData['agentLiveStatus'] = orderData.agentInfo.liveStatus;
              orderData['agentAvatar'] = orderData.agentInfo.image_api;
            }
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }
          this.eordersService.concatItem(this.eventData.data);
          break;

        case 'orderUpdate':
          var orderData = this.eventData.data;
          if (orderData.peopleInfo && orderData.peopleInfo.id) {
            orderData['name'] = orderData.peopleInfo.name;
            orderData['ref'] = orderData.peopleInfo.unique_ref;
            orderData['created_name'] = orderData.peopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.eordersService.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.agentInfo && orderData.agentInfo.person_id) {
            if (orderData.agent_id == orderData.agentInfo.person_id) {
              orderData['agentName'] = orderData.agentInfo.name;
              orderData['agentLiveStatus'] = orderData.agentInfo.liveStatus;
              orderData['agentAvatar'] = orderData.agentInfo.image_api;
            }
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address ) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['address']['governorateName'] = governorate.name_en;
                   orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }

          this.eordersService.changeAllItemsByItem(orderData);
          break;

        case 'ordereassign':
          var orderData = this.eventData.data;
          if (orderData.peopleInfo && orderData.peopleInfo.id) {
            orderData['name'] = orderData.peopleInfo.name;
            orderData['ref'] = orderData.peopleInfo.unique_ref;
            orderData['created_name'] = orderData.peopleInfo.name;
          }

          if (orderData.StatusInfo && orderData.StatusInfo.length > 0) {
            this.eordersService.statusInfoData.next(orderData.StatusInfo)
          }

          if (orderData.PaymentsInfo && orderData.PaymentsInfo.PaymentId) {
            orderData['PaymentsInfo'] = orderData.PaymentsInfo;
          }

          if (orderData.clientSiteInfo && orderData.clientSiteInfo.id) {
            if (orderData.client_site_id == orderData.clientSiteInfo.id) {
              orderData['account_name'] = orderData.clientSiteInfo.account_name;
            }
          }

          if (orderData.agentInfo && orderData.agentInfo.person_id) {
            if (orderData.agent_id == orderData.agentInfo.person_id) {
              orderData['agentName'] = orderData.agentInfo.name;
              orderData['agentLiveStatus'] = orderData.agentInfo.liveStatus;
              orderData['agentAvatar'] = orderData.agentInfo.image_api;
            }
          }

          if (orderData.AvailableGovernarates && orderData.AvailableGovernarates.length > 0) {
            orderData.AvailableGovernarates.forEach(governorate => {
              if (orderData.address) {
                if (orderData.address.governarate_id == governorate._id) {
                  orderData['address']['governorateName'] = governorate.name_en;
                  orderData['governorateName'] = governorate.name_en;
                }

              } else {
                if (orderData.governorate_id == governorate._id) {
                  orderData['governorateName'] = governorate.name_en;
                }
              }
            });
          }

          this.eordersService.changeAllItemsByItem(orderData);
          break;
        case 'orderemoved':
          var orderData = this.eventData.data;
          this.eordersService.spliceItem(orderData._id);
          break;
        default:
          break;
      }
    }
  }

  getTotleQty(data: any = null): string {
    var totalQty = 0;
    if (data) {
      data.forEach(element => {
        if (element.quantity && element.quantity) {
          totalQty = totalQty + parseInt(element.quantity);
        }
      });
      return totalQty.toString();
    } else {
      return totalQty.toString();
    }
  }

  changeStatus(orderData) {
    this.eordersService.changeItem(orderData);
    let confirmDialogRef = this.matDialog.open(OrderStatusUpdateComponent, {
      data: { type: 'info' }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        var statusData = {
          "id": orderData._id,
          "status": result
        }
        this.eordersService.update('eorders/updatestatus', statusData);
      }
    });
  }


  viewStatus(orderData) {
    this.eordersService.changeItem(orderData);
    var params = { client_site_id: orderData.client_site_id ? orderData.client_site_id : null }
    this.settingsService.getProfile(params);
    let confirmDialogRef = this.matDialog.open(OrderViewComponent, {
      width: "400px",
      data: { type: 'info' }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {

      }
    });
  }
  /** Add / Update Tag */
  reassignOrder(ticket = null): void {
    this.eordersService.changeItem((ticket) ? ticket : null);
    let dialogRef = this.matDialog.open(OrderReassignFormComponent, {
      width: "600px",
      data: {
        action: 'edit',
        item: ticket,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        this.eordersService.allActiveSource.next([]);
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        let updateData: any = formData.value;
        let newAgentId: any = updateData.agent_id;
        if (newAgentId && newAgentId === ticket.agent_id) {
          updateData.status_mode = null;
        }
        switch (actionType) {
          case 'update':
            this.eordersService.reassingnOrder("eorders/assign", updateData);
            break;
        }
      });
  }
}