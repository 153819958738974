import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup } from '@angular/forms';
import * as Rx from 'rxjs';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { ApiFormComponent } from 'src/app/appAccount/socialSite/itrsItems/apiForm/apiForm.component';

import {
  User, UserService,
  ParamService, Param,
  IntractiveService, Intractive,
  IntractiveGroupService, IntractiveGroup,
  ApiDataModel, ApiData
} from 'src/app/@tji/_dbShare';


@UntilDestroy()
@Component({
  selector: 'itrsbot-itrs-api',
  templateUrl: './itrs-api.component.html',
  styleUrls: ['./itrs-api.component.scss']
})

export class ItrsApiComponent implements OnInit, OnDestroy {

  @ViewChild('apiDataTable') apiDataTable: MatTable<any>;

  authUser: User;
  dataSource: any = [];
  intractive: Intractive;
  intractiveGroup: IntractiveGroup;
  itrsParams: Param[] = [];

  apiData: ApiData[] = [new ApiDataModel({})];
  apiDataLength: number = 1;
  isShown: boolean = false; // hidden by default

  displayedColumns: string[] = ['id', 'key', 'is_default', 'value', 'action'];

  constructor(public userService: UserService,
    public paramService: ParamService,
    public intractiveService: IntractiveService,
    public intractiveGroupService: IntractiveGroupService,
    private router: Router,
    public matDialog: MatDialog) {
    this.userService.user.subscribe(data => this.authUser = data);
    this.paramService.allItems.pipe(untilDestroyed(this)).subscribe(data => this.itrsParams = data);
    this.intractiveService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.intractive = data
      if (this.intractive && this.intractive.itrsbot_id) {
        this.itrsParams["itrsbot_id"] = this.intractive.itrsbot_id;
        this.getItrsParams();
      }
      if (this.intractive && this.intractive.intractive_groups && this.intractive.intractive_groups.length > 0) {
        this.intractiveGroupService.changeItem(this.intractive.intractive_groups[0]);
      }
    });
    this.intractiveGroupService.item.pipe(untilDestroyed(this)).subscribe(data => {
      this.intractiveGroup = data;
      if (this.intractiveGroup && this.intractiveGroup.id) {
        this.apiDataInit();
      }
    });
    this.dataSource = this.apiData;
  }

  ngOnInit() {
    // this.getItrsParams();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.intractiveService.changeItem(null);
  }

  getItrsParams() {
    if (this.intractive && this.intractive.itrsbot_id) {
      var data = {
        itrsbot_id : this.intractive.itrsbot_id
      }
      this.paramService.getAllItems(data);
    }
  }

  apiDataInit() {
    if (this.intractiveGroup && this.intractiveGroup.json) {
      let jsonString = this.intractiveGroup.json;
      let jsonData = JSON.parse(jsonString);
      let existingApiData: ApiData[] = [];
      if (jsonData && jsonData && jsonData.length > 0) {
        for (var i = 0; i < jsonData.length; ++i) {
          jsonData[i].id = i + 1;
          existingApiData.push(new ApiDataModel(jsonData[i]));
        }
      }
      if (existingApiData && existingApiData.length > 0) {
        this.apiData = existingApiData;
      } else {
        this.apiData = [new ApiDataModel({})];
      }
    }
  }

  /** Add / Update Webhook */
  updateUrl(intractiveGroup = null): void {
    this.intractiveGroupService.changeItem(intractiveGroup);
    let dialogRef = this.matDialog.open(ApiFormComponent, {
      width: "600px",
      data: {
        action: (intractiveGroup) ? 'edit' : 'new',
        item: intractiveGroup,
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) { return; }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        const id: number = (response[2]) ? response[2] : null;
        switch (actionType) {
          case 'new':
            this.intractiveGroupService.store(formData.value);
            break;
          case 'update':
            var value = {
              "id":id,
              "key":"text",
              "value":formData.value.text,
            }
            this.intractiveGroupService.updateApiUrl(value);
            break;
        }
      });
  }

  getUrl(): string {
    if (this.intractiveGroup && this.intractiveGroup.id) {
      return this.intractiveGroup.text;
    } else if (this.intractive && this.intractive.intractive_groups && this.intractive.intractive_groups.length > 0) {
      return this.intractive.intractive_groups[0].text;
    }
    return '';
  }

  appendApiData() {
    let newApiData: ApiData = new ApiDataModel({});
    newApiData.id = this.apiData.length + 1;
    this.apiData.push(newApiData);
    this.apiDataTable.renderRows();
  }

  removeApiData(i: number) {
    this.apiData.splice(i, 1);
    this.apiDataTable.renderRows();
  }

  updateData() {
    let jsonInputParam = [];
    if (this.apiData && this.apiData.length > 0) {
      for (var i = 0; i < this.apiData.length; ++i) {
        if (this.apiData[i].key !== '' && this.apiData[i].value !== '') {
          let appendData = {
            'key': this.apiData[i].key,
            'value': this.apiData[i].value,
            'is_default': this.apiData[i].is_default,
          }
          jsonInputParam.push(appendData);
        }
      }
    }
    if (jsonInputParam && jsonInputParam.length > 0) {
      var apiData = {
        id: this.intractiveGroup.id,
        value: jsonInputParam,
        "key":"json_input_param",
      }
      this.intractiveGroupService.updatePostApiJson(apiData);
    }
  }

  getName(id): string {
    return 'value_' + id;
  }
}
