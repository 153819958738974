import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import {
  UserService, ClientToggleService, Client,
  MenuGroupService, MenuGroup,
  NotifyService, UnreadCount, CountService, WhatsappTicketService, TweetTicketService, TweetdmTicketService, MessengerTicketService, YoutubeTicketService, InstadmTicketService, WebchatTicketService
} from 'src/app/@tji/_dbShare';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  @ViewChild('leftSideBar', { static: true }) leftSideBar: MatSidenav;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  isExpanded = true;
  isShowing = false;
  showSubmenu: boolean = false;

  selectedClient: Client = null;

  isAdmin: any;
  menuGroups: MenuGroup[] = [];
  menuGroupParam: any;
  selectedMenu: any = null;
  selectedSubMenu: any = null;
  logoImg = './assets/images/tji/logo_black_bg.png';

  unreadCounts: UnreadCount[] = [];

  activeUrl: string = this.router.url;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private _formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public countService: CountService,
    public clientToggleService: ClientToggleService,
    public menuGroupService: MenuGroupService,
    public notifyService: NotifyService,
    private router: Router,
    public tweetTicketService: TweetTicketService,
		public tweetdmTicketService: TweetdmTicketService,
		public messengerTicketService: MessengerTicketService,
		public whatsappTicketService: WhatsappTicketService,
		public youtubeTicketService: YoutubeTicketService,
		public instadmTicketService: InstadmTicketService,
		public webchatTicketService: WebchatTicketService,) {
    this.userService.authUser();
    this.clientToggleService.item.subscribe(data => this.selectedClient = data);
    this.menuGroupService.allItems.subscribe(data => this.menuGroups = data );
    this.menuGroupService.params.subscribe(data => this.menuGroupParam = data);
    this.notifyService.unreadCounts.subscribe(data => this.unreadCounts = data);
    this.isAdmin = this.userService.isAuthUserIsAdmin();
  }

  ngOnInit() {
    // this.getMenuGroups();
    this.menuGroupService.getAllItems();
  }

  isDashboard(): boolean {
    return (window.location.href.includes('/app/dashboard') || window.location.href.includes('/app/agent-dashboard')) ? true : false;
  }

  getMenuGroups() {
    this.menuGroupParam.all = 1;
    this.menuGroupParam.with = 'menus,menus.subMenus';
    this.menuGroupService.getAllItems();
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  toggleShortMenu() {
    this.isExpanded = !this.isExpanded;
  }

  toggleLeftSideBar() {
    this.leftSideBar.toggle();
  }

  	getUnreadCount(menu): number {
      let output: number = 0;
	  	if(menu && menu.json) {
	        let input: any = JSON.parse(menu.json);
	        let siteName = (input && input.badgeSite) ? input.badgeSite : null;
	        let slug = (input && input.badgeName) ? input.badgeName : null;
	        if(siteName && slug) {
	            output = this.countService.getItemCount(slug, siteName);
	        }
	  	}
        return output;

    // if (menu && menu.json && this.unreadCounts.length > 0) {
    //   let input: any = JSON.parse(menu.json);
    //   let siteName = (input && input.badgeSite) ? input.badgeSite : null;
    //   let selectedUnreads = [];
    //   let output: number = 0;
    //   if (siteName) {
    //     selectedUnreads = this.unreadCounts.filter(x => x.name === input.badgeName).filter(y => y.site === siteName)
    //   } else {
    //     selectedUnreads = this.unreadCounts.filter(x => x.name === input.badgeName)
    //   }
    //   if (selectedUnreads && selectedUnreads.length > 0) {
    //     if (input && input.isBadgeTotal) {
    //       output = selectedUnreads.reduce((a, item) => a + item.count, 0);
    //     } else {
    //       output = selectedUnreads[0].count;
    //     }
    //   }
    //   return output;
    // }
    return 0;
  }

  isShowBadge(menu): boolean {
    if (menu && menu.json && this.unreadCounts.length > 0) {
      let input: any = JSON.parse(menu.json);
      return (input && input.isShowBadge) ? true : false;
    }
    return false;
  }

  isShowMenu(res): boolean {
    if (res) {
      let input: any = JSON.parse(res);
      return input.isReadAdmin
    }
    return true;
  }

  getClientName() {
    if(this.selectedClient && this.selectedClient.id) {
      return this.selectedClient.name;
    } else {
      return "-- Click to Select --";
    }
  }

    checkRoles(item): boolean {
      if(item.role) {
        let roles: Array<any> = item.roles;
        if(roles.length > 0) {
          return this.userService.personTypeMatch(roles);
        }
      }
      return true;
    }

  // checkMenuGroupPermissions(menuGroup): boolean {
  //   return true;
  //   let allPermissions = [];
  //   allPermissions = allPermissions.concat(menuGroup.permissions);
  //   if (menuGroup && menuGroup.menus && menuGroup.menus.length > 0) {
  //     for (var i = 0; i < menuGroup.menus.length; ++i) {
  //       let newMenu = menuGroup.menus[i];
  //       allPermissions = allPermissions.concat(newMenu.permissions);
  //       if (newMenu && newMenu.subMenus && newMenu.subMenus.length > 0) {
  //         for (var i = 0; i < newMenu.subMenus.length; ++i) {
  //           let newSubMenu = newMenu.subMenus[i];
  //           allPermissions = allPermissions.concat(newSubMenu.permissions);
  //         }
  //       }
  //     }
  //   }
  //   return (allPermissions && allPermissions.length > 0) ? this.userService.permissionMatch(allPermissions) : false;
  // }

  // checkMenuPermissions(menu): boolean {
  //   return false;
  //   let allPermissions = [];
  //   allPermissions = allPermissions.concat(menu.permissions);
  //   if (menu && menu.subMenus && menu.subMenus.length > 0) {
  //     for (var i = 0; i < menu.subMenus.length; ++i) {
  //       let newSubMenu = menu.subMenus[i];
  //       allPermissions = allPermissions.concat(newSubMenu.permissions);
  //     }
  //   }
  //   return (allPermissions && allPermissions.length > 0) ? this.userService.permissionMatch(allPermissions) : false;
  // }
  resetParams(){
		this.tweetTicketService.isParams = true;
		this.tweetdmTicketService.isParams = true;
		this.messengerTicketService.isParams = true;
		this.whatsappTicketService.isParams = true;
		this.youtubeTicketService.isParams = true;
		this.instadmTicketService.isParams = true;
		this.webchatTicketService.isParams = true;
    this.tweetTicketService.resetParams();
    this.tweetdmTicketService.resetParams();
		this.messengerTicketService.resetParams();
		this.whatsappTicketService.resetParams();
		this.youtubeTicketService.resetParams();
		this.instadmTicketService.resetParams();
		this.webchatTicketService.resetParams();
  }
}
