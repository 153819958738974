import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import * as moment from 'moment-timezone';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

declare var require: any;
var slugify = require('slugify')

@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class ClosedTicketService {
	url: string = 'closed-ticket';
	defaultParams: any = {
		'sort': 'updated_at|desc',
		'current': 1,
		'is_closed': 1,
		'limit': 15,
	};

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private allItemsSource = new BehaviorSubject<Array<any>>([]);
	allItems = this.allItemsSource.asObservable();

	private itemSource = new BehaviorSubject<any>({});
	item = this.itemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private snackBar: MatSnackBar,
		private alertService: AlertService,) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.getRecentClosedTickets('tickets/whatsappList'),
				this.getRecentClosedTickets('tickets/instaDmList'),
				this.getRecentClosedTickets('tickets/messengerList'),
				this.getRecentClosedTickets('tickets/tweetDmList'),
				this.getRecentClosedTickets('tickets/instagramlist'),
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}


	unSubscribe() {
		// console.log('UnSubscribed ClosedTicketService');
	}

	concatlibrary(allItems: Array<any>) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	removelibrary(item: any) {
		let oldLibraries = [];
		this.libraries.subscribe(data => oldLibraries = data);
		if (oldLibraries && oldLibraries.length > 0 && item && item.id && item.siteName) {
			oldLibraries = oldLibraries.filter(x => {
				return (x.id === item.id && x.siteName === item.siteName) ? false : true;
			});
		}
		this.changeLibraries(oldLibraries);
	}

	splicelibrary(item: any) {
		let oldLists = [];
		this.libraries.subscribe(data => oldLists = data);
		if (item && item.id && item.siteName) {
			let index = oldLists.findIndex(x => x.id === item.id && x.siteName === item.siteName);
			oldLists.splice(index, 1);
			this.changeLibraries(oldLists);
		}
	}

	replacelibrary(item: any) {
		let oldLibraries = [];
		let isReplaced: boolean = false;
		this.libraries.subscribe(data => oldLibraries = data);
		if (oldLibraries && oldLibraries.length > 0 && item && item.id && item.siteName) {
			for (var i = 0; i < oldLibraries.length; ++i) {
				if (oldLibraries[i].id === item.id && oldLibraries[i].siteName === item.siteName) {
					oldLibraries.splice(i, 1, item);
					isReplaced = true;
					break;
				}
			}
		}
		if (!isReplaced) { oldLibraries.push(item); }
		this.changeLibraries(oldLibraries);
	}

	changeLibraries(libraries: Array<any>) {
		this.librariesSource.next(libraries);
	}

	changeAllItems(allItems: Array<any>) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: any) {
		this.itemSource.next(item);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		return newParams;
	}

	resetParams() {
		const defaultParams: any = {
			'sort': 'updated_at|desc',
			'current': 1,
			'is_closed': 1,
			'limit': 25,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	// getRecentClosedTickets(params: any = null) {
	// 	this.commonService.getAll("tickets/whatsappList", params)
	// 		.pipe(untilDestroyed(this, 'unSubscribe'))
	// 		.subscribe(data => {
	// 			this.changeAllItems(data.data);
	// 			this.concatlibrary(data.data);
	// 		},
	// 			error => console.log('Error ::' + error)
	// 		);
	// }
	getRecentClosedTickets(url, params: any = null) {
		params = this.paramsInit(params);
		if (params.flag_id && params.flag_id.length < 5) {
			delete params.flag_id
		}
		this.commonService.storeItem(url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var ticketData = data.data;
					var additionalData = data.additional;
					var dataFormat = [];
					if (ticketData && ticketData.rows && ticketData.rows.length > 0) {
						ticketData.rows.forEach(ticketList => {
							var dataFormats = {
								id: ticketList.id,
								code: ticketList.code,
								client_id: ticketList.client_id,
								agent_id: ticketList.agent_id,
								priority: ticketList.priority,
								is_active: ticketList.is_active,
								is_closed: ticketList.is_closed,
								comment: ticketList.comment,
								people_id: ticketList.people_id,
								created_by: ticketList.created_by,
								updated_by: ticketList.updated_by,
								created_at: moment(ticketList.created_at + '.000+0300').local(),
								last_message_on: ticketList.updated_at,
								deleted_at: ticketList.deleted_at,
								is_closeable: ticketList.is_closeable,
								agentName: ticketList.agentInfo?.name,
								agentAvatar: ticketList.agentInfo?.person_details?.image_api,
								createdByAvatar: ticketList.agentInfo?.person_details?.image_api,
								agentLiveStatus: ticketList.agentInfo?.person_details?.agentLiveStatus,
								statusChanges: null,
							}
							additionalData.ChatData.forEach(ChatData => {
								if (ChatData.people_id == ticketList.people_id) {
									if (ChatData.people_id == ticketList.people_id) {
										dataFormats["clientSiteId"] = ChatData.client_site_id;
										dataFormats["sms_message_sid"] = ChatData.sms_message_sid;
										dataFormats["text"] = ChatData.text;
										dataFormats["from"] = ChatData.from;
										dataFormats["to"] = ChatData.to;
										dataFormats["user_image"] = ChatData.user_image;
										dataFormats["media"] = ChatData.media;
										dataFormats["json"] = ChatData.json;
										dataFormats["status"] = ChatData.status;
										dataFormats["is_read"] = ChatData.is_read;
										dataFormats["parent_id"] = ChatData.id;
										dataFormats["unReadCount"] = ChatData.unreadcount;
										if (ChatData && ChatData.clientSiteInfo) {
											dataFormats["clientSiteTextColor"] = ChatData.clientSiteInfo?.textcolor;
											dataFormats["clientSiteName"] = ChatData.clientSiteInfo?.account_name;
											if (ChatData && ChatData.clientSiteInfo && ChatData.clientSiteInfo.details) {
												dataFormats["siteName"] = ChatData.clientSiteInfo.details?.name;
												dataFormats["siteId"] = ChatData.clientSiteInfo.details?.id;
												dataFormats["siteIcon"] = ChatData.clientSiteInfo.details?.icon;
												dataFormats["siteColor"] = ChatData.clientSiteInfo.details?.color;
												dataFormats["clientSiteColor"] = ChatData.clientSiteInfo.details?.color;
											}
										}
										if (ChatData && ChatData.clientSiteInfo && ChatData.clientSiteInfo.details && ChatData.clientSiteInfo.details.name) {
											var siteName = ChatData.clientSiteInfo.details.name;
											if (siteName) {
												switch (siteName) {
													case "Twitter": case "twitter":
													case "Tweet": case "tweet":
														dataFormats["ticketId"] = ticketList.tweet_id;
														dataFormats["tweet_id"] = ticketList.tweet_id;
														break;
													case "Twitterdm": case "twitterdm":
													case "Tweetdm": case "tweetdm":
														dataFormats["ticketId"] = ticketList.tweetdm_id;
														dataFormats["tweetdm_id"] = ticketList.tweetdm_id;
														break;
													case "Messenger": case "messenger":
														dataFormats["ticketId"] = ticketList.messenger_id;
														dataFormats["messenger_id"] = ticketList.messenger_id;
														break;
													case "Whatsapp": case "whatsapp":
														dataFormats["ticketId"] = ticketList.whatsapp_id;
														dataFormats["whatsapp_id"] = ticketList.whatsapp_id;
														break;
													case "Webchat": case "webchat":
														dataFormats["ticketId"] = ticketList.webchat_id;
														dataFormats["webchat_id"] = ticketList.webchat_id;
														break;
													case "Youtube": case "youtube":
														dataFormats["ticketId"] = ticketList.youtube_id;
														dataFormats["youtube_id"] = ticketList.youtube_id;
														break;
													case "Instagram": case "instagram":
														dataFormats["ticketId"] = ticketList.instagram_id;
														dataFormats["instagram_id"] = ticketList.instagram_id;
														break;
													case "Instadm": case "instadm":
														dataFormats["ticketId"] = ticketList.instadm_id;
														dataFormats["instadm_id"] = ticketList.instadm_id;
														break;
													default:
														break;
												}
											}
										}
									}
								}
							});
							if (additionalData && additionalData.peopleData) {
								additionalData.peopleData.forEach(peopleData => {
									if (ticketList.people_id == peopleData.id) {
										dataFormats["peopleName"] = peopleData?.name;
										dataFormats["peopleAvatar"] = peopleData?.peopleAvatar;
										dataFormats["recipientId"] = peopleData?.unique_ref;
										if (peopleData && peopleData.labelInfo) {
											dataFormats["peopleLabelId"] = peopleData.labelInfo?.id;
											dataFormats["peopleLabelName"] = peopleData.labelInfo?.name;
											dataFormats["peopleLabelColor"] = peopleData.labelInfo?.color;
											dataFormats["peopleLabelColorText"] = peopleData.labelInfo?.color_text;
										}
									}
								});
							}
							var statusData = [];
							ticketData.ticketStatus.forEach(ticketStatus => {
								if (ticketStatus.model_id == ticketList.id) {
									statusData.push(ticketStatus);
								}
							});
							if (statusData && statusData.length > 0) {
								let lastStatusData = statusData.pop();
								dataFormats["statusName"] = lastStatusData.name;
								dataFormats["className"] = lastStatusData.model_type;
							}
							dataFormat.push(dataFormats);
						});
					}
					this.changeAllItems(dataFormat);
					this.concatlibrary(dataFormat);
				} else {
					this.alertService.webErrorShow(data);
				}
			},
				error =>{
				 console.log('Error ::' + error)}
			);
	}
	// getAllItems() {
	// 	if (this.userService.permissionMatch(['manage twitter'])) {
	// 		this.getRecentClosedTickets('tickets/tweetList');
	// 	}
	// 	if (this.userService.permissionMatch(['manage twitterdm'])) {
	// 		this.getRecentClosedTickets('tickets/tweetDmList');
	// 	}
	// 	if (this.userService.permissionMatch(['manage whatsapp'])) {
	// 		this.getRecentClosedTickets('tickets/whatsappList');
	// 	}
	// 	if (this.userService.permissionMatch(['manage webchat'])) {
	// 	}
	// 	if (this.userService.permissionMatch(['manage youtube'])) {
	// 	}
	// 	if (this.userService.permissionMatch(['manage instagram'])) {
	// 		this.getRecentClosedTickets('tickets/instagramlist');
	// 	}
	// 	if (this.userService.permissionMatch(['manage instadm'])) {
	// 		this.getRecentClosedTickets('tickets/instaDmList');
	// 	}
	// 	if (this.userService.permissionMatch(['manage messenger'])) {
	// 		this.getRecentClosedTickets('tickets/messengerList');
	// 	}
	// }
}
