import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	User, UserService,
	PeopleService, People, PeopleModel,
	LabelService, Label,
	ModalHasLabelService, ModalHasLabel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
	selector: 'people-label-form',
	templateUrl: './labelChangeForm.component.html',
	styleUrls: ['./labelChangeForm.component.scss']
})

export class LabelChangeFormComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Change Label';
	authUser: User;
	people: People;
	labelParam: any;
	label: Label;
	labels: Label[] = [];
	selectedLableId: number = null;
	action: string;
	labelForm: UntypedFormGroup;
	data: any;

	constructor(public userService: UserService,
		public peopleService: PeopleService,
		public labelService: LabelService,
		public matDialogRef: MatDialogRef<LabelChangeFormComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _formBuilder: UntypedFormBuilder) {
		this.labelService.libraries.pipe(untilDestroyed(this)).subscribe(data => this.labels = data);
		this.getInit(_data);
		this.labelService.params.pipe(untilDestroyed(this)).subscribe(data => this.labelParam = data);
		this.peopleService.item.pipe(untilDestroyed(this)).subscribe(data => {
			this.people = data;
			this.selectedLableId = this.people.labelId?this.people.labelId:0;
			this.labelForm = this.createForm();
		});
	}

	ngOnInit() {
		this.getDataInit();
	}

	/** On destroy */
	ngOnDestroy(): void { }

	getDataInit() {
		this.labelParam.paginate = 500;
		this.labelParam.page = 1;
		this.labelParam.client_id = this.people.client_id;
		this.labelService.getAllItems();
	}

	getInit(data) {
		// Set the defaults
		this.action = data.action;
		switch (data.action) {
			case 'new':
				this.dialogTitle = 'Change Lable';
				break;
			case 'edit':
				this.dialogTitle = 'Change Lable';
				break;
			default:
				break;
		}
	}

	onChangeLabel(label) {
		this.label = label;
		this.selectedLableId = label;
		this.labelForm.value.label_id = label;
		this.labelForm.value.resource_id = this.people.id;
		this.labelForm.value.resource_type = 'people';
	}

	/** Create form */
	createForm(): UntypedFormGroup {
		return this._formBuilder.group({
			label_id: [this.people.labelId?this.people.labelId:0, [Validators.required]],
			resource_id: [this.people.id, [Validators.required]],
			resource_type: ['people', [Validators.required]],
		});
	}

}
