import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Aicollection, AicollectionService, ClientService, EventService, PaymentsService } from 'src/app/@tji/_dbShare';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'app-payment-pending',
  templateUrl: './payment-pending.component.html',
  styleUrls: ['./payment-pending.component.scss'],
  providers: [OrderByPipe]
})
export class PaymentPendingComponent implements OnInit {

  paymentList: Aicollection[] = [];
  loader: boolean = true;
  subLoader: boolean = false;
  param: any;
  dataSource = new MatTableDataSource(this.paymentList);
  displayedColumns = ['date', 'name', 'payment id', 'amount', 'payment link', 'details', 'action'];


  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;
  dataLength: number = 0;
  eventData: any;
  statusData: any;

  @ViewChild('rightSideBar') rightSideBar: MatSidenav;
  filterType: string = 'pending';
  filterLoader: boolean = true;

  constructor(public aicollectionService: AicollectionService,
    private orderByPipe: OrderByPipe, public eventService: EventService,
    public clientService: ClientService, public paymentsService: PaymentsService) {
    this.eventService.eventData.subscribe(data => {
      this.eventData = data;
      this.checkEventData();
    });
    this.clientService.getAllUsers();
    this.aicollectionService.resetParams();
    this.aicollectionService.params.pipe(untilDestroyed(this)).subscribe(data => {
      this.param = data;
      this.param['type'] = 'pending';
    });

    this.aicollectionService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentList = this.orderByPipe.transform(data, '-_id');
      this.dataSource = new MatTableDataSource(this.paymentList);
      this.dataSource.sort = this.sort;
    });

    this.aicollectionService.loader.pipe(untilDestroyed(this)).subscribe(data => {
      this.loader = data;
    });

    this.aicollectionService.isStatusPayment.pipe(untilDestroyed(this)).subscribe(data => {
      if (data && data['payment_status']) {
        if (data['transaction_response']) {
          data['transaction_response'] = JSON.parse(data['transaction_response']);
        }
        this.statusData = data;
      } else {
        delete this.statusData;
      }
    });

    this.paymentsService.filterLoader.subscribe(data => this.filterLoader = data);
    this.aicollectionService.filterLoader.subscribe(data => this.filterLoader = data);
  }

  ngOnInit(): void {
    this.aicollectionService.getCurrentItems();
    this.dataInit();
  }
  ngOnDestroy(): void {
    this.aicollectionService.isLoader.next(true);
    this.aicollectionService.allItemsSource.next(null);
    this.aicollectionService.resetParams();
    this.aicollectionService.unSubscribe();
    this.aicollectionService.unSubscribeFilter();
  }
  setSubLoader() {
    this.subLoader = true;
    setTimeout(() => {
      this.subLoader = false;
    }, 5000);
  }
  dataInit() {
    this.aicollectionService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
  }
  checkEventData() {
    if (this.eventData) {
      let event: string = this.eventData.event;
      switch (event) {
        case 'paymentCreate':
          if (this.eventData.data && this.eventData.data.payment_status == 'pending') {
            this.aicollectionService.concatItem(this.eventData.data);
          }
          break;
        case 'paymentUpdate':
          if (this.eventData.data && this.eventData.data.payment_status == 'pending') {
            this.aicollectionService.changeAllItemsByItem(this.eventData.data);
          }
          break;
        default:
          break;
      }
    }
  }

  toggleRightSidebar() {
    this.rightSideBar.toggle();
  }


  viewStatus(payment: any = null) {
    if (payment && payment._id) {
      var data = {
        "trackId": payment._id
      }
      this.aicollectionService.getStatusPayment(data);
    }
  }

  closeStatus() {
    this.aicollectionService.isStatusPaymentData.next(null);
  }
}
