export class AddressModel {
    id: number;
    AltcontactNumber: number;
    addressDetails: any;
    addressName: string;
    addressType: string;
    area: string;
    block: string;
    client_id: number;
    client_site_id: number;
    contactName: string;
    contactNumber: number;
    created_at: any;
    created_by: number;
    governarate_id: number;
    is_default: boolean;
    people_id: number;
    street: string;
    _id: number;
    governarateAmount: number;
    governarateName: string;
    currency: any;
    is_delivarable: boolean;

    /**
     * Constructor
     *
     * @param address
     */
    constructor(address) {
        this.id = address.id || '';
        this.AltcontactNumber = address.AltcontactNumber || '';
        this.addressDetails = address.addressDetails || '';
        this.addressName = address.addressName || '';
        this.addressType = address.addressType || '';
        this.area = address.area || '';
        this.block = address.block || '';
        this.client_id = address.client_id || '';
        this.client_site_id = address.client_site_id || '';
        this.contactName = address.contactName || '';
        this.contactNumber = address.contactNumber || '';
        this.created_at = address.created_at || '';
        this.created_by = address.created_by || '';
        this.governarate_id = address.governarate_id || '';
        this.is_default = address.is_default || '';
        this.people_id = address.people_id || '';
        this.street = address.street || '';
        this._id = address._id || '';
        this.governarateAmount = address.governarateAmount || '';
        this.governarateName = address.governarateName || '';
        this.currency = address.currency || '';
        this.is_delivarable = address.is_delivable || '';
    }
}