import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatePipe } from '@angular/common';

import {
	Flag,
	FlagService,
	LabelService,
	PeopleService,
	ReservePeopleService,
	User, UserService,
	WhatsappTicketService,
} from 'src/app/@tji/_dbShare';

import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

@UntilDestroy()
@Component({
	selector: 'filter-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	providers: [DatePipe]
})

export class SidebarComponent {
	@Output() onClose = new EventEmitter();
	@Input() action: string;
	label: any;
	flags: Flag[] = [];
	reserveParam: any;
	peopleParam: any;
	selectedLabel: any;

	myControl = new FormControl<any>('');
	filteredOptions: Observable<string[]>;

	campaignOne: UntypedFormGroup;
	fromDate: Date;
	toDate: Date;
	today: Date = new Date();
	minDate: Date = new Date(2021, 0, 1);
	maxDate: Date = new Date();
	range = new FormGroup({
		start: new FormControl<Date | null>(null),
		end: new FormControl<Date | null>(null),
	});

	constructor(private _formBuilder: UntypedFormBuilder,
		private router: Router,
		public labelService: LabelService,
		public flagService: FlagService,
		public reservePeopleService: ReservePeopleService,
		public peopleService: PeopleService,
		public matDialog: MatDialog) {
		this.labelService.allLabelItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.label = data;
			if (this.label && this.label.length > 0) {
				this.getLabelItems();
			}
		});
		this.flagService.allFlag.pipe(untilDestroyed(this)).subscribe(data => this.flags = data);
		this.reservePeopleService.params.pipe(untilDestroyed(this)).subscribe(data => { this.reserveParam = data; });
		this.peopleService.params.pipe(untilDestroyed(this)).subscribe(data => { this.peopleParam = data; });
		// this.getDates();
	}

	ngOnInit() {
	}

	/** On destroy */
	ngOnDestroy(): void {
	}

	getLabelItems() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.label.slice();
			}),
		);

	}

	displayFn(user: any = null): string {
		return user && user.name ? user.name : '';
	}

	private _filter(name: string): User[] {
		const filterValue = name.toLowerCase();

		return this.label.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	// getDates() {
	// 	let date = new Date();
	// 	this.fromDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
	// 	this.toDate = this.today;
	// 	this.campaignOne = new UntypedFormGroup({
	// 		start: new UntypedFormControl(this.fromDate),
	// 		end: new UntypedFormControl(this.toDate)
	// 	});
	// }

	// onChangeFromDate(event) {
	// 	this.fromDate = event.target.value;
	// 	let maxToDate = this.today;
	// 	maxToDate = (maxToDate > this.today) ? this.today : maxToDate;
	// 	this.toDate = (this.toDate && this.toDate > maxToDate) ? maxToDate : this.toDate;
	// 	this.toDate = (this.toDate && this.toDate >= this.fromDate) ? this.toDate : maxToDate;
	// }

	onChangeToDate(event) {
		if (this.action == 'lead') {
		this.peopleService.isfilterLoader.next(true);
			this.peopleService.changeAllItems([]);
				this.peopleParam["from"] = this.range.value['start'];
				this.peopleParam["to"] = this.range.value['end'];
				this.peopleParam.current = 1,
				this.peopleParam.limit = 25,
				this.peopleService.getAllItems();
		}
		
		if (this.action == 'reserve') {
		this.reservePeopleService.isfilterLoader.next(true);
			this.reservePeopleService.changeAllItems([]);
				this.reserveParam["from"] = this.range.value['start'];
				this.reserveParam["to"] = this.range.value['end'];
				this.reserveParam.current = 1,
				this.reserveParam.limit = 25,
				this.reservePeopleService.getAllItems();
		}
		setTimeout(() => {
			// this.getTemplateReports();
		}, 100)
	}

	selectedLabelId(value: any) {
		if (this.action == 'lead') {
			this.peopleService.isfilterLoader.next(true);
			this.peopleService.changeAllItems([]);
			if (value == null) {
				this.peopleParam["label_id"] = '';
				delete this.peopleParam["label_id"];
				this.peopleParam.current = 1,
				this.peopleParam.limit = 25,
				this.selectedLabel = null;
				this.peopleService.getAllItems();
			} else {
				this.selectedLabel = value;
				this.peopleParam["label_id"] = value;
				this.peopleParam.current = 1,
				this.peopleParam.limit = 25,
				this.peopleService.getAllItems();
			}
		}
		
		if (this.action == 'reserve') {
			this.reservePeopleService.isfilterLoader.next(true);
			this.reservePeopleService.changeAllItems([]);
			if (value == null) {
				this.reserveParam["label_id"] = '';
				delete this.reserveParam["label_id"];
				this.reserveParam.current = 1,
				this.reserveParam.limit = 25,
				this.selectedLabel = null;
				this.reservePeopleService.getAllItems();
			} else {
				this.selectedLabel = value;
				this.reserveParam["label_id"] = value;
				this.reserveParam.current = 1,
				this.reserveParam.limit = 25,
				this.reservePeopleService.getAllItems();
			}
		}

	}

	reset() {
		if (this.action == 'lead') {
			this.peopleService.isfilterLoader.next(true);
			this.peopleParam["label_id"] = '';
			delete this.peopleParam["label_id"];
			delete this.peopleParam["from"];
			delete this.peopleParam["to"];
			this.peopleParam.current = 1,
			this.peopleParam.limit = 25,
			this.selectedLabel = null;
			this.peopleService.getAllItems();
		}
		if (this.action == 'reserve') {
			this.peopleService.isfilterLoader.next(true);
			this.reserveParam["label_id"] = '';
			delete this.reserveParam["label_id"];
			delete this.reserveParam["from"];
			delete this.reserveParam["to"];
			this.reserveParam.current = 1,
			this.reserveParam.limit = 25,
			this.selectedLabel = null;
			this.peopleService.getAllItems();
		}
	}
}
