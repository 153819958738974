import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { LeadsHistory } from './leadsHistory.interface';
import { LeadsHistoryModel } from './leadsHistory_model.model';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';
import * as moment from 'moment-timezone';

declare var require: any;
var slugify = require('slugify')

@Directive()
@Injectable({
	providedIn: 'root',
})
@UntilDestroy()

export class LeadsHistoryService implements Resolve<any> {
	url: string = 'peoplen/history';
	routeParams: any;
	clientId: number = null;
	clientSiteId: number = null;
	peopleId: number = null;
	defaultParams: any = {
		'limit': 500,
		'current': 1,
		'client_site_id': null,
		'people_id': null,
	};

	sortIdentity: any = {
		'name': 'name'
	};

	@Output() onChangeItem = new EventEmitter();
	@Output() onChangeAllItems = new EventEmitter();

	private historySource = new BehaviorSubject<LeadsHistory>(new LeadsHistoryModel({}));
	history = this.historySource.asObservable();

	private allItemsSource = new BehaviorSubject<Array<any>>([]);
	allItems = this.allItemsSource.asObservable();

	private librariesSource = new BehaviorSubject<Array<any>>([]);
	libraries = this.librariesSource.asObservable();

	private itemSource = new BehaviorSubject<any>({});
	item = this.itemSource.asObservable();

	private totalItemSource = new BehaviorSubject<number>(0);
	totalItem = this.totalItemSource.asObservable();

	private paramsSource = new BehaviorSubject<any>(this.defaultParams);
	params = this.paramsSource.asObservable();

	private ticketStatusData = new BehaviorSubject<any>(this.defaultParams);
	ticketStatus = this.ticketStatusData.asObservable();


	private _unsubscribeAll: Subject<any>;

	constructor(private globalService: GlobalService,
		private commonService: CommonService,
		private alertService: AlertService,
		private snackBar: MatSnackBar) {
		this._unsubscribeAll = new Subject();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;
		this.clientId = (this.routeParams && this.routeParams.clientId) ? this.routeParams.clientId : null;
		this.clientSiteId = (this.routeParams && this.routeParams.clientSiteId) ? this.routeParams.clientSiteId : null;
		this.peopleId = (this.routeParams && this.routeParams.peopleId) ? this.routeParams.peopleId : null;
		return new Promise((resolve, reject) => {
			Promise.all([
				this.resetParams(),
				this.getAllItems(),
				// this.getItem()
			])
				.then(() => {
					resolve(null);
				}, reject
				);
		});
	}

	unSubscribe() {
		// console.log('UnSubscribed LeadsHistoryService');
	}

	unSubscribeFilter() {
		// console.log('UnSubscribed Filters on LeadsHistoryService');
	}

	changeLibraries(libraries: LeadsHistory[]) {
		this.librariesSource.next(libraries);
	}

	changeHistory(history: any) {
		this.historySource.next(history);
	}

	changeAllItems(allItems: LeadsHistory[]) {
		this.allItemsSource.next(allItems);
	}

	changeItem(item: LeadsHistory) {
		this.itemSource.next(item);
	}

	changeTotalItem(total: number) {
		this.totalItemSource.next(total);
	}

	changeParams(parms: any) {
		this.paramsSource.next(parms);
	}

	paramsInit(params: any) {
		let newParams: any;
		let key: any;
		if (params !== null) {
			newParams = params;
		} else {
			this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
		}

		for (key in newParams) {
			if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
				delete newParams[key];
			}
		}
		this.clientId = (this.routeParams && this.routeParams.clientId) ? this.routeParams.clientId : null;
		this.clientSiteId = (this.routeParams && this.routeParams.clientSiteId) ? this.routeParams.clientSiteId : null;
		this.peopleId = (this.routeParams && this.routeParams.peopleId) ? this.routeParams.peopleId : null;
		return newParams;
	}

	concatlibrary(allItems: LeadsHistory[]) {
		var oldLists: Array<any> = [];
		this.libraries.subscribe(data => {
			oldLists = data;
		});
		if (oldLists && oldLists.length > 0) {
			oldLists = this.globalService.arrayMergeById(oldLists, allItems);
		} else {
			oldLists = allItems;
		}
		this.changeLibraries(oldLists);
	}

	resetParams() {
		const defaultParams: any = {
			'limit': 500,
			'current': 1,
			'client_site_id': null,
			'people_id': null,
		};
		this.changeParams(this.paramsInit(defaultParams));
	}

	getAllItems(params: any = null) {
		params = this.paramsInit(params);
		params.client_id = (this.routeParams && this.routeParams.clientId) ? this.routeParams.clientId : params.client_id;
		params.client_site_id = (this.routeParams && this.routeParams.clientSiteId) ? this.routeParams.clientSiteId : params.client_site_id;
		params.people_id = (this.routeParams && this.routeParams.peopleId) ? this.routeParams.peopleId : params.people_id;
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					this.changeHistory(data.data);
					this.changeAllItems(data.data);
					this.changeTotalItem(data.pagnitation.totalResult);
				}
			},
				error => console.log('Error ::' + error)
			);
	}

	concatAllItems(params: any = null) {
		params = this.paramsInit(params);
		params.client_id = (this.routeParams && this.routeParams.clientId) ? this.routeParams.clientId : params.client_id;
		params.client_site_id = (this.routeParams && this.routeParams.clientSiteId) ? this.routeParams.clientSiteId : params.client_site_id;
		params.people_id = (this.routeParams && this.routeParams.peopleId) ? this.routeParams.peopleId : params.people_id;
		this.commonService.storeItem(this.url, params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				let lists = [];
				this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
					lists = result
					if (data.data && data.data.rows && data.data.rows.length > 0) {
						lists = result['rows'].concat(data.data.rows);
					}

					if (data.data && data.data.CommentsInfo && data.data.CommentsInfo.length > 0) {
						lists = result['CommentsInfo'].concat(data.data.CommentsInfo);
					}

					if (data.data && data.data.FlagMapInfo && data.data.FlagMapInfo.length > 0) {
						lists = result['FlagMapInfo'].concat(data.data.FlagMapInfo);
					}
					
					if (data.pagnitation.last <= data.pagnitation.current) {
						params.page = data.pagnitation.last;
						this.changeParams(params);
					}
				});

				// this.changeHistory(lists);
				this.changeAllItems(lists);
				this.changeTotalItem(data.pagnitation.total);
			},
				error => console.log('Error ::' + error)
			);
	}

	/** Scroll Event */
	onScroll() {
		let newParams: any;
		this.params
			.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current += 1;
				this.changeParams(newParams);
				this.concatAllItems();
			});
	}

	/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.current = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	pageEvent(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = event.pageIndex + 1;
				newParams.paginate = event.pageSize;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	getSortName(input: string) {
		let sortName = 'name';
		sortName = (input) ? this.sortIdentity[input] : sortName;
		return sortName;
	}

	sortData(event) {
		let newParams: any;
		this.params
			.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.order = this.getSortName(event.active) + '|' + event.direction;
				this.changeParams(newParams);
				this.getAllItems();
			});
	}

	alert(type: string, message) {
		this.alertService.webShow(type, message);
		// let capitalType = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';
		// this.snackBar.openFromComponent(AlertComponent, {
		// 	panelClass: ['alert' + capitalType],
		// 	data: {
		// 		message: message,
		// 		type: type.toLowerCase(),
		// 	}
		// });
	}

	getTrackTicket(ticket) {
		if (ticket && ticket.id) {
			this.resetParams();
			var newParams;
			this.params
				.pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
				.subscribe(data => {
					newParams = data;
					newParams.client_id = ticket.client_id;
					newParams.client_site_id = (ticket.clientSiteId) ? (ticket.clientSiteId) : ticket.client_site_id;
					newParams.ticket_id = ticket.id;
					newParams.unique = true;
					this.changeParams(newParams);
					this.getTicketStatus(newParams);
				});
		}
	}

	getTicketStatus(params: any = null) {
		params = this.paramsInit(params);
		this.commonService.storeItem('tickets/track', params, true, 'optionOne')
			.pipe(untilDestroyed(this, 'unSubscribe'))
			.subscribe(data => {
				if (data.success) {
					var trackList = {}
					if (data.data) {
						var ticket = data.data.ticketInfo
						var ticketEventData = [];
						if (data.data && data.data.Conversation) {
							data.data.Conversation['created_at'] = moment(data.data.Conversation.created_at + '.000+0300').local();
						}
						trackList['conversation'] = data.data.Conversation;
						if (ticket) {
							ticket['created_at'] = moment(ticket.created_at + '.000+0300').local(),
								data.data.ticketEvent.forEach(ticketEvent => {
									if (ticket.people_id == ticketEvent.people_id) {
										data.data.allAgents.forEach(agent => {
											if (ticketEvent.agent_id == agent.id) {
												ticketEvent['name'] = agent.name
												ticketEvent['updated_at'] = moment(ticketEvent.updated_at + '.000+0300').local(),
													ticketEventData.push(ticketEvent);
											}
										});
									}

								});
						}
						if (data.data && data.data.allAgents) {
							data.data.allAgents.forEach(agent => {
								if (ticket.agent_id == agent.id) {
									ticket['name'] = agent.name
								}
							});
						}
						trackList['ticketEvent'] = ticketEventData;
						trackList['ticketInfo'] = ticket;
					}

					this.ticketStatusData.next(trackList);
				}
			},
				error => console.log('Error ::' + error)
			);
	}
}