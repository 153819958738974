import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import * as XLSX from 'xlsx';

import {
  CatalogProductService,
  User, UserService,
} from 'src/app/@tji/_dbShare';

@UntilDestroy()

@Component({
  selector: 'app-bulk-upload-product',
  templateUrl: './bulk-upload-product.component.html',
  styleUrls: ['./bulk-upload-product.component.scss']
})
export class BulkUploadProductComponent {
  dialogTitle: string = 'Bulk Import';
  error: string = '';
  excelJsonData: any = [];
  selectedExcelData: any = [];
  convertTemplateJson = [];
  isUpload: boolean = true;
  fileData: any;
  params: any;
  isUploadFile: boolean = true;
  isFileData: boolean = false;
  fileName: string = '';
  constructor(public userService: UserService,
    private snackBar: MatSnackBar,
    private catalogProductService: CatalogProductService,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<BulkUploadProductComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public location: Location) {
    // this.templateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
    // 	this.template = data;
    // });
    this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
  }

  ngOnInit() {
    // this.cd.detectChanges();
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.catalogProductService.unSubscribe();
  }

  readExcel(event) {
    this.fileData = event;
    this.fileName = event.target.files[0].name;
    var types = event.target.files[0].name.split(/\.(?=[^\.]+$)/);
    var typeDatas = types[1];
    if (typeDatas == 'xlsx' || typeDatas == 'xls' || typeDatas == 'csv') {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          initial['keys'] = sheet
          var excel = {
            keys: name,
            data: XLSX.utils.sheet_to_json(sheet, { defval: "" }) //XLSX.utils.sheet_to_json(workBook.Sheets[sheet], {defval:""}) //XLSX.utils.sheet_to_json(sheet)
          }
          this.excelJsonData.push(excel);
          return initial;
        }, {});
        this.isUpload = false;
        this.isUploadFile = false;
        this.isFileData = true;
      }
      reader.readAsBinaryString(file);
    } else {
      this.error = "upload file formate is worng. only accept xlsx, xls and csv"
    }
  }

  uploadProduct() {
    this.isUpload = true;
    var keepCurrency = true;
    var keepDescription = true;
    var keepImage = true;
    var keepName = true;
    var keepPrice = true;
    var keepId = false;

    if (this.excelJsonData && this.excelJsonData[0] && this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"].length > 0) {
      if (this.excelJsonData[0]["data"] && this.excelJsonData[0]["data"][0] && this.excelJsonData[0]["data"][0].id) {
        keepId = true;
      }

      this.excelJsonData[0]["data"].forEach(element => {
        element["catalog_id"] = this.params.catalog_id.toString();
        if (!element.currency && keepCurrency && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter currency Type');
          keepCurrency = false;
          return;
        }
        if (element.currency && !keepId) {
          element.currency = element.currency.toUpperCase();
        }
        if (!element.description && keepDescription && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter description');
          keepDescription = false;
          return;
        }
        if (!element.image_url && keepImage && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter image_url');
          keepImage = false;
          return;
        }
        if (!element.name && keepName && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter name');
          keepName = false;
          return;
        }
        if (!element.price && keepPrice && !keepId) {
          this.alertService.webShow('Danger', 'Please Enter price');
          keepPrice = false;
          return;
        }
        if (!element.ordering_index) {
          element.ordering_index = element.ordering_index?element.ordering_index:'0';
        }
        if (element.availability && !keepId) {
          element.availability = element.availability.toString();
        }
        if (element.id) {
          element.id = element.id.toString();
        }
        if (element.price) {
          var priceData = element.price.toString().split('.');
          if (priceData[0] && priceData[0].length < 1) {
            priceData[0] = 0;
          }
          if (priceData[1] && priceData[1].length == 1) {
            priceData = priceData[0] + '.' + priceData[1] + '00'
          } else if (priceData[1] && priceData[1].length == 2) {
            priceData = priceData[0] + '.' + priceData[1] + '0'
          } else if (priceData[1] && priceData[1].length == 3) {
            var decimelsData = priceData[1].split('');
            if (decimelsData[2] != 0) {
              decimelsData[2] = 0
            }
            priceData = priceData[0] + '.' + decimelsData[0] + decimelsData[1] + decimelsData[2];
          } else {
            priceData = priceData[0] + '.' + '000'
          }
          element.price = priceData;
          // element.price.toFixed(3);
        }
      });
      var result = {
        data: this.excelJsonData[0]["data"]
      }
      if (keepCurrency && keepDescription && keepImage && keepImage && keepName && keepPrice && !keepId) {
        this.matDialogRef.close(result);
        this.isUpload = false;
        this.excelJsonData = [];
        delete this.fileData;
        delete this.fileName;
        this.isUploadFile = true;
        this.isFileData = false;
      } else if (keepId) {
        this.matDialogRef.close(result);
        this.isUpload = false;
        this.excelJsonData = [];
        delete this.fileData;
        delete this.fileName;
        this.isUploadFile = true;
        this.isFileData = false;
      } else {
        this.deleteFile();
      }


    } else {
      this.alertService.webShow("danger", 'no records found in uploaded excel sheet');
    }
  }
  deleteFile() {
    this.isUpload = false;
    this.excelJsonData = [];
    delete this.fileData;
    delete this.fileName;
    this.isUploadFile = true;
    this.isFileData = false;
  }

  sampleFile() {
    window.open('/assets/sample-files/sample-facebook-product.csv', '_blank');
  }
}
