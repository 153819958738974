import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import * as Rx from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  UserService, CatalogProductService, catalogProduct, AlertService, MediaService, CommonService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
  selector: 'sidebar-product',
  templateUrl: './sidebar-product.component.html',
  styleUrls: ['./sidebar-product.component.scss']
})
export class SidebarProductComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  productForm: UntypedFormGroup;
  productTitle: string = 'New Product';
  productItem: catalogProduct;
  currencyList: any;
  params: any;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  isImageUpload: boolean = false;
  isEdite: boolean = false;
  stockStatus = [
    {
      name: "out of stock",
      id: "0",
    },
    {
      name: "in stock",
      id: "1",
    }
  ]

  private _unsubscribeAll: Subject<any>;
  constructor(public catalogProductService: CatalogProductService,
    public matDialog: MatDialog,
    public userService: UserService,
    public mediaService: MediaService,
    private commonService: CommonService,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder) {
    this._unsubscribeAll = new Subject();

    this.catalogProductService.allICurrencyItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.currencyList = data;
      }
    });
    this.catalogProductService.params.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.params = data;
      }
    });
    this.catalogProductService.item.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.productItem = data;
        if (data && data._id) {
          this.productTitle = 'Edit Product';
          this.productPrice = this.productItem?.price;
          this.imageUrl = this.productItem?.image_url;
          this.isEdite = true;
        } else {
          this.productTitle = 'New Product';
        }
      }
      this.productForm = this.createForm();
    });

    this.catalogProductService.addItems.pipe(untilDestroyed(this)).subscribe(data => {
      if (data == true) {
        this.productForm.reset();
        delete this.imageUrl;
        delete this.productPrice;
        // this.productItem
        this.catalogProductService.isAddItems.next(false);
        this.productForm.controls.auto_generate_gtn.setValue(false);
      }
    });

  }

  ngOnInit(): void {
    // this.productForm.reset();
    delete this.imageUrl;
    delete this.productPrice;
  }

  /** On destroy */
  ngOnDestroy(): void {
    this.catalogProductService.itemSource.next(null);
    this.catalogProductService.unSubscribe();
  }
  unSubscribe() {
    // console.log('UnSubscribed MediaService');
  }
  createForm(): UntypedFormGroup {
    if (this.productItem && this.productItem.auto_generate_gtn) {
      this.autoGenerateGtn = this.productItem.auto_generate_gtn ? true : false;
    } else {
      this.autoGenerateGtn = false;
      this.checkGenerateGtn(this.autoGenerateGtn);
    }
    return this._formBuilder.group({
      name: [this.productItem?.name || null, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]],
      currency: [this.productItem?.currency || null, [Validators.required]],
      price: [this.productItem?.price || null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      description: [this.productItem?.description || null, [Validators.required, Validators.minLength(4), Validators.maxLength(9999)]],
      url: [this.productItem?.url || null, [Validators.required, Validators.pattern(this.urlRegex)]],
      gtin: [this.productItem?.gtin || null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1), Validators.maxLength(20)]],
      image_url: [this.productItem?.image_url || null, [Validators.required]],
      availability: [this.productItem?.availability],
      auto_generate_gtn: [false],
      // ordering_index: [this.productItem?.ordering_index || null]
    });
  }


  // catalog_id
  // image_url
  autoGenerateGtn: boolean = false;
  productPrice: any;
  submit() {
    if (this.productForm.invalid) {
      return
    }

    if (!this.productForm.value.image_url) {
      this.alertService.webShow('Danger', 'Select Product Image');
    }
    if (this.productForm.value.price && this.productForm.value.price.length > 0) {
      var priceData = this.productForm.value.price.split('.');
      if (priceData[0] && priceData[0].length < 1) {
        priceData[0] = 0;
      }
      if (priceData[1] && priceData[1].length == 1) {
        priceData = priceData[0] + '.' + priceData[1] + '00'
      } else if (priceData[1] && priceData[1].length == 2) {
        priceData = priceData[0] + '.' + priceData[1] + '0'
      } else if (priceData[1] && priceData[1].length == 3) {
        var decimelsData = priceData[1].split('');
        if (decimelsData[2] != 0) {
          decimelsData[2] = 0
        }
        priceData = priceData[0] + '.' + decimelsData[0] + decimelsData[1] + decimelsData[2];
      } else {
        priceData = priceData[0] + '.' + '000'
      }
      this.productPrice = priceData;
    }

    if (this.productItem && this.productItem._id) {
      var update = {
        "catalog_id": this.params.catalog_id.toString(),
        "name": this.productForm.value.name,
        "currency": this.productForm.value.currency,
        "price": this.productPrice,
        "description": this.productForm.value.description,
        "image_url": this.productForm.value.image_url,
        "url": this.productForm.value.url,
        "gtin": parseInt(this.productForm.value.gtin),
        "availability": this.productForm.value.availability,
        "id": this.productItem.id,
        // "ordering_index": this.productForm.value.ordering_index?this.productForm.value.ordering_index:'0'
      }
      this.catalogProductService.store('fbProduct/update', update);
      // this.onClose.emit();
      this.onClose.emit(false)
      delete this.productItem;
      delete this.imageUrl;
      this.catalogProductService.itemSource.next(null);
      this.isEdite = false;
      this.productTitle = 'New Product';
    } else {
      var data = {
        "catalog_id": this.params.catalog_id.toString(),
        "name": this.productForm.value.name,
        "currency": this.productForm.value.currency,
        "price": this.productPrice,
        "description": this.productForm.value.description,
        "image_url": this.productForm.value.image_url,
        "url": this.productForm.value.url,
        "gtin": parseInt(this.productForm.value.gtin),
        "auto_generate_gtn": this.productForm.value.auto_generate_gtn ? '1' : '0',
        // "ordering_index": this.productForm.value.ordering_index?this.productForm.value.ordering_index:'0'
      }
      this.catalogProductService.store('fbProduct/manage', [data]);
      this.onClose.emit(false)
      delete this.productItem;
      delete this.imageUrl;
      this.catalogProductService.itemSource.next(null);
      this.isEdite = false;
      this.productTitle = 'New Product';
    }
  }

  autGenerateGtn(e) {
    if (e) {
      this.autoGenerateGtn = e.checked;
    }
    if (this.autoGenerateGtn) {
      this.productForm.get('gtin').clearValidators();
      this.productForm.get('gtin').updateValueAndValidity();
    } else {
      this.productForm.controls["gtin"].setValidators(Validators.required);
      this.productForm.controls["gtin"].updateValueAndValidity();
    }
  }

  checkGenerateGtn(e) {
    setTimeout(() => {
      if (e) {
        this.productForm.get('gtin').clearValidators();
        this.productForm.get('gtin').updateValueAndValidity();
      } else {
        this.productForm.controls["gtin"].setValidators(Validators.required);
        this.productForm.controls["gtin"].updateValueAndValidity();
      }
    }, 300);

  }

  resetForm() {
    this.productForm.reset();
    // this.productForm.get('business_id').enable();
  }

  imageHandlerData(event) {
    this.isImageUpload = true;
    if (event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/png") {
      let reader = new FileReader();
      let fileData = null;
      var filesize = event.target.files[0].size;
      const fileSizeInKB = Math.round(filesize / 1024);
      if (fileSizeInKB <= 8192) {
        if (event.target.files && event.target.files.length) {
          const file = event.target.files[0];
          reader.readAsDataURL(file);

          reader.onload = (e: any) => {
            var images = {
              asset: reader.result,
              asset_name: file.name.replace(/ /g, "_"),
              asset_type: 'image'
            };
            this.addStore(images);
          };
        }
      } else {
        this.isImageUpload = false;
        this.alertService.webShow('Danger', 'Please Select below 8MB');
      }
    } else {
      this.isImageUpload = false;
      this.alertService.webShow('Danger', 'Image Format Not Supported, Please Select JPEG, JPG and PNG');
    }
  }

  imageUrl: any = null;
  addStore(data: any) {
    this.commonService.storeItem('media/add', data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.isImageUpload = false;
          this.productForm.controls.image_url.setValue(res.data.url);
          this.imageUrl = res.data.url;
          this.alertService.webShow('Success', 'updated Successfully !!!');
        } else {
          this.isImageUpload = false;
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          this.isImageUpload = false;
          console.log('Error ::' + error);
          this.alertService.webShow('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  productAmount: any;
  /** number only accept */
  numberOnly(event: any): boolean {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      if (this.productAmount) {
        var len = this.productAmount.length;
        var index = this.productAmount.indexOf('.');
        var priceData = this.productAmount.split('.');
        if (priceData.length > 4) {
          return false;
        } else {
          if (index > 0 && charCode == 46) {
            return false;
          }
          if (index > 0) {
            var CharAfterdot = (len + 1) - index;
            if (CharAfterdot > 4) {
              return false;
            }
          }
        }

      }
    }

    return true;
  }

   /** number only accept */
  orderIndex(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  resetImage() {
    this.productForm.controls.image_url.setValue('');
    delete this.imageUrl;
  }

  closeWindow(){
    delete this.productItem;
    delete this.imageUrl;
    this.catalogProductService.itemSource.next(null);
    this.isEdite = false;
    this.productTitle = 'New Product';
  }
}

