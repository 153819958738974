import { Component, ElementRef, OnDestroy, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, pipe } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';

import { IntractiveMessageViewComponent } from 'src/app/appAccount/intractiveMessage/intractiveMessage-view/intractiveMessage-view.component';
import { MediaViewComponent } from 'src/app/library/dialog/mediaView/mediaView.component';

import { User, UserService, ChatService, CommonService, ProductCartService } from 'src/app/@tji/_dbShare';
import { tick } from '@angular/core/testing';
import { HttpClient } from '@angular/common/http';

@UntilDestroy()
@Component({
	selector: 'utils-chat-item',
	templateUrl: './chatItem.component.html',
	styleUrls: ['./chatItem.component.scss'],
})

export class ChatItemComponent implements OnInit, OnDestroy {
	@Input() chat: any;
	@Input() ticket: any = null;
	@Input() showReply: boolean = true;
	@Input() position: string;
	@Output() onRightSideView = new EventEmitter();
	base_image: string = this.commonService.logo_image;
	logoAvatar: string = this.commonService.logoAvatar;
	cartId: any;
	isCartPanel: boolean = false;
	constructor(public chatService: ChatService,
		public userService: UserService,
		public commonService: CommonService,
		public matDialog: MatDialog,
		public productCartService: ProductCartService,
		private http: HttpClient) {

		this.productCartService.cartDetails.subscribe(data => {
			if (data) {
				this.cartId = data['id'];
			}
		});

		this.productCartService.cartPanel.subscribe(data => {
			this.isCartPanel = data;
		});
	}

	ngOnInit() { }

	/** On destroy */
	ngOnDestroy(): void { }

	ngAfterViewInit() { }

	getRightAvatar(chat): string {
		if (chat && chat.created_by != 1 && chat.createdByAvatar &&
			chat.createdByAvatar != null && chat.createdByAvatar != '') {
			return chat.createdByAvatar;
		}
		if (chat && chat.created_by == 1 && chat.clientAvatar &&
			chat.clientAvatar != null && chat.clientAvatar != '') {
			return chat.clientAvatar;
		}
		return this.logoAvatar;
	}

	getLeftAvatar(chat): string {
		if (chat && chat.peopleAvatar &&
			chat.peopleAvatar != null && chat.peopleAvatar != '') {
			return chat.peopleAvatar;
		}
		if (this.ticket && this.ticket.id && this.ticket.peopleAvatar &&
			this.ticket.peopleAvatar != null && this.ticket.peopleAvatar != '') {
			return this.ticket.peopleAvatar;
		}
		return this.base_image;
	}

	getPeopleName(chat): string {
		if (chat && chat.peopleName && (chat.peopleName != '' || chat.peopleName != null)) {
			return chat.peopleName;
		}
		if (this.ticket && this.ticket.peopleName && (this.ticket.peopleName != '' || this.ticket.peopleName != null)) {
			return this.ticket.peopleName;
		}
		if (this.ticket && this.ticket.recipientId) {
			return this.ticket.recipientId
		}
		return 'Customer';
	}

	getChatmeClass(): string {
		return (this.position && this.position === 'right') ? 'chat-me' : '';
	}

	getDmClass(chat): string {
		if (chat && chat.table &&
			(chat.siteName === 'Twitterdm' || chat.siteName === 'twitterdm' ||
				chat.siteName === 'messenger' || chat.siteName === 'Messenger' ||
				chat.siteName === 'instadm' || chat.siteName === 'Instadm' || chat.siteName === 'InstaDm' ||
				chat.siteName === 'whatsapp' || chat.siteName === 'Whatsapp')) {
			let sitName: string = chat.siteName.toLowerCase();
			return (this.position && this.position === 'right') ? 'dmRight-' + sitName : 'dmLeft-' + sitName;
		}
		return '';
	}

	getChatReplyMedia(chat): string {
		if (chat && chat.reply_id && chat.reply && chat.reply.media) {
			return chat.reply.media;
		}
		return 'text';
	}

	canReply(chat): boolean {
		if (((this.showReply && chat && chat.siteName) && (chat.siteName === 'Whatsapp' || chat.siteName === 'whatsapp')) || ((this.showReply && chat && chat.siteName) && (chat.siteName === 'Instagram' || chat.siteName === 'instagram'))) {
			return true;
		}
		return false;
	}

	isUnSupport(chat) {
		if (chat.media !== 'text' && chat.media !== 'video' && chat.media !== 'audio' &&
			chat.media !== 'link' && chat.media !== 'document' && chat.media !== 'image' &&
			chat.media !== 'button' && chat.media !== 'location' && chat.media !== 'voice' &&
			chat.media !== 'file' && chat.media !== 'fallback' && chat.media !== 'interactive' &&
			chat.media !== 'template' &&
			chat.media !== 'order' && chat.media !== 'contacts' && chat.media !== 'carousel_album') {
			return true;
		}
		return false;
	}

	previewMedia(chat: any) {
		let mediaData: any = null;
		if (chat.reply_id && chat.reply.id) {
			if (!chat.reply.asset_url && chat.reply.media == 'text') {
				mediaData = {
					'url': chat.asset_url,
					'type': chat.reply.media,
					'tableName': 'chatData',
				};
			} else if (chat.media == 'text' && chat.reply.media == 'image' || chat.reply.media == 'video' || chat.reply.media == 'voice' || chat.reply.media == 'audio' || chat.reply.media == 'document' || chat.reply.media == 'contacts' || chat.reply.media == 'location' || chat.reply.media == 'carousel_album') {
				mediaData = {
					'url': chat.reply.asset_url,
					'type': chat.media,
					'tableName': 'chatData',
				}
			} else if (chat.media == 'image' && chat.reply.media == 'image' || chat.reply.media == 'video' || chat.reply.media == 'voice' || chat.reply.media == 'audio' || chat.reply.media == 'document' || chat.reply.media == 'contacts' || chat.reply.media == 'location' || chat.reply.media == 'carousel_album') {
				mediaData = {
					'url': chat.asset_url,
					'type': chat.media,
					'tableName': 'chatData',
				}
			} else if (chat.reply.media == 'image' && chat.media == 'video' || chat.media == 'voice' || chat.media == '"audio"' || chat.media == 'document' || chat.media == 'contacts' || chat.media == 'location' || chat.media == 'carousel_album') {
				mediaData = {
					'url': '',
					'type': chat.reply.media,
					'tableName': 'chatData',
				};
			} else {
				mediaData = {
					'url': chat.asset_url,
					'type': chat.reply.media,
					'tableName': 'chatData',
				};
			}
		} else {
			mediaData = {
				'url': chat.asset_url,
				'type': chat.media,
				'tableName': 'chatData',
			};
		}
		if (mediaData && mediaData.url) {
			let dialogRef = this.matDialog.open(MediaViewComponent, {
				data: {
					item: mediaData,
				}
			});
		}
	}

	replyToChat(chat) {
		if (this.ticket && this.ticket.id && !this.ticket.is_closed) {
			this.chatService.changeReplyChat(chat);
		}
	}
	cancelReply() { this.chatService.changeReplyChat(null); }

	getTotlePrice(data: any = null): string {
		var totalPrice = 0;
		if (data) {
			data.forEach(element => {
				if (element.quantity > 1) {
					totalPrice = totalPrice + element.quantity * element.item_price;
				} else {
					totalPrice = totalPrice + element.item_price;
				}
			});
			return totalPrice.toString();
		} else {
			return totalPrice.toString();
		}
	}
	getTotleQty(data: any = null): string {
		var totalQty = 0;
		if (data) {
			data.forEach(element => {
				if (element.quantity) {
					totalQty = totalQty + element.quantity;
				}
			});
			return totalQty.toString();
		} else {
			return totalQty.toString();
		}
	}
	toggleCartDetails(item: any) {
		if (this.cartId != item.id && !this.productCartService.cartClientPanel) {
			this.productCartService.cartDataSource.next(true);
			this.productCartService.cartData.next(item);
		} else if (this.productCartService.cartClientPanel) {
			if (this.cartId != item.id) {
				this.productCartService.cartDataSource.next(true);
				this.productCartService.cartData.next(item);
				this.onRightSideView.emit('cartDetails')
			} else {
				this.productCartService.cartData.next(item);
				if (this.isCartPanel) {
					this.productCartService.cartDataSource.next(false);
				} else {
					this.productCartService.cartDataSource.next(true);
				}
			}
		} else {
			if (this.isCartPanel) {
				this.productCartService.cartDataSource.next(false);
			} else {
				this.productCartService.cartDataSource.next(true);
			}
		}
	}

	getChatContacts(chat) {
		if (chat && chat.media == 'contacts') {
			var data = JSON.parse(chat.text);
			chat = data;
			return chat;
		}
		return 'text';
	}
	errorHandlerImage(event, chat) {
		chat.asset_url = "assets/images/broken_img.png";
		chat.isAssetData = true;
	}

	isValidHttpUrl(chat) {
		try {
			const newUrl = new URL(chat.asset_url);
			return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
		} catch (err) {
			chat.isAssetData = true;
			return false;
		}
	}

	downloadAssets(chat) {
		if (chat && chat.id && chat.asset_url) {
			var redownload = {
				// "ticket_id": chat.ticketId,
				// "sitename": chat.siteName,
				"chatId": chat.id,
			}

			if (this.ticket && this.ticket.clientSiteId) {
				redownload["clientSiteId"] = this.ticket.clientSiteId;
			}
			if (this.ticket && this.ticket.client_site_id) {
				redownload["clientSiteId"] = this.ticket.client_site_id;
			}

			this.chatService.reDownload(chat.siteName, redownload);
		}
	}
	getUrl(text) {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function (url) {
			return '<a class="url" href="' + url + '" target="_blank">' + url + '</a>';
		})
	}
}
