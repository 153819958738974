import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderByPipe } from 'ngx-pipes';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { CloseDialogComponent } from 'src/app/library/dialog/close/close.component';
import { CommentDialogComponent } from 'src/app/library/dialog/comment/comment.component';
import { ReassignFormComponent } from './../reassignForm/reassignForm.component';
import { CommentExportComponent } from './../commentExport/commentExport.component';


import { ConversationInstagramHistoryComponent } from 'src/app/appAccount/openConversation/conversationInstagramHistory/conversationInstagramHistory.component';


import {
	EventService,
	User, UserService, CommonService,
	InstagramService, Instagram,
	InstagramTicketService, InstagramTicket,
	TicketActivityService, TicketActivity,
	ChatService, FeedService, LeadsHistoryService, LeadsHistory,
	CommentService, Comment, AgentService, ModalHasFlagService, ProductCartService
} from 'src/app/@tji/_dbShare';
import * as moment from 'moment-timezone';

@UntilDestroy()
@Component({
	selector: 'instagram-ticket-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	providers: [OrderByPipe]
})

export class ListComponent implements OnInit, OnDestroy {
	@Input() viewMode: string = "open";
	routeParam: string = null;
	loader: boolean = true;
	subLoader: boolean = false;
	filterLoader: boolean = true;
	baseImage: string = this.commonService.logo_image;
	authUser: User;
	ticketsAll: InstagramTicket[] = [];
	tickets: InstagramTicket[] = [];
	param: any;
	instagramParam: any;
	dataSource = new MatTableDataSource(this.tickets);
	displayedColumns = ['date', 'avatar', 'content', 'agentavatar', 'agent', 'priority', 'action'];
	dataLength: number = 0;
	leadsHistoryParam: any;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	eventData: any;

	constructor(public userService: UserService,
		public eventService: EventService,
		public feedService: FeedService,
		public instagramService: InstagramService,
		public instagramTicketService: InstagramTicketService,
		public ticketActivityService: TicketActivityService,
		public leadsHistoryService: LeadsHistoryService,
		public commentService: CommentService,
		public commonService: CommonService,
		public chatService: ChatService,
		private router: Router,
		private route: ActivatedRoute,
		public matDialog: MatDialog,
		public agentService: AgentService,
		public modalHasFlagService: ModalHasFlagService,
		public productCartService: ProductCartService,
		private orderByPipe: OrderByPipe) {
		this.instagramTicketService.isResetHistory = false;
		this.instagramTicketService.getAllItems();
		this.instagramService.resetParams();
		this.userService.user.subscribe(data => this.authUser = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.instagramTicketService.filterLoader.subscribe(data => this.filterLoader = data);
		this.leadsHistoryService.params.pipe(untilDestroyed(this)).subscribe(data => this.leadsHistoryParam = data);
		this.instagramService.params.subscribe(data => this.instagramParam = data);
		this.instagramTicketService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			if (data !== this.ticketsAll) { this.subLoader = false; }
			this.ticketsAll = data;
			if (this.viewMode && this.viewMode === 'closed') {
				this.tickets = this.orderByPipe.transform(data, '-id').filter(x => {
					x.created_at = x.updated_at;
					return (x.is_closed) ? true : false;
				});
				this.dataSource = new MatTableDataSource(this.tickets);
				this.dataSource.sort = this.sort;
			} else {
				this.tickets = this.orderByPipe.transform(data, '-id').filter(x => {
					return (!x.is_closed) ? true : false;
				});
				this.dataSource = new MatTableDataSource(this.tickets);
				this.dataSource.sort = this.sort;
			}
		});
	}

	ngOnInit() {
		this.getRouteParams();
		this.doLoader();
		this.dataInit();
	}

	/** On destroy */
	ngOnDestroy(): void {
		if (!this.instagramTicketService.isResetHistory) {
			this.instagramTicketService.resetParams();
		}
		this.instagramTicketService.unSubscribeFilter();
		this.instagramTicketService.changeAllItems([]);
	}

	viewTicket() {
		this.instagramTicketService.isResetHistory = true;
		this.productCartService.cartDataSource.next(null);
	}

	getRouteParams() {
		this.routeParam = this.route.snapshot.paramMap.get('param');
	}

	doLoader() {
		this.loader = true;
		setTimeout(() => {
			this.loader = false;
		}, 5000);
	}

	setSubLoader() {
		this.subLoader = true;
		setTimeout(() => {
			this.subLoader = false;
		}, 5000);
	}

	dataInit() {
		this.instagramTicketService.params.pipe(untilDestroyed(this)).subscribe(data => {
			this.param = data;
		});
		this.instagramTicketService.totalItem.pipe(untilDestroyed(this)).subscribe(data => this.dataLength = data);
	}

	/** Add / Update Tag */
	reassignTicket(ticket = null): void {
		this.instagramTicketService.changeItem((ticket) ? ticket : null);
		let dialogRef = this.matDialog.open(ReassignFormComponent, {
			width: "600px",
			data: {
				action: 'edit',
				item: ticket,
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				this.agentService.allActiveSource.next([]);
				if (!response) { return; }
				const actionType: string = response[0];
				const formData: UntypedFormGroup = response[1];
				const id: number = (response[2]) ? response[2] : null;
				let updateData: any = formData.value;
				let newAgentId: any = updateData.agent_id;
				if (newAgentId && newAgentId === ticket.agent_id) {
					updateData.status_mode = null;
				}
				switch (actionType) {
					case 'update':
						this.instagramTicketService.update("tickets/assign", updateData);
						break;
				}
			});
	}

	/** Re Open Ticket */
	reOpenTicket(ticket = null): void {
		this.instagramTicketService.changeItem((ticket) ? ticket : null);
		let dialogRef = this.matDialog.open(ConfirmDialogComponent, {
			disableClose: false,
			width: '600',
			data: {
				type: 'info',
				title: 'Re-Open Ticket',
				message: 'Are you sure you want to Re-open this Ticket and Assigned to you?',
			}
		});

		dialogRef.afterClosed()
			.subscribe(response => {
				if (!response || !response.data) { return; }
				const actionType: string = 'update';
				let updateData: any = {};
				updateData.client_site_id = ticket.clientSiteId;
				updateData.ticket_id = ticket.id;
				updateData.agent_id = ticket.agent_id;
				const id: number = ticket.id;
				let newAgentId: any = updateData.agent_id;
				switch (actionType) {
					case 'update':
						this.instagramTicketService.update("tickets/assign", updateData);
						break;
				}
			});
			
	}

	/** Close Tickets */
	closeTicket(ticket): void {
		let closeDialogRef = this.matDialog.open(CloseDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'close',
				title: 'Close Ticket',
				resource: ticket,
				clientSiteId: ticket.clientSiteId,
				message: 'Are you sure you want to Close this Ticket?',
			}
		});
		closeDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				result.formValue.is_closed = true;
				result.formValue.instagram_ticket_id = result.formValue.id;
				result.formValue.status_mode = 'Closed';
				var data = {
					"client_site_id": result.formValue.clientSiteId,
					"ticket_id": result.formValue.id,
				}
				var user: any = JSON.parse(localStorage.getItem('tji_user'));
				let comments: any = {
					"client_site_id": result.formValue.clientSiteId,
					"comment_id": null,
					"ticket_id": result.formValue.id,
					"user_id": (user && user.id) ? user.id : null,
					"description": result.formValue.description,
					"title": result.formValue.title
				}
				if (result.formValue.description) {
					this.commentService.closeTicketComment('comments/addcomment', comments);
				}
				if (result.formValue.set_flags && result.formValue.set_flags.length > 0) {
					result.formValue.set_flags.forEach(flag => {
						let modalHasFlag: any = {
							"ticket_id": result.formValue.id,
							"flag_id": flag,
							"client_site_id": result.formValue.clientSiteId,
						};
						this.modalHasFlagService.closeTicketFlag('flag/addflagMap', modalHasFlag);
					});
				}
				this.instagramTicketService.close(ticket.id, data, 'tickets/close');
				this.instagramTicketService.removelibraryById(ticket.id);
			}
		});
	}

	/** Close Tickets */
	commentTicket(ticket): void {
		let commentDialogRef = this.matDialog.open(CommentDialogComponent, {
			disableClose: false,
			width: '400px',
			data: {
				type: 'new',
				title: 'Add New Comment',
				resource: ticket,
				message: 'Enter Your comments here...',
			}
		});
		commentDialogRef.afterClosed().subscribe(result => {
			if (result && result.data) {
				this.commentService.replayComment('comments/addcomment', result.formValue);
			}
		});
	}

	statusChange(ticket: InstagramTicket, status: string) {
		switch (status) {
			case "Re-Assigned":
				this.reassignTicket(ticket);
				break;
			case "Closed":
				this.closeTicket(ticket);
				break;
			case "Important":
				let updateData: any = { status_mode: 'Important' };
				this.instagramTicketService.update(ticket.id, updateData);
				break;
			default:
				break;
		}
	}

	getTicketActivities(ticket: InstagramTicket) {
		this.instagramTicketService.isResetHistory = true;
		this.ticketActivityService.changeAllItems([]);
		this.instagramTicketService.changeItem(ticket);
		this.ticketActivityService.getTicketActivities(ticket);
	}

	exportInstagramComments(ticket): void {
		this.instagramTicketService.changeItem(ticket);
		this.getInstagramsByMediaId(ticket);
		let mediaId = (ticket && ticket.mediaId) ? ticket.mediaId : null;
		if (mediaId) {
			let param = {
				'mediaId': mediaId,
				'client_id': (ticket && ticket.client_id) ? ticket.client_id : null,
				'client_site_id': (ticket && ticket.client_site_id) ? ticket.client_site_id : null,
				'file_type': 'xls',
			};
			let url: string = this.instagramService.getExportUrl(param);
			window.open(url, '_self');
		}
		// let dialogRef = this.matDialog.open(CommentExportComponent, {
		// 	width: "650px",
		// 	data: {
		// 		action: 'show',
		// 		item: ticket,
		// 		mediaId: mediaId,
		// 	}
		// });
		// dialogRef.afterClosed().subscribe(response => { });
	}

	getInstagramsByMediaId(ticket: InstagramTicket) {
		if (ticket && ticket.mediaId) {
			this.instagramParam.mediaId = ticket.mediaId;
			this.instagramParam.is_itrs = null;
			this.instagramParam.all_history = 'true';
			this.instagramParam.order = 'created_at|desc';
			this.instagramParam.paginate = 10;
			this.instagramParam.page = 1;
			this.instagramParam.only = "id,text,instagram_id,reply_id,status,from,to,mediaId,commentId,media_url,user_image,uName,created_by,updated_by,created_at,updated_at";
			this.instagramService.getAllItems(this.instagramParam);
		}
	}

	checkEventData() {
		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instagram' && this.userService.isAuthUserIsAgent()) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, "aticketIsAgent");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, "aticketIsAgent");
					// this.whatsappTicketService.updateAllItemsByItem(this.eventData.data)
					break;
				case 'ticketassigned':
					this.instagramTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.instagramTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketremoved':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'aticketremoved':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketclosed':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketabandon':
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					break;
				default:
					break;
			}
		}

		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instagram' && this.userService.isAuthUserIsSupervisor()) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, "aticketIsAgent");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, "aticketIsAgent");
					// this.whatsappTicketService.updateAllItemsByItem(this.eventData.data)
					break;
				case 'ticketassigned':
					this.instagramTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.instagramTicketService.concatItem(this.eventData.data);
					// this.whatsappTicketService.updateAllItemsByItem(this.eventData.data)
					break;
				case 'ticketremoved':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'aticketremoved':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketclosed':
					this.instagramTicketService.spliceItem(this.eventData.data.id);
					break;
				case 'ticketabandon':
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					break;
				// case 'newmessage':
				// 	this.whatsappTicketService.getAllItems();
				// 	break;
				default:
					break;
			}
		}
		
		if (this.eventData && this.eventData.site && this.eventData.site?.toLowerCase() === 'instagram' && this.userService.isAuthUserIsClient()) {
			let event: string = this.eventData.event;
			switch (event) {
				case 'aticketassigned':
					this.concatItemData(this.eventData.data, "aticketassigned");
					break;
				case 'aticketreassigned':
					this.concatItemData(this.eventData.data, "aticketreassignedClient");
					break;
				case 'ticketassigned':
					this.instagramTicketService.concatItem(this.eventData.data);
					break;
				case 'ticketreassigned':
					this.instagramTicketService.updateAllItemsByItem(this.eventData.data);
					this.instagramTicketService.updateReplacelibrary(this.eventData.data);
					break;
				case 'ticketremoved':
				case 'ticketclosed':
				case 'ticketabandon':
					if (this.eventData.data && this.eventData.data.agentInfo) {
						this.eventData.data["agentName"] = this.eventData.data.agentInfo.name;
						if (this.eventData.data.agentInfo.person_details) {
							this.eventData.data["agentAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["createdByAvatar"] = this.eventData.data.agentInfo.person_details.image_api;
							this.eventData.data["agentLiveStatus"] = this.eventData.data.agentInfo.person_details.agentLiveStatus;
						}
					}if (this.eventData.data.client_site_id) {
						this.eventData.data.clientSiteId = this.eventData.data.client_site_id;
					}
					if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.id) {
						this.eventData.data["peopleName"] = this.eventData.data.leadInfo?.name;
						this.eventData.data["peopleAvatar"] = this.eventData.data.leadInfo?.peopleAvatar;
						this.eventData.data["recipientId"] = this.eventData.data.leadInfo?.unique_ref;
						if (this.eventData.data.leadInfo && this.eventData.data.leadInfo.labelInfo) {
							this.eventData.data["peopleLabelId"] = this.eventData.data.leadInfo.labelInfo?.id;
							this.eventData.data["peopleLabelName"] = this.eventData.data.leadInfo.labelInfo?.name;
							this.eventData.data["peopleLabelColor"] = this.eventData.data.leadInfo.labelInfo?.color;
							this.eventData.data["peopleLabelColorText"] = this.eventData.data.leadInfo.labelInfo?.color_text;
						}
					}
					this.instagramTicketService.changeAllItemsByItem(this.eventData.data);
					break;
				// case 'aticketremoved':
				// 	this.concatItemData(this.eventData.data, "aticketremoved");
				// 	break;
				default:
					break;
			}
		}
	}

	getLeadsHistory(ticket: InstagramTicket) {
		this.leadsHistoryParam.client_id = ticket.client_id;
		this.leadsHistoryParam.client_site_id = ticket.clientSiteId;
		this.leadsHistoryParam.people_id = ticket.people_id;
		this.leadsHistoryService.getAllItems();
	}


	showConversationHistory(ticket): void {
		let dialogRef = this.matDialog.open(ConversationInstagramHistoryComponent, {
			width: "600px",
			data: {
				action: 'show',
				item: ticket,
				ticketClassName: (ticket) ? ticket.table : null,
				sourceFrom: 'ticket',
			}
		});
		dialogRef.afterClosed().subscribe(response => { });
	}

	concatItemData(data, status) {
		var ticketList = data;
		var dataFormat;
		if (ticketList && ticketList.id) {
			var dataFormats = {
				id: ticketList.id,
				ticketId: ticketList.id,
				instagram_id: ticketList.instagram_id,
				code: ticketList.code,
				client_id: ticketList.client_id,
				agent_id: ticketList.agent_id,
				priority: ticketList.priority,
				is_active: ticketList.is_active,
				is_closed: ticketList.is_closed ? true : false,
				comment: ticketList.comment,
				people_id: ticketList.people_id,
				created_by: ticketList.created_by,
				updated_by: ticketList.updated_by,
				created_at: moment(ticketList.created_at + '.000+0300').local(),
				last_message_on: ticketList.updated_at,
				deleted_at: ticketList.deleted_at,
				is_closeable: ticketList.is_closeable,
				statusChanges: null,
			}
			if (ticketList.agentInfo) {
				dataFormats["agentName"] = ticketList.agentInfo.name;
				if (ticketList.agentInfo.person_details) {
					dataFormats["agentAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["createdByAvatar"] = ticketList.agentInfo.person_details.image_api;
					dataFormats["agentLiveStatus"] = ticketList.agentInfo.person_details.agentLiveStatus;
				}
			}

			if (ticketList.clientSite) {
				dataFormats["clientSiteTextColor"] = ticketList.clientSite?.textcolor;
				dataFormats["clientSiteName"] = ticketList.clientSite?.account_name;
				dataFormats["clientSiteId"] = ticketList.clientSite?.id;
				if (ticketList && ticketList.clientSite && ticketList.clientSite.details) {
					dataFormats["siteName"] = ticketList.clientSite.details?.name;
					dataFormats["siteId"] = ticketList.clientSite.details?.id;
					dataFormats["siteIcon"] = ticketList.clientSite.details?.icon;
					dataFormats["siteColor"] = ticketList.clientSite.details?.color;
					dataFormats["clientSiteColor"] = ticketList.clientSite.details?.color;
				}
			}
			if (ticketList.leadInfo.id) {
				dataFormats["peopleName"] = ticketList.leadInfo?.name;
				dataFormats["peopleAvatar"] = ticketList.leadInfo?.peopleAvatar;
				dataFormats["recipientId"] = ticketList.leadInfo?.unique_ref;
				if (ticketList.leadInfo && ticketList.leadInfo.labelInfo) {
					dataFormats["peopleLabelId"] = ticketList.leadInfo.labelInfo?.id;
					dataFormats["peopleLabelName"] = ticketList.leadInfo.labelInfo?.name;
					dataFormats["peopleLabelColor"] = ticketList.leadInfo.labelInfo?.color;
					dataFormats["peopleLabelColorText"] = ticketList.leadInfo.labelInfo?.color_text;
				}
			}
			var statusData = [];
			if (ticketList.ticketStatus) {
				if (statusData && statusData.length > 0) {
					let lastStatusData = statusData.pop();
					dataFormats["statusName"] = lastStatusData.name;
					dataFormats["className"] = lastStatusData.model_type;
				}
			}
		}
		dataFormat = dataFormats;
		if (status == "aticketassigned") {
			this.instagramTicketService.concatItem(dataFormat);
		}
		if (status == "aticketreassigned") {
			this.instagramTicketService.updateAllItemsByItem(dataFormat);
			this.instagramTicketService.updateReplacelibrary(dataFormat);
		}
		if (status == "aticketreassignedClient") {
			this.instagramTicketService.updateAllItemsByItem(dataFormat);
			this.instagramTicketService.updateReplacelibrary(dataFormat);
		}
		if (status == "aticketIsAgent") {
			this.instagramTicketService.concatItem(dataFormat);
		}
		if (status == "aticketremoved") {
			this.instagramTicketService.changeAllItemsByItem(dataFormat);
		}

	}

}
