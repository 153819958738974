import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ProfileValidateGuard } from 'src/app/auth/profileValidate.guard';

import { MainComponent } from './main/main.component';
import { ChatComponent } from './chat/chat.component';

const routes = [
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('src/app/appAccount/dashboard/dashboard.module').then(d => d.DashboardModule),
				canActivate: [AuthGuard, ProfileValidateGuard],
				data: { dashboard: 'Admin Dashboard' }
			},
			{
				path: 'admin-dashboard',
				loadChildren: () => import('src/app/appAccount/adminDashboard/adminDashboard.module').then(d => d.AdminDashboardModule),
				canActivate: [AuthGuard, ProfileValidateGuard],
				data: { dashboard: 'Super Admin Dashboard' }
			},
			{
				path: 'agent-dashboard',
				loadChildren: () => import('src/app/appAccount/agentDashboard/agentDashboard.module').then(d => d.AgentDashboardModule),
				canActivate: [AuthGuard, ProfileValidateGuard],
				data: { dashboard: 'Agent Dashboard' }
			},
			{
				path: 'agent',
				loadChildren: () => import('src/app/appAccount/agent/agent.module').then(a => a.AgentModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'team',
				loadChildren: () => import('src/app/appAccount/team/team.module').then(a => a.TeamModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'reseller',
				loadChildren: () => import('src/app/appAccount/reseller/reseller.module').then(a => a.ResellerModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'client',
				loadChildren: () => import('src/app/appAccount/client/client.module').then(a => a.ClientModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'people',
				loadChildren: () => import('src/app/appAccount/people/people.module').then(a => a.PeopleModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'consumer',
				loadChildren: () => import('src/app/appAccount/consumer/consumer.module').then(a => a.ConsumerModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'social-site',
				loadChildren: () => import('src/app/appAccount/socialSite/socialSite.module').then(a => a.SocialSiteModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'smm',
				loadChildren: () => import('src/app/appAccount/socialMediaManagement/socialMediaManagement.module').then(a => a.SocialMediaManagementModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'template',
				loadChildren: () => import('src/app/appAccount/template/template.module').then(a => a.TemplateModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'template-usage',
				loadChildren: () => import('src/app/appAccount/template-group/template-group.module').then(d => d.TemplateGroupModule),
				canActivate: [AuthGuard],
			},
			// {
			// 	path: 'template-success',
			// 	loadChildren: () => import('src/app/appAccount/template-group/template-success/template-success.module').then(d => d.TemplateSuccessModule),
			// 	canActivate: [AuthGuard],
			// },
			{
				path: 'intractive-message',
				loadChildren: () => import('src/app/appAccount/intractiveMessage/intractiveMessage.module').then(a => a.IntractiveMessageModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'itrsbot',
				loadChildren: () => import('src/app/appAccount/itrsbot/itrsbot.module').then(a => a.ItrsbotModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'open-conversation',
				loadChildren: () => import('src/app/appAccount/openConversation/openConversation.module').then(a => a.OpenConversationModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'closed-conversation',
				loadChildren: () => import('src/app/appAccount/closedConversation/closedConversation.module').then(a => a.ClosedConversationModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'find-conversation',
				loadChildren: () => import('src/app/appAccount/findConversation/findConversation.module').then(a => a.FindConversationModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'queue-ticket',
				loadChildren: () => import('src/app/appAccount/openTicket/openTicket.module').then(a => a.OpenTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'tweet-ticket',
				loadChildren: () => import('src/app/appAccount/tweetTicket/tweetTicket.module').then(a => a.TweetTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'tweetdm-ticket',
				loadChildren: () => import('src/app/appAccount/tweetdmTicket/tweetdmTicket.module').then(a => a.TweetdmTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'messenger-ticket',
				loadChildren: () => import('src/app/appAccount/messengerTicket/messengerTicket.module').then(a => a.MessengerTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'instagram-ticket',
				loadChildren: () => import('src/app/appAccount/instagramTicket/instagramTicket.module').then(a => a.InstagramTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'instadm-ticket',
				loadChildren: () => import('src/app/appAccount/instadmTicket/instadmTicket.module').then(a => a.InstadmTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'whatsapp-ticket',
				loadChildren: () => import('src/app/appAccount/whatsappTicket/whatsappTicket.module').then(a => a.WhatsappTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'webchat-ticket',
				loadChildren: () => import('src/app/appAccount/webchatTicket/webchatTicket.module').then(a => a.WebchatTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'youtube-ticket',
				loadChildren: () => import('src/app/appAccount/youtubeTicket/youtubeTicket.module').then(a => a.YoutubeTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'abandon-ticket',
				loadChildren: () => import('src/app/appAccount/abandonTicket/abandonTicket.module').then(a => a.AbandonTicketModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'role-permission',
				loadChildren: () => import('src/app/appAccount/roles/roles.module').then(a => a.RolesModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'gallery',
				loadChildren: () => import('src/app/appAccount/gallery/gallery.module').then(a => a.GalleryModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'manage-permission',
				loadChildren: () => import('src/app/appAccount/managePermission/managePermission.module').then(a => a.ManagePermissionModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'notice',
				loadChildren: () => import('src/app/appAccount/notice/notice.module').then(a => a.NoticeModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'comment',
				loadChildren: () => import('src/app/appAccount/comment/comment.module').then(a => a.CommentModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'activity',
				loadChildren: () => import('src/app/appAccount/log/log.module').then(a => a.LogModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'flag-master',
				loadChildren: () => import('src/app/appAccount/flag/flag.module').then(a => a.FlagModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'setting',
				loadChildren: () => import('src/app/appAccount/setting/setting.module').then(a => a.SettingModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'workspace-setting',
				loadChildren: () => import('src/app/appAccount/workspaceSetting/workspaceSetting.module').then(a => a.WorkspaceSettingModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'label',
				loadChildren: () => import('src/app/appAccount/label/label.module').then(a => a.LabelModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'report',
				loadChildren: () => import('src/app/appAccount/report/report.module').then(a => a.ReportModule),
				canActivate: [ProfileValidateGuard],
			},

			{
				path: 'menu',
				loadChildren: () => import('src/app/appAccount/menuGroup/menuGroup.module').then(a => a.MenuGroupModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'profile-update',
				loadChildren: () => import('src/app/appAccount/profileUpdate/profileUpdate.module').then(a => a.ProfileUpdateModule),
			},
			{
				path: 'diagnostic-tool',
				loadChildren: () => import('src/app/appAccount/diagnosticTool/diagnosticTool.module').then(a => a.DiagnosticToolModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'template-failed',
				loadChildren: () => import('src/app/appAccount/templateGroup/templatefailed/templatefailed.module').then(a => a.TemplatefailedModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'product-catalog',
				loadChildren: () => import('src/app/appAccount/product-catalog/product-catalog.module').then(a => a.ProductCatalogModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'product-settings',
				loadChildren: () => import('src/app/appAccount/productSettings/product-settings.module').then(a => a.ProductSettingsModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'catalog',
				loadChildren: () => import('src/app/appAccount/catalog/catalog.module').then(a => a.CatalogModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'ai-collections',
				loadChildren: () => import('src/app/appAccount/aiCollection/aiCollection.module').then(a => a.AiCollectionModule),
				canActivate: [ProfileValidateGuard],
			},
			{
				path: 'fb-template',
				loadChildren: () => import('src/app/appAccount/fbTemplate/fb-template.module').then(a => a.FbTemplateModule),
				canActivate: [ProfileValidateGuard],
			},
		]
	},
	{
		path: 'app-chat',
		component: ChatComponent,
	},
	{ path: '**', redirectTo: '/app/dashboard' }
];

@NgModule({
	imports: [
		RouterModule.forChild(routes),
	],
	exports: [
		RouterModule
	]
})

export class AppAccountRouteModule { }
