import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { DataSource } from '@angular/cdk/collections';
import { merge, Observable, BehaviorSubject, fromEvent, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from 'src/app/library/dialog/confirm/confirm.component';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import * as XLSX from 'xlsx';

import {
	User, UserService,
	FbTemplateMessageService, FbTemplateMessage, FbTemplateMessageModel, FirebaseJobService, FirebaseJob,
	ClientSite,
	ClientSiteService
} from 'src/app/@tji/_dbShare';

@UntilDestroy()
@Component({
	selector: 'fb-bulk-import',
	templateUrl: './bulkImport.component.html',
	styleUrls: ['./bulkImport.component.scss']
})

export class FbBulkImportComponent implements OnInit, OnDestroy {
	dialogTitle: string = 'Bulk Import';
	error: string = '';
	excelJsonData: any = [];
	selectedExcelData: any = [];
	convertTemplateJson = [];
	worksheetDataKey: any;
	isUploadFile: boolean = true;
	isAssingDate: boolean = false;
	selectWorkSheet: string = '';
	fileData: any;
	template: FbTemplateMessage;
	isSend: boolean = true;
	isRepeat: boolean = false;
	convertTemplateJsonLength: any;
	TemplateJsonLength: any;
	client_site_id: any;
	clientSites: ClientSite[] = [];

	constructor(public userService: UserService,
		public fbTemplateMessageService: FbTemplateMessageService,
		public firebaseJobService: FirebaseJobService,
		private router: Router,
		private _formBuilder: FormBuilder,
		private cd: ChangeDetectorRef,
		private alertService: AlertService,
		private clientSiteService: ClientSiteService,
		public matDialogRef: MatDialogRef<FbBulkImportComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		public location: Location) {
		this.fbTemplateMessageService.item.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data.id) {

				data.required_values['required'] = ["to"]
				// data.forEach(element => {

				// });
				this.template = data;
			}
		});
		this.clientSiteService.allItems.pipe(untilDestroyed(this)).subscribe(data => {
			this.clientSites = data;
			this.clientSites = this.clientSites.filter(client => client?.mongo?.waba_id == this.template.waba_id)
		});

		this.fbTemplateMessageService.temSend.pipe(untilDestroyed(this)).subscribe(data => {
			if (data && data[0] && data[0].jobcode) {
				this.matDialogRef.close();
				if (this.isRepeat) {
					var msg = "Job created with Job Code " + data[0].jobcode + " " + this.convertTemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msg);
				} else {
					var msgs = "Job created with Job Code " + data[0].jobcode + " " + this.TemplateJsonLength + " out of " + this.TemplateJsonLength
					this.alertService.webShow('Success', msgs);
				}

			}
		});

	}

	ngOnInit() {
		// this.cd.detectChanges();
	}

	/** On destroy */
	ngOnDestroy(): void {
		this.fbTemplateMessageService.bulkTemSend.next([]);
		this.fbTemplateMessageService.unSubscribe();
		this.firebaseJobService.changeJobcode(null);
	}

	// doLoader() {
	// 	setTimeout(() => {
	// 		this.loader = !this.loader;
	// 	}, 5000);
	// }

	// timer() {
	// 	let data = 'bulk';
	// 	setTimeout(() => {
	// 		if (this.remainingCounts != 0) {
	// 			this.templateMessageService.getCompletedCounts({ 'queue_name': data });
	// 			this.completeCounts = this.totalCounts - this.remainingCounts;
	// 			this.rangeValue = (100 / this.totalCounts) * this.completeCounts;
	// 			// this.timer();
	// 		} else {
	// 			this.rangeValue = 100;
	// 			this.completeCounts = this.totalCounts;
	// 		}
	// 	}, 3000);
	// }

	readExcel(event) {
		this.fileData = event;
		var types = event.target.files[0].name.split(/\.(?=[^\.]+$)/);
		var typeDatas = types[1];
		if (typeDatas == 'xlsx' || typeDatas == 'xls' || typeDatas == 'csv') {
			let workBook = null;
			let jsonData = null;
			const reader = new FileReader();
			const file = event.target.files[0];
			reader.onload = (event) => {
				const data = reader.result;
				workBook = XLSX.read(data, { type: 'binary' });
				jsonData = workBook.SheetNames.reduce((initial, name) => {
					const sheet = workBook.Sheets[name];
					initial[name] = XLSX.utils.sheet_to_json(sheet);
					initial['keys'] = sheet
					var excel = {
						keys: name,
						data: XLSX.utils.sheet_to_json(sheet, { defval: "" }) //XLSX.utils.sheet_to_json(workBook.Sheets[sheet], {defval:""}) //XLSX.utils.sheet_to_json(sheet)
					}
					this.excelJsonData.push(excel);
					return initial;
				}, {});
				const dataString = jsonData //JSON.stringify(jsonData);
				if (jsonData) {
					this.isUploadFile = false;
					this.isAssingDate = true;

				}
			}
			reader.readAsBinaryString(file);
		} else {
			this.error = "upload file formate is worng. only accept xlsx, xls and csv"
		}
	}

	isWorksheetDataKey = 'false';
	selectWorkSheetData(e, work) {
		this.worksheetDataKey = null;
		this.excelJsonData.forEach(workbook => {
			if (workbook.keys == e.keys) {
				if (!workbook.data[0]) {
					this.isWorksheetDataKey = 'false';
					return;
				}
				this.selectedExcelData = workbook.data;

				var keys = Object.keys(workbook.data[0]);
				if (keys && keys.length > 0) {
					this.worksheetDataKey = [];
					keys.forEach(element => {
						var iss = {
							key: element,
							isdIsabled: false
						}
						this.worksheetDataKey.push(iss);
					});
				} else {
					this.isWorksheetDataKey = 'false';
				}

			}
		});
		if (this.worksheetDataKey && this.worksheetDataKey.length > 0) {
			this.isWorksheetDataKey = 'true';
		}

		// console.log("===========================", this.worksheetDataKey)
	}

	selectData(requireValue, selectedItem, bodyIndex: any = null) {
		this.worksheetDataKey.forEach(element => {
			if (element.key == selectedItem) {
				this.worksheetDataKey.forEach(keys => {
					if (keys.requireValue == requireValue) {
						delete keys.requireValue;
						keys['isdIsabled'] = false;
						delete element.requireValue;
						element['isdIsabled'] = false;
					}
				});
				if (requireValue.type == 'body' && requireValue.parameters && requireValue.parameters.length > 0) {
					requireValue.parameters.forEach((bodyParmeter, idx) => {
						if (idx == bodyIndex) {
							// bodyParmeter['requireValue'] = bodyIndex.toString();
							// bodyParmeter['isdIsabled'] = true;
							element['requireValue'] = bodyParmeter;
							element['requireValue']['index'] = idx;
							element['requireValue']['type'] = 'body';
							element['isdIsabled'] = true;
						}
					});

				} else if (requireValue.type == 'button' && requireValue.parameters && requireValue.parameters.length > 0) {
					requireValue.parameters.forEach((buttonParmeter, idx) => {
						if (idx == bodyIndex) {
							// buttonParmeter['requireValue'] = bodyIndex.toString();
							// buttonParmeter['isdIsabled'] = true;
							element['requireValue'] = buttonParmeter;
							element['requireValue']['index'] = requireValue.index;
							element['requireValue']['type'] = 'button';
							element['isdIsabled'] = true;
						}
					});

				} else {
					element['requireValue'] = requireValue
					element['isdIsabled'] = true;
				}
			}
			if (selectedItem == '') {
				if (element.requireValue == requireValue) {
					delete element['requireValue'];
					element['isdIsabled'] = false;
				}
			}
		});
		this.isSend = false;
	}



	sendTemplate() {

		if (!this.client_site_id) {
			this.alertService.webShow('Success', 'Please select Account');
			return;
		}
		var convertTemplateJson = [];
		this.selectedExcelData.forEach((excel) => {
			var finalData = {
				data: [],
			};
			var body = {
				"type": "body",
				"parameters": [

				]
			}
			var finalQuick = {
				data: [],
			};
			var finalUrl = {
				data: [],
			};
			var finalCopy = {
				data: [],
			};
			this.worksheetDataKey.forEach(element => {
				Object.keys(excel).forEach(excelKey => {
					if (excelKey.toLowerCase() == element['key'].toLowerCase() && element['requireValue'] == "to") {
						finalData["to"] = excel[element['key']];
						// finalData[element['requireValue']] = excel[element['key']];
					}
					if (excelKey.toLowerCase() == element['key'].toLowerCase() && element['requireValue'] && element['requireValue']['type']) {

						if (element['requireValue']['type'] == "header") {
							if (element['requireValue']["parameters"][0].type == "document") {
								var index = excel[element['key']].lastIndexOf("/") + 1;
								var filename = excel[element['key']].substr(index);
								var document = {
									"type": "header",
									"parameters": [
										{
											"type": "document",
											"document": {
												"link": excel[element['key']],
												"filename": filename ? filename : ''
											}
										}
									]
								}
								finalData["data"].push(document);
							}
							if (element['requireValue']["parameters"][0].type == "text") {
								var text = {
									"type": "header",
									"parameters": [
										{
											"type": "text",
											"text": excel[element['key']]
										}
									]
								}
								finalData["data"].push(text);
							}
							if (element['requireValue']["parameters"][0].type == "image") {
								var image = {
									"type": "header",
									"parameters": [
										{
											"type": "image",
											"image": {
												"link": excel[element['key']]
											}
										}
									]
								}
								finalData["data"].push(image);
							}
							if (element['requireValue']["parameters"][0].type == "video") {
								var video = {
									"type": "header",
									"parameters": [
										{
											"type": "video",
											"video": {
												"link": excel[element['key']]
											}
										}
									]
								}
								finalData["data"].push(video);
							}
						}

					}
					if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == 'body') {
						if (this.template && this.template.required_values && this.template.required_values.data) {
							this.template.required_values.data.forEach(dataSection => {
								// body contains
								if (dataSection.type == 'body' && element['requireValue']['type'] == "body") {

									dataSection.parameters.forEach((parameter, index) => {
										if (parameter.index == element['requireValue'].index) {
											var textData = {
												"type": "text",
												"text": excel[element['key']]
											}
											body.parameters.push(textData);
										}

									});
								}

							});
						}
						var finalBody = {
							data: [],
						};
						finalBody["data"].push(body);
						finalBody["data"] = [...finalBody["data"].reduce((map, obj) => map.set(obj.type, obj), new Map()).values()];
						finalBody["data"]['0']['parameters'] = [...finalBody["data"]['0']['parameters'].reduce((map, obj) => map.set(obj.text, obj), new Map()).values()];
						finalData["data"].push(finalBody.data);
					}

					if (element['requireValue'] && element['requireValue']['type'] && element['requireValue']['type'] == 'button') {
						
						if (this.template && this.template.required_values && this.template.required_values.data) {
							this.template.required_values.data.forEach(dataSection => {

								// button contains
								

								if (dataSection.type == 'button' && dataSection.sub_type == 'quick_reply') {

									var quickButton = {
										"type": "button",
										"sub_type": "quick_reply",
										"parameters": [],
										"index": dataSection.index
									}
									dataSection.parameters.forEach((parameter, index) => {
										if (parameter.index == element['requireValue'].index) {
											var textData = {
												"type": "payload",
												"payload": excel[element['key']]
											}
											quickButton.parameters.push(textData);
										}
										finalQuick["data"].push(quickButton);
									});

								}
								if (dataSection.type == 'button' && dataSection.sub_type == 'url') {

									var urlButton = {
										"type": "button",
										"sub_type": "url",
										"parameters": [],
										"index": dataSection.index
									}
									dataSection.parameters.forEach((parameter, index) => {
										if (parameter.index == element['requireValue'].index) {
											var urlButtons = {
												"type": "text",
												"text": excel[element['key']]
											}
											urlButton.parameters.push(urlButtons);
										}
										finalUrl["data"].push(urlButton);
									});

								}
								if (dataSection.type == 'button' && dataSection.sub_type == 'copy_code') {

									var copyButton = {
										"type": "button",
										"sub_type": "copy_code",
										"parameters": [],
										"index": dataSection.index
									}
									dataSection.parameters.forEach((parameter, index) => {
										if (parameter.index == element['requireValue'].index) {
											var copyButtons = {
												"type": "coupon_code",
												"coupon_code": excel[element['key']]
											}
											copyButton.parameters.push(copyButtons);
										}
										finalCopy["data"].push(copyButton);
									});

								}



							});
						}
						
						// finalData["data"].push(body);
						// finalData["data"] = [...finalData["data"].reduce((map, obj) => map.set(obj.type, obj), new Map()).values()];
						// finalData["data"]['0']['parameters'] = [...finalData["data"]['0']['parameters'].reduce((map, obj) => map.set(obj.text, obj), new Map()).values()];
					}

					
					// if (excelKey.toLowerCase() == element['key'].toLowerCase() && element['requireValue'] && !element['requireValue']['type']) {

					// 	if (this.template && this.template.required_values && this.template.required_values.data) {



					// 		if (this.template && this.template.required_values && this.template.required_values.data) {
					// 			this.template.required_values.data.forEach(dataSectionBody => {
					// 				if (dataSectionBody.type == 'body' && element['requireValue']['type'] == "body") {
					// 					var body = {
					// 						"type": "body",
					// 						"parameters": [

					// 						]
					// 					}
					// 					dataSectionBody.parameters.forEach((parameter, index) => {
					// 						if (index == element['requireValue'].index) {
					// 							var textData = {
					// 								"type": "text",
					// 								"text": excel[element['key']]
					// 							}
					// 							body.parameters.push(textData);
					// 						}
					// 						finalData["data"].push(body);
					// 					});

					// 				}
					// 			});
					// 		}


					// 	}
					// 	// button
					// 	if (this.template && this.template.required_values && this.template.required_values.data) {
					// 		if (this.template && this.template.required_values && this.template.required_values.data) {
					// 			this.template.required_values.data.forEach(dataSection => {
					// 				if (dataSection.type == 'button' && dataSection.sub_type == 'quick_reply') {

					// 					var quickButton = {
					// 						"type": "button",
					// 						"sub_type": "quick_reply",
					// 						"parameters": [],
					// 						"index": dataSection.index
					// 					}
					// 					dataSection.parameters.forEach((parameter, index) => {
					// 						if (index == element['requireValue'].toLowerCase()) {
					// 							var textData = {
					// 								"type": "payload",
					// 								"payload": excel[element['key']]
					// 							}
					// 							quickButton.parameters.push(textData);
					// 						}
					// 						finalData["data"].push(quickButton);
					// 					});

					// 				}
					// 				if (dataSection.type == 'button' && dataSection.sub_type == 'url') {

					// 					var urlButton = {
					// 						"type": "button",
					// 						"sub_type": "url",
					// 						"parameters": [],
					// 						"index": dataSection.index
					// 					}
					// 					dataSection.parameters.forEach((parameter, index) => {
					// 						if (index == element['requireValue'].toLowerCase()) {
					// 							var urlButtons = {
					// 								"type": "text",
					// 								"text": excel[element['key']]
					// 							}
					// 							urlButton.parameters.push(urlButtons);
					// 						}
					// 						finalData["data"].push(urlButton);
					// 					});

					// 				}
					// 				if (dataSection.type == 'button' && dataSection.sub_type == 'copy_code') {

					// 					var copyButton = {
					// 						"type": "button",
					// 						"sub_type": "copy_code",
					// 						"parameters": [],
					// 						"index": dataSection.index
					// 					}
					// 					dataSection.parameters.forEach((parameter, index) => {
					// 						if (index == element['requireValue'].toLowerCase()) {
					// 							var copyButtons = {
					// 								"type": "coupon_code",
					// 								"coupon_code": excel[element['key']]
					// 							}
					// 							copyButton.parameters.push(copyButtons);
					// 						}
					// 						finalData["data"].push(copyButton);
					// 					});

					// 				}
					// 			});
					// 		}


					// 	}

					// }
				});
			});
			if (finalQuick["data"] && finalQuick["data"].length > 0) {
				finalQuick["data"] = [...finalQuick["data"].reduce((map, obj) => map.set(obj.sub_type, obj), new Map()).values()];
				finalQuick["data"]['0']['parameters'] = [...finalQuick["data"]['0']['parameters'].reduce((map, obj) => map.set(obj.payload, obj), new Map()).values()];
				finalData["data"].push(finalQuick.data);
			}
			if (finalUrl["data"] && finalUrl["data"].length > 0) {
				finalUrl["data"] = [...finalUrl["data"].reduce((map, obj) => map.set(obj.sub_type, obj), new Map()).values()];
				finalUrl["data"]['0']['parameters'] = [...finalUrl["data"]['0']['parameters'].reduce((map, obj) => map.set(obj.text, obj), new Map()).values()];
				finalData["data"].push(finalUrl.data);
			}
			if (finalCopy["data"] && finalCopy["data"].length > 0) {
				finalCopy["data"] = [...finalCopy["data"].reduce((map, obj) => map.set(obj.sub_type, obj), new Map()).values()];
				finalCopy["data"]['0']['parameters'] = [...finalCopy["data"]['0']['parameters'].reduce((map, obj) => map.set(obj.coupon_code, obj), new Map()).values()];
				finalData["data"].push(finalCopy.data);
			}

			convertTemplateJson.push(finalData);
		});

		this.TemplateJsonLength = convertTemplateJson.length;
		if (this.isRepeat) {
			convertTemplateJson = convertTemplateJson.filter(function (e) { return e });
			convertTemplateJson = [...new Map(convertTemplateJson.map((json) => [json.to, json])).values()];
			this.convertTemplateJsonLength = convertTemplateJson.length;
		}
		var datas = {
			"template_id": this.template.id,
			"variables": convertTemplateJson,
			"client_site_id": this.client_site_id
		}
		if (convertTemplateJson && convertTemplateJson.length > 0) {
			// console.log("convertTemplateJson", datas)
			this.fbTemplateMessageService.sendTemplate(datas);
		} else {
			this.alertService.webShow('Success', 'Please select a correct data');
		}
	}
}
