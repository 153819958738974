export class FbTemplateMessageModel {
   _id: number;
	id:number;
	waba_id: number;
	client_site_id: number;
	client_id: number;
	name: string;
	category: string;
	allow_category_change: boolean;
	language: string;
	components: any;
	created_at: Date;
	updated_at: Date;
	status: string;
	required_values: any;
	siteName?:string;
	clientSiteName?:string;
	clientSiteColor?:string;
	clientSiteTextColor?:string;
    waba_name: string;
	templateComponent: any;
    business_id: number;
	business_name: string;
    rejected_reason: string;
    postData: any;
    isCarousel: boolean;

    /**
     * Constructor
     *
     * @param FbTemplateMessage
     */
    constructor(FbTemplateMessage) {
            this._id = FbTemplateMessage.id || '';
            this.id = FbTemplateMessage.id || '';
            this.waba_id = FbTemplateMessage.waba_id || '';
            this.client_site_id = FbTemplateMessage.client_site_id || '';
            this.client_id = FbTemplateMessage.client_id || '';
            this.name = FbTemplateMessage.name || '';
            this.category = FbTemplateMessage.category || '';
            this.allow_category_change = FbTemplateMessage.allow_category_change || '';
            this.language = FbTemplateMessage.language || '';
            this.components = FbTemplateMessage.components || '';
            this.created_at = FbTemplateMessage.created_at || '';
            this.updated_at = FbTemplateMessage.updated_at || '';
            this.status = FbTemplateMessage.status || '';
            this.required_values = FbTemplateMessage.required_values || '';
            this.siteName = FbTemplateMessage.siteName || '';
            this.clientSiteName = FbTemplateMessage.clientSiteName || '';
            this.clientSiteColor = FbTemplateMessage.clientSiteColor || '';
            this.clientSiteTextColor = FbTemplateMessage.clientSiteTextColor || '';
            this.waba_name = FbTemplateMessage.waba_name || '';
            this.templateComponent = FbTemplateMessage.templateComponent || '';
            this.business_id = FbTemplateMessage.business_id || '';
            this.business_name = FbTemplateMessage.business_name || '';
            this.rejected_reason = FbTemplateMessage.rejected_reason || '';
            this.postData = FbTemplateMessage.postData || '';
            this.isCarousel = FbTemplateMessage.isCarousel || false;
    }
}
