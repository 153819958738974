import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import { catalogProduct } from './catalogProduct.interface';
import { CatalogProductModel } from './catalogProduct-model.model';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class CatalogProductService {
  url: string = 'fbProduct';
  currencyUrl: string = 'fbProduct/init';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  jobCount: number = 0;

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  private allItemsSource = new BehaviorSubject<catalogProduct[]>([]);
  allItems = this.allItemsSource.asObservable();

  private allCurrencyItemsSource = new BehaviorSubject<catalogProduct[]>([]);
  allICurrencyItems = this.allCurrencyItemsSource.asObservable();

  public itemSource = new BehaviorSubject<catalogProduct>(new CatalogProductModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<catalogProduct>(new CatalogProductModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<catalogProduct[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public isAddItems = new BehaviorSubject<boolean>(false);
  addItems = this.isAddItems.asObservable();

  private allIIntractivetemsSource = new BehaviorSubject<catalogProduct[]>([]);
  allIntractiveProductItems = this.allIIntractivetemsSource.asObservable();

  private CatalogNameData = new BehaviorSubject<any>('');
  catalogName = this.CatalogNameData.asObservable();

  public isLoaderSource = new BehaviorSubject<boolean>(false);
	isLoader = this.isLoaderSource.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.getAllCurrencyItem(),
        this.getAllItems(),
        this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed productService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on productService');
  }

  concatlibrary(allItems: catalogProduct[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: catalogProduct) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: catalogProduct) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: catalogProduct[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: catalogProduct[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeItem(item: catalogProduct) {
    this.itemSource.next(item);
  }

  changeItemId(item: catalogProduct) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: catalogProduct[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: catalogProduct) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getAllCurrencyItem(params: any = null) {
    // params = this.paramsInit(params);
    this.commonService.storeItem(this.currencyUrl, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.allCurrencyItemsSource.next(data.data);
        }
      },
        error => console.log('Error ::' + error)
      );
  }

  getAllItems(params: any = null) {
    params = this.paramsInit(params);
    // this.routeParams = (params) ? params : this.routeParams;
    if (this.routeParams && this.routeParams.id) {
      params['catalog_id'] = this.routeParams.id;
    }
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          if (data && data.additional && data.additional.CatalogInfo) {
            data.additional.CatalogInfo.forEach(element => {
              if (element.id == params.catalog_id) {
                this.CatalogNameData.next(element.name);
              }
            });
            data.data.forEach(product => {
              if (product.availability == 'in stock') {
                product.availability = '1';
                product.is_active = '1';
              } else if (!product.availability) {
                product.availability = '1';
                product.is_active = '1';
              } else {
                product.availability = '0';
                product.is_active = '0';
              }

              data.additional.CatalogInfo.forEach(catalog => {
                if (product.catalog_id == catalog.id) {
                  product['catalogName'] = catalog.name;
                }
              });
            });
          }
          this.changeAllItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        }
      },
        error => console.log('Error ::' + error)
      );
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.getAll(this.url, params)
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.data.last_page <= data.data.current_page) {
          params.page = data.data.last_page;
          this.changeParams(params);
        }
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: catalogProduct) {
    let lists = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result.concat([item]);
    });
    this.changeAllItems(lists);
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id == id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  getItem(params: any = null) {
    this.routeParams = (params) ? params : this.routeParams;
    var itemId;
    this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
    var id = {
      "id": itemId
    }
    if (itemId > 0) {

      this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          this.changeAllItemsByItem(data.data);
          this.changeItem(data.data);
        },
          error => console.log('Error ::' + error)
        );
    }
    else {
      this.changeItem(new CatalogProductModel({}));
    }
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.itemSource.next(null);
          this.alert('Success', res.data.message);
          if (res && res.data && res.data.jobs) {
            this.jobCount = res.data.jobs;
          }
          if (res && res.data && res.data.reload) {
            this.getAllItems();
          }
          if (res && data && data.id) {
            this.getAllItems();
          }
          if (this.jobCount > 0 && data && data.id) {
            setTimeout(() => {
              this.manageJobs();
            }, 20000);
          }

        } else {
          this.alertService.webErrorShow(res);
          this.isAddItems.next(false);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.isAddItems.next(false);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  manageJobs() {
    var job = {
      "jobs": this.jobCount
    }
    this.commonService.storeItem("fbProduct/managejobs", job, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          if (this.jobCount > 0 && res.data && res.data.jobs < this.jobCount) {
            this.jobCount = res.data.jobs;
            this.getAllItems();
          }
          if (this.jobCount > 0) {
            this.jobCount = res.data.jobs
            setTimeout(() => {
              this.manageJobs();
            }, 50000);
          }
          if (res && res.message) {
            this.alert('Success', res.message);
          }
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  sync(url, data: any = null) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', 'Product Sync Successfully !!!');
          this.getAllItems();
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }
  update(url, data: any, product = null): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.product_id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  bussinessActivity(url, data: any, product = null): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          // product['clientSiteInfo']['mongo']['waba_id'] = data.waba_id;
          // product['waba_id'] = data.waba_id;
          // this.concatItem(product);
          // this.changeItem(product);
          // this.changeAllItems(product);
          this.getAllItems();
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alertService.webErrorShow(error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  // updateproduct(product: any) {
  // 	let allItems = [];
  // 	var isChanged = false;
  // 	this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
  // 	if (allItems.length > 0) {
  // 		allItems.forEach(element => {
  // 			if (element.id == product.id) {
  // 				element = product;
  // 				isChanged = true;
  // 			}
  // 		});
  // 		if (!isChanged) {
  // 			allItems.push(product);
  // 			this.changeAllItems(allItems);
  // 		} else {
  // 			this.changeAllItems(allItems);
  // 		}
  // 	}
  // }

  getAllIntractiveProduct(params: any = null) {
    this.commonService.storeItem('fbProduct/productbyIntractive', params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          data.data.forEach(element => {
            element["isDragDrop"] = false;
          });
          this.allIIntractivetemsSource.next(data);
          // this.changeAllItems(data.data);
        }
      },
        error => console.log('Error ::' + error)
      );
  }

  changeAllItemsByCatalogProductItem(item: any) {
    let allItems = [];
    this.allIIntractivetemsSource.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id == item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.allIIntractivetemsSource.next(allItems);
  }

  public drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      var duplicates = []
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      duplicates = event.container.data.filter((item, index) => index != event.container.data.indexOf(item));

      if (event.container && event.container.data && event.container.data[0]) {
        event.container.data[0].isDragDrop = true;
        // this.changeAllItemsByCatalogProductItem(event.container.data[0]);
      }

      if (duplicates.length > 0) {
        this.alert('Danger', 'unique product Only allowed !!!');
      }

    }

  }

  /** Search Event */
  onSearch(input: string) {
    let newParams: any;
    this.params
      .pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
      .subscribe(data => {
        newParams = data;
        newParams.current = 1;
        newParams.search = input;
        this.changeParams(newParams);
        this.getAllItems();
      });
  }

  getExportItems(params: any = null) {
    this.isLoaderSource.next(true);
    this.commonService.storeItem(this.url, params, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success && data.data && data.data.length > 0) {
          this.downloadFile(data.data)
        }else{
          this.isLoaderSource.next(false);
          this.alert('Danger', "There is no Products to export");
        }
      },
        error => {console.log('Error ::' + error), this.isLoaderSource.next(false);}
      );
  }

  // downloadFile
  downloadFile(data) {
    var excelData = [];
    data.forEach(element => {
      if (element.id) {
        data = {
          id: element.id,
          name: element.name,
          currency: element?.currency,
          price: element?.price,
          description: element?.description,
          url: element?.url,
          image_url: element?.image_url
        }
        if (element.availability == 'in stock') {
          data['availability'] = '1';
        } else if (!element.availability) {
          data['availability'] = '1';
        } else {
          data['availability'] = '0';
        }
        excelData.push(data);
      }
    });
    let arrHeader = ["id","name", "currency", "price","availability", "description", "url", "image_url"];
    let csvData = this.ConvertToCSV(excelData, arrHeader);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    var currentTime = new Date().toJSON();
    dwldLink.setAttribute("download", 'facebook-product-list-' + currentTime + '.csv');
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.isLoaderSource.next(false);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    let newHeaders = ["id","name", "currency", "price","availability", "description", "url", "image_url"];

    for (let index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + this.strRep(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  strRep(data) {
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    }
    else if (typeof data == "undefined") {
      return "-";
    }
    else if (typeof data == "number") {
      return data.toString();
    }
    else {
      return data;
    }
  }

}
