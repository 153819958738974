import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { tick } from '@angular/core/testing';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	ClosedTicketService, CommonService,
	ChatService, UserService, User, ProductCartService, LeadsHistoryService, EventService,
	InstadmService,
	MessengerService,
	InstagramService,
	WhatsappService,
	FlagService,
	Flag
} from 'src/app/@tji/_dbShare';
import { KeyValue } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'agent-closed-ticket',
	templateUrl: './closedTicket.component.html',
	styleUrls: ['./closedTicket.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush
})

export class AgentClosedTicketComponent implements OnInit, AfterViewInit {
	@Input() siteName: string;
	authUser: User;
	ticket: any;
	closedTickets: Array<any> = [];
	ticketParam: any;
	base_image: string = this.commonService.logo_image;
	eventData: any;

	constructor(public chatService: ChatService,
		public closedTicketService: ClosedTicketService,
		public userService: UserService,
		public commonService: CommonService,
		private cdRef: ChangeDetectorRef,
		public eventService: EventService,
		private instadmService: InstadmService,
		private messengerService: MessengerService,
		private instagramService: InstagramService,
		private whatsappService: WhatsappService,
		public productCartService: ProductCartService,public leadsHistoryService: LeadsHistoryService,) {
		this.userService.user.subscribe(data => this.authUser = data);
		this.chatService.ticket.pipe(untilDestroyed(this)).subscribe(data =>  this.ticket = data);
		this.eventService.eventData.subscribe(data => {
			this.eventData = data;
			this.checkEventData();
		});
		this.closedTicketService.libraries.pipe(untilDestroyed(this)).subscribe(data => {
			// data.sort((a, b) => a.created_at  - b.created_at);
			this.closedTickets = data;
			// this.closedTickets = data.filter(x => {
            //     if (this.authUser && this.authUser.person && this.authUser.person.is_supervisor && this.authUser.person.supervisoragentids && this.authUser.person.supervisoragentids.length > 0) {
            //         return ((x.agent_id == this.authUser.person_id || this.authUser.person.supervisoragentids.includes(x.agent_id) || this.authUser.person.supervisor_id == x.agent_id) && x.is_closed) ? true : false;
            //     } else {
            //         return (x.agent_id == this.authUser.person_id || this.authUser.person.supervisor_id == x.agent_id && x.is_closed) ? true : false;
            //     }
			// });
		});
	}

	ngOnInit() {
		// this.getClosedTickets(); 
	}

	ngAfterViewInit() {
		this.cdRef.detectChanges();
	}

	getClosedTickets() {
		if (this.userService.permissionMatch(['Manage Whatsapp'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
		}
		if (this.userService.permissionMatch(['Manage Instadm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
		}
		if (this.userService.permissionMatch(['Manage Messenger'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
		if (this.userService.permissionMatch(['Manage Instagram'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
		}
		if (this.userService.permissionMatch(['Manage Twitterdm'])) {
			this.closedTicketService.getRecentClosedTickets('tickets/tweetDmList');
		}
	}

	onSelectTicket(ticket) {
		this.whatsappService.changeLibraries([]);
		this.instagramService.changeLibraries([]);
		this.messengerService.changeLibraries([]);
		this.instadmService.changeLibraries([]);
		this.chatService.changeTicket(ticket);
		this.productCartService.cartDataSource.next(null);
	}

	canShowThisUser(ticket): boolean {
		if (this.authUser && this.authUser.person_type === 'agent' && ticket && ticket.id) {
			return (this.authUser.person_id === ticket.agent_id) ? true : false;
		}
		return false;
	}

	isTicketClosed(ticket): boolean {
		return (ticket && ticket.id && ticket.is_closed) ? true : false;
	}

	checkTicket(ticket): boolean {
		if (this.ticket  && this.ticket.id && this.ticket.id === ticket.id &&
		    this.ticket.siteName === ticket.siteName) {
			return true;
		}
		return false;
	}

	getIcon(ticket): string {
		var output: string = 'support';
		if(ticket && ticket.siteName) {
			output = ticket.siteName.toLowerCase();
		}
		return output;
	}

	checkEventData() {
		if (this.eventData && this.eventData.site && this.userService.isAuthUserIsAgent()) {
			let site: string = this.eventData.site.toLowerCase();
			switch (site) {
				case 'instagram':
				case 'Instagram':
					if (this.eventData && this.eventData.event == 'aclosedticketremoved') {
						var data = {
							siteName : this.eventData.siteName,
							id: this.eventData.data.id,
						}
						this.closedTicketService.splicelibrary(data);
						// this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
					}
					if (this.eventData && this.eventData.event == 'ticketclosed') {
						this.closedTicketService.getRecentClosedTickets('tickets/instagramlist');
					}
					break;
				case 'instadm':
				case 'Instadm':
				case 'instaDm':
				case 'InstaDm':
					if (this.eventData && this.eventData.event == 'aclosedticketremoved') {
						var data = {
							siteName : this.eventData.siteName,
							id: this.eventData.data.id,
						}
						this.closedTicketService.splicelibrary(data);
						// this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
					}
					if (this.eventData && this.eventData.event == 'ticketclosed') {
						this.closedTicketService.getRecentClosedTickets('tickets/instaDmList');
					}
					break;
				case 'messenger':
				case 'Messenger':
					if (this.eventData && this.eventData.event == 'aclosedticketremoved') {
						var data = {
							siteName : this.eventData.siteName,
							id: this.eventData.data.id,
						}
						this.closedTicketService.splicelibrary(data);
						// this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
					}
					if (this.eventData && this.eventData.event == 'ticketclosed') {
						this.closedTicketService.getRecentClosedTickets('tickets/messengerList');
					}
					break;
				case 'Whatsapp':
				case 'whatsapp':
					if (this.eventData && this.eventData.event == 'aclosedticketremoved') {
						var data = {
							siteName : this.eventData.siteName,
							id: this.eventData.data.id,
						}
						this.closedTicketService.splicelibrary(data);
						// this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
					}
					if (this.eventData && this.eventData.event == 'ticketclosed') {
						this.closedTicketService.getRecentClosedTickets('tickets/whatsappList');
					}
					break;
				default:
					break;
			}
		}
	}


}
