import { Injectable, Output, EventEmitter } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertComponent } from 'src/app/library/dialog/alert/alert.component';

import { GlobalService } from 'src/app/@tji/_dbShare/general/global.service';
import { CommonService } from 'src/app/@tji/_dbShare/general/common.service';
import { AlertService } from 'src/app/@tji/_dbShare/alert/alert/alert.service';

import { Aicollection } from './aicollection.interface';
import { AicollectionModel } from './aicollection-model.model';

declare var require: any;
var slugify = require('slugify');
var Pusher = require('pusher-js');
@Injectable({
  providedIn: 'root'
})
export class AicollectionService {
  url: string = 'module/aicollection/auth';
  routeParams: any;
  defaultParams: any = {
    'limit': 25,
    'current': 1,
    'search': '',
  };

  isAuthUser: boolean = false;

  private librariesSource = new BehaviorSubject<Array<any>>([]);
  libraries = this.librariesSource.asObservable();

  public allItemsSource = new BehaviorSubject<Aicollection[]>([]);
  allItems = this.allItemsSource.asObservable();

  public itemSource = new BehaviorSubject<Aicollection>(new AicollectionModel({}));
  item = this.itemSource.asObservable();

  public itemIdSource = new BehaviorSubject<Aicollection>(new AicollectionModel({}));
  itemId = this.itemIdSource.asObservable();

  private displayItemsSource = new BehaviorSubject<Aicollection[]>([]);
  displayItems = this.displayItemsSource.asObservable();

  private totalItemSource = new BehaviorSubject<number>(0);
  totalItem = this.totalItemSource.asObservable();

  private paramsSource = new BehaviorSubject<any>(this.defaultParams);
  params = this.paramsSource.asObservable();

  public isAuthentication = new BehaviorSubject<Array<any>>([]);
  isAuth = this.isAuthentication.asObservable();

  public isSendPaymentData = new BehaviorSubject<Array<any>>([]);
  isSendPayment = this.isSendPaymentData.asObservable();

  public isStatusPaymentData = new BehaviorSubject<Array<any>>([]);
  isStatusPayment = this.isStatusPaymentData.asObservable();

  public isPaymentGenerateRequest = new BehaviorSubject<boolean>(false);
  isPaymentGenerate = this.isPaymentGenerateRequest.asObservable();

  // public currentItemsSource = new BehaviorSubject<Aicollection[]>([]);
  // currentItems = this.currentItemsSource.asObservable();

  public isLoader = new BehaviorSubject<boolean>(true);
  loader = this.isLoader.asObservable();

  public isPaymentLoader = new BehaviorSubject<boolean>(false);
  paymentLoader = this.isPaymentLoader.asObservable();

  public isStatusInit = new BehaviorSubject<any>([]);
  statusInit = this.isStatusInit.asObservable();

  public isfilterLoader = new BehaviorSubject<boolean>(false);
  filterLoader = this.isfilterLoader.asObservable();

  private _unsubscribeAll: Subject<any>;

  constructor(private globalService: GlobalService,
    private commonService: CommonService,
    private alertService: AlertService,
    private snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        this.defaultParams.search = '',
        this.resetParams(),
        this.checkAuth(),
        this.getCurrentItems(),
        // this.getItem()
      ])
        .then(() => {
          resolve(null);
        }, reject
        );
    });
  }

  unSubscribe() {
    // console.log('UnSubscribed catalogService');
  }
  unSubscribeFilter() {
    // console.log('UnSubscribed Filters on catalogService');
  }

  concatlibrary(allItems: Aicollection[]) {
    var oldLists: Array<any> = [];
    this.libraries.subscribe(data => {
      oldLists = data;
    });
    if (oldLists && oldLists.length > 0) {
      oldLists = this.globalService.arrayMergeByIdData(oldLists, allItems);
    } else {
      oldLists = allItems;
    }
    this.changeLibraries(oldLists);
  }

  removelibrary(item: Aicollection) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      oldLists = oldLists.filter(x => {
        return x._id !== item._id;
      });
    }
    this.changeLibraries(oldLists);
  }

  removelibraryById(id: number) {
    let oldLists = [];
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && id) {
      oldLists = oldLists.filter(x => {
        return x.id !== id;
      });
    }
    this.changeLibraries(oldLists);
  }

  replacelibrary(item: Aicollection) {
    let oldLists = [];
    let isReplaced: boolean = false;
    this.libraries.subscribe(data => oldLists = data);
    if (oldLists && oldLists.length > 0 && item && item._id) {
      for (var i = 0; i < oldLists.length; ++i) {
        if (oldLists[i]._id === item._id) {
          oldLists.splice(i, 1, item);
          isReplaced = true;
          break;
        }
      }
    }
    if (!isReplaced) { oldLists.concat([item]); }
    this.changeLibraries(oldLists);
  }

  changeLibraries(libraries: Aicollection[]) {
    this.librariesSource.next(libraries);
  }

  changeAllItems(allItems: Aicollection[]) {
    this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }

  changeCurrentItems(allItems: Aicollection[]) {
    // this.concatlibrary(allItems);
    this.allItemsSource.next(allItems);
  }


  changeItem(item: Aicollection) {
    this.itemSource.next(item);
  }

  changeItemId(item: Aicollection) {
    this.itemIdSource.next(item);
  }

  changeTotalItem(total: number) {
    this.totalItemSource.next(total);
  }

  changeDisplayItems(displayItems: Aicollection[]) {
    this.displayItemsSource.next(displayItems);
  }

  changeParams(parms: any) {
    this.paramsSource.next(parms);
  }

  changeAllItemsByItem(item: Aicollection) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i]._id === item._id) { allItems.splice(i, 1, item); }
      }
    }
    this.changeAllItems(allItems);
  }

  paramsInit(params: any) {
    let newParams: any;
    let key: any;
    if (params !== null) {
      newParams = params;
    } else {
      this.params.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => { newParams = data; });
    }

    for (key in newParams) {
      if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
        delete newParams[key];
      }
    }
    return newParams;
  }

  resetParams() {
    const defaultParams: any = {
      'limit': 25,
      'current': 1,
      'search': '',
    };
    this.changeParams(this.paramsInit(defaultParams));
  }

  getCurrentItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem('module/aicollection/getPaymentsLink', params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isLoader.next(false);
          this.changeCurrentItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        }else{
          this.isLoader.next(false);
        }
      },

        error => {
          this.isLoader.next(false);
          console.log('Error ::' + error)
        }
      );
  }

  getFilterAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem('module/aicollection/getPaymentsLink', params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.isfilterLoader.next(false);
          this.changeCurrentItems(data.data);
          this.changeTotalItem(data.pagnitation.totalResult);
        }else{
          this.isfilterLoader.next(false);
        }
      },

        error => {
          this.isfilterLoader.next(false);
          console.log('Error ::' + error)
        }
      );
  }

  checkAuth(params: any = null) {
    params = this.paramsInit(params);
    var clientId = Number(localStorage.getItem('client_id'));
    params.client_id = clientId ? clientId : null;
    // if (!this.isAuthUser) {
    this.commonService.storeItem(this.url, params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          if (data.data && !data.data.result) {
            this.isAuthentication.next(data);
          } else {
            this.isAuthUser = true;
            this.isAuthentication.next(data);
          }
        } else {
          this.isAuthUser = false;
        }
      },
        error => console.log('Error ::' + error)
      );
    // }
  }

  concatAllItems(params: any = null) {
    params = this.paramsInit(params);
    this.commonService.storeItem(this.url, params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        let lists = [];
        if (data.data.last_page <= data.data.current_page) {
          params.page = data.data.last_page;
          this.changeParams(params);
        }
        this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
          lists = result.concat(data.data.data);
        });
        this.changeAllItems(lists);
      },
        error => console.log('Error ::' + error)
      );
  }

  concatItem(item: Aicollection) {
    let lists = [];
    let isChanged: boolean = false;
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(result => {
      lists = result
      if (!lists) {
        lists = [];
      }
      if (lists && lists.length > 0) {
        var foundIndex = lists.findIndex(x => x?._id == item._id);
        if (foundIndex >= 0) {
          lists[foundIndex] = item;
          isChanged = true;
        } else {
          lists = lists.concat([item]);
          isChanged = true;
        };
      } else {
        lists[0] = item;
        isChanged = true;
      }
    });
    if (isChanged) {
      this.changeAllItems(lists);
      isChanged = false;
    }
  }

  spliceItem(id: number) {
    let allItems = [];
    this.allItems.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => allItems = data);
    if (allItems && allItems.length > 0) {
      for (var i = 0; i < allItems.length; ++i) {
        if (allItems[i].id === id) { allItems.splice(i, 1); }
      }
    }
    this.changeAllItems(allItems);
  }

  // getItem(params: any = null) {
  //   this.routeParams = (params) ? params : this.routeParams;
  //   var itemId;
  //   this.itemId.pipe(untilDestroyed(this, 'unSubscribe')).subscribe(data => itemId = data);
  //   var id = {
  //     "id": itemId
  //   }
  //   if (itemId > 0) {

  //     this.commonService.storeItem('agent/viewmore', id, true, 'optionOne')
  //       .pipe(untilDestroyed(this, 'unSubscribe'))
  //       .subscribe(data => {
  //         this.changeAllItemsByItem(data.data);
  //         this.changeItem(data.data);
  //       },
  //         error => console.log('Error ::' + error)
  //       );
  //   }
  //   else {
  //     this.changeItem(new AicollectionModel({}));
  //   }
  // }

  getInit() {
    this.commonService.storeItem("module/aicollection/init", {}, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.isStatusInit.next(res.data)
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  store(url, data: any) {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          this.concatItem(res.data);
          this.changeItem(res.data);
          this.alert('Success', 'Created Successfully !!!');
        } else {
          this.alertService.webErrorShow(res);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }


  update(url, data: any, Aicollection = null): void {
    this.commonService.storeItem(url, data, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(res => {
        if (res.success) {
          Aicollection.name = data.name;
          this.alert('Success', res.data.message);
        } else {
          this.alertService.webErrorShow(res);
        }
      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }

  destroy(url, id) {
    this.commonService.storeItem(url, id, true, 'optionOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          this.spliceItem(id.id);
          this.alert('Danger', 'Destroyed Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
        }

      },
        error => {
          console.log('Error ::' + error);
          this.alert('Danger', 'Something Wrong. Try after Sometimes !!!');
        }
      );
  }



  /** Scroll Event */
  onScroll() {
    let newParams: any;
    this.params
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.page += 1;
        this.changeParams(newParams);
        this.concatAllItems();
      });
  }

  pageEvent(event) {
    let newParams: any;
    this.params
      .pipe(debounceTime(100), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        newParams = data;
        newParams.current = event.pageIndex + 1;
        newParams.limit = event.pageSize;
        this.changeParams(newParams);
        this.getCurrentItems();
      });
  }
  alert(type: string, message) {
    this.alertService.webShow(type, message);
  }

  verifyAuth(params: any = null) {
    if (!this.isAuthUser) {
      this.commonService.storeItem('module/aicollection/authUpdate', params, true, 'optionAiOne')
        .pipe(untilDestroyed(this, 'unSubscribe'))
        .subscribe(data => {
          if (data.success) {
            this.checkAuth();
            this.alert('Success', 'Created Successfully !!!');
            // this.isAuthentication.next(data);
          } else {
            this.alertService.webErrorShow(data);
          }
        },
          error => console.log('Error ::' + error)
        );
    }
  }

  generatePayment(params: any = null) {
    this.commonService.storeItem('module/aicollection/generatePaymentUrl', params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          // this.concatItem(data.data);
          // this.changeItem(data.data);
          this.isPaymentGenerateRequest.next(true);
          this.isPaymentLoader.next(false);
          this.isSendPaymentData.next(data.data);
          this.alert('Success', 'Created Successfully !!!');
        } else {
          this.alertService.webErrorShow(data);
          this.isPaymentLoader.next(false);
        }
      },
        error => {console.log('Error ::' + error); this.isPaymentLoader.next(false);}
        
      );
  }

  getStatusPayment(params: any = null) {
    this.commonService.storeItem('module/aicollection/paymentStatus', params, true, 'optionAiOne')
      .pipe(untilDestroyed(this, 'unSubscribe'))
      .subscribe(data => {
        if (data.success) {
          // this.alert('Success', 'Created Successfully !!!');
          this.isStatusPaymentData.next(data.data);
        } else {
          this.alertService.webErrorShow(data);
        }
      },
        error => console.log('Error ::' + error)
      );
  }

/** Search Event */
	onSearch(input: string) {
		let newParams: any;
		this.params
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this, 'unSubscribe'), untilDestroyed(this, 'unSubscribeFilter'))
			.subscribe(data => {
				newParams = data;
				newParams.page = 1;
				newParams.search = input;
				this.changeParams(newParams);
				this.getCurrentItems();
			});
	}

}
