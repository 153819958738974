import { NgModule } from '@angular/core';
import { AppShareModule } from 'src/app/app-share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainComponent } from './main/main.component';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './item/item.component';
import { FormComponent } from './form/form.component';
import { FacebookFormComponent } from './facebookForm/facebookForm.component';
import { MessengerFormComponent } from './messengerForm/messengerForm.component';
import { WhatsappFormComponent } from './whatsappForm/whatsappForm.component';
import { TwitterFormComponent } from './twitterForm/twitterForm.component';
import { YoutubeFormComponent } from './youtubeForm/youtubeForm.component';
import { GooglechatFormComponent } from './googlechatForm/googlechatForm.component';
import { InstagramFormComponent } from './instagramForm/instagramForm.component';
import { InstadmFormComponent } from './instadmForm/instadmForm.component';
import { WebchatFormComponent } from './webchatForm/webchatForm.component';

import { AutoAssignComponent } from './autoAssign/autoAssign.component';
import { TagFormComponent } from './tagForm/tagForm.component';
import { BoardFormComponent } from './boardForm/boardForm.component';

import { ItrsComponent } from './itrs/itrs.component';
import { IntractiveFormComponent } from './itrsItems/intractiveForm/intractiveForm.component';
import { ChooseIntractiveFormComponent } from './itrsItems/chooseIntractiveForm/chooseIntractiveForm.component';
import { ChooseJumpIntractiveFormComponent } from './itrsItems/chooseJumpIntractiveForm/chooseJumpIntractiveForm.component';
import { ChangeIntractiveGroupImageComponent } from './itrsItems/changeIntractiveGroupImage/changeIntractiveGroupImage.component';
import { ChangeIntractiveGroupVideoComponent } from './itrsItems/changeIntractiveGroupVideo/changeIntractiveGroupVideo.component';
import { TicketableIntractiveFormComponent } from './itrsItems/ticketableIntractiveForm/ticketableIntractiveForm.component';
import { IntractiveComponent } from './itrsItems/intractive/intractive.component';
import { IntractiveCategoryComponent } from './itrsItems/intractiveCategory/intractiveCategory.component';
import { SingleComponent } from './itrsItems/single/single.component';
import { CombinedComponent } from './itrsItems/combined/combined.component';
import { SurveyComponent } from './itrsItems/survey/survey.component';
import { StoreAsFormComponent } from './itrsItems/storeAsForm/storeAsForm.component';
import { StoreAsCombinedFormComponent } from './itrsItems/storeAsCombinedForm/storeAsCombinedForm.component';
import { StoreAsIntractiveFormComponent } from './itrsItems/storeAsIntractiveForm/storeAsIntractiveForm.component';
import { SimpleFormComponent } from './itrsItems/simpleForm/simpleForm.component';
import { ShortCodeFormComponent } from './itrsItems/shortCodeForm/shortCodeForm.component';
import { ItrsApiComponent } from './itrsItems/itrs-api/itrs-api.component';
import { ApiFormComponent } from './itrsItems/apiForm/apiForm.component';
import { TemplateMessageComponent } from './itrsItems/templateMessage/templateMessage.component';
import { TemplateMessageFormComponent } from './itrsItems/templateMessageForm/templateMessageForm.component';
import { SidebarSitesComponent } from './sidebarSites/sidebarSites.component';
import { LogViewComponent } from './logView/logView.component';
import { LinkIntractiveMessageFormComponent } from './itrsItems/linkIntractiveMessageForm/linkIntractiveMessageForm.component';

import { WebhookComponent } from './webhook/webhook.component';
import { UrlFormComponent } from './webhookItems/urlForm/urlForm.component';

import { ClientSiteService, SiteService, IntractiveCommandService, ParamService } from 'src/app/@tji/_dbShare';
import { WhatsappProfileUpdateFormComponent } from './whatsapp-profile-update-form/whatsapp-profile-update-form.component';
import { ItrsbotComponent } from './itrsbot/itrsbot.component';
import { WhatsappSettingsComponent } from './whatsappSettings/whatsappSettings.component';

@NgModule({
    declarations: [
        MainComponent,
        ListComponent,
        ItemComponent,
        FormComponent,
        FacebookFormComponent,
        MessengerFormComponent,
        WhatsappFormComponent,
        TwitterFormComponent,
        YoutubeFormComponent,
        GooglechatFormComponent,
        InstagramFormComponent,
        InstadmFormComponent,
        WebchatFormComponent,
        AutoAssignComponent,
        TagFormComponent,
        BoardFormComponent,
        IntractiveFormComponent,
        ChooseIntractiveFormComponent,
        ChooseJumpIntractiveFormComponent,
        ChangeIntractiveGroupImageComponent,
        ChangeIntractiveGroupVideoComponent,
        TicketableIntractiveFormComponent,
        ItrsComponent,
        IntractiveComponent,
        IntractiveCategoryComponent,
        SingleComponent,
        CombinedComponent,
        SurveyComponent,
        StoreAsFormComponent,
        StoreAsCombinedFormComponent,
        StoreAsIntractiveFormComponent,
        SimpleFormComponent,
        ShortCodeFormComponent,
        SidebarSitesComponent,
        WebhookComponent,
        UrlFormComponent,
        ItrsApiComponent,
        ApiFormComponent,
        TemplateMessageComponent,
        TemplateMessageFormComponent,
        WhatsappProfileUpdateFormComponent,
        ItrsbotComponent,
        LinkIntractiveMessageFormComponent,
        LogViewComponent,
        WhatsappSettingsComponent
    ],
    imports: [
        AppShareModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MainComponent,
        ListComponent,
        ItemComponent,
        SidebarSitesComponent,
    ],
    providers: [
        ClientSiteService,
        SiteService,
        IntractiveCommandService,
        ParamService
    ]
})

export class SocialSiteImportModule { }
