import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    User, UserService,
    MediaService, Media, MediaModel
} from 'src/app/@tji/_dbShare';

export interface InputPattern { title: string; visible: boolean; required: boolean; }

@UntilDestroy()
@Component({
    selector: 'gallery-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnDestroy {
    authUser: User;
    gallery: Media;
    galleryForm: UntypedFormGroup;
	loader: boolean = false;

    constructor(public userService: UserService,
        public mediaService: MediaService,
        private _formBuilder: UntypedFormBuilder) {
        this.galleryForm = this.createForm();
        this.mediaService.uploadLoader.subscribe(data => this.loader = data);
    }

    ngOnInit() {
        // this.doLoader();
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.mediaService.unSubscribe();
        this.mediaService.unSubscribeFilter();
    }

    doLoader() {
		this.loader = true;
		setTimeout(() => {
			this.loader = false;
		}, 5000);
	}

    /** Create form */
    createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            file: [null],
            asset: [null],
            asset_name: [null],
            asset_type: [null]
        });
    }
    typeData: string = 'image';

    imageHandler(event) {
        let reader = new FileReader();
        let fileData = null;

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);

            reader.onload = (e: any) => {
                var types = file.type.split("/");
                var typeDatas = types[1];
                switch (typeDatas) {
                    case "JPEG": case "JPG":
                    case "PNG": case "GIF":
                    case "TIFF": case "RAW":
                    case "jpeg": case "jpg":
                    case "png": case "gif":
                    case "tiff": case "raw":
                    case "WebP": case "webp": case "WEBP":
                        this.typeData = 'image'
                        break;
                    case "DOC": case "DOCX":
                    case "ODT": case "PDF":
                    case "PPT": case "PPTX":
                    case "TXT": case "XLS": 
                    case "XLSX": case "ZIP":
                    case "CVS": case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    case "doc": case "csv":
                    case "docx": case "odt":
                    case "pdf": case "pptx":
                    case "text":case "xls": 
                    case "xlsx": case "zip":
                    case "cvs": case "csv":
                        this.typeData = 'document'
                        break;
                    case "MP3": case "WAV":
                    case "AAC": case "FLAC":
                    case "Ogg": case "Ogg Vorbis":
                    case "PCM": case "MPEG":
                    case "mp3": case "wav": 
                    case "aac": case "flac": 
                    case "ogg": case "mpeg":
                    case "ogg vorbis": case "pcm":
                    case "vnd.dlna.adts":
                        this.typeData = 'voice'
                        break;
                    case "WEBM": case "MPG":
                    case "MP2":
                    case "MPE": case "MPV":
                    case "OGG": case "MP4":
                    case "AVI": case "WMV":
                    case "MOV": case "QT":
                    case "FLV": case "SWF":
                    case "AVCHD":
                    case "webm": case "mpg":
                    case "mp2":
                    case "mpe": case "mpv":
                    case "ogg": case "mp4":
                    case "avi": case "wmv":
                    case "mov": case "qt":
                    case "flv": case "swf":
                    case "avchd":
                        this.typeData = 'video'
                    default:
                        break;
                }

                this.galleryForm.patchValue({
                    asset: reader.result,
                    asset_name: file.name.replace(/ /g, "_"),
                    asset_type: this.typeData,
                });
                this.mediaService.isUploadLoader.next(true);
                this.mediaService.addStore(this.galleryForm.value);
                setTimeout(() => {
                    this.galleryForm.patchValue({
                        file: null,
                        asset: null,
                        asset_name: null,
                        asset_type: null
                    });
                }, 300);
            };
        }
    }

}
